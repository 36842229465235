import { Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { Container, Wrapper } from './styled';

interface RegisteredProps {}

const Registered: FunctionComponent<RegisteredProps> = () => {
  return (
    <Container>
      <Wrapper style={{ textAlign: 'center' }}>
        <Typography variant="h2" component="h2" style={{ marginBottom: '15px' }}>
          🎉
        </Typography>
        <Typography variant="h4" component="h4" style={{ marginBottom: '25px', textAlign: 'center' }}>
          Congratulations!
        </Typography>
        <Typography style={{ marginBottom: '21px' }}>You have successfully completed your registration!</Typography>
        <Typography style={{ marginBottom: '41px' }}>We'll be in touch soon to get your experience set.</Typography>
      </Wrapper>
    </Container>
  );
};

export default Registered;
