import React, { FunctionComponent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAppSelector, useMustHaveGroupId } from 'app/hooks';
import { BUILDER_DAYTIMEACTIVITIES_QUERY, BUILDER_DAYTIMEACTIVITIES_QUERY_DATA } from 'app/queries';
import {
  groupInfoSelector,
  destinationInfoSelector,
  submitDaytimeActivities,
  selectDaytimeActivities,
  selectGroupDataCollectionStatus,
  gotoSummarySelector,
} from 'features/group-data-collection/groupSlice';
import { Cards, SelectWrapper, StyledSkeleton } from './styled';
import { CardWrapper } from 'components/shared';
import Card from 'components/shared/card';
import Select from 'components/shared/select';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FormArea from '../form-area';
import { toggleFromArray, toUSD } from 'utils';
import Person from 'components/shared/icons/people';
import toast from 'react-hot-toast';
import { submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
import { Typography } from '@material-ui/core';
import useSort from 'hooks/useSort';
import { PriceService } from 'services/price';

export interface DaytimeActivitiesProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

// const heading = 'Do you want to do any activities during the day?';//temp-no-night
const heading = 'Do you want to do any activities?';
const paragraph = 'Are you sure? We have some great featured deals selected specifically for you.';

const DaytimeActivities: FunctionComponent<DaytimeActivitiesProps> = ({ setActiveStep, ...props }) => {
  useEffect(() => {
    setActiveStep(2);
  }, [setActiveStep]);
  useMustHaveGroupId();
  const history = useHistory();
  const initialDaytimeActivities = useAppSelector(selectDaytimeActivities);
  const [daytimeActivities, setDaytimeActivities] = useState<null | string[]>(initialDaytimeActivities || null);
  const { groupSize } = useAppSelector(groupInfoSelector);
  const [daytimeActivitiesYes, seDaytimeActivitiesYes] = useState<undefined | boolean>(
    !!initialDaytimeActivities || true
  );
  const { destination } = useAppSelector(destinationInfoSelector);
  const { data, loading: loadingDaytimeActivities } = useQuery<BUILDER_DAYTIMEACTIVITIES_QUERY_DATA>(
    BUILDER_DAYTIMEACTIVITIES_QUERY,
    {
      variables: {
        destination: destination.id,
        groupSize,
        // activityType: 'group',
      },
    }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // we're insterested in knowing that the user got to ready because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'daytime activities' }));
  }, [dispatch]);
  const gotoSummary = useAppSelector(gotoSummarySelector);
  const handleSubmit = async (e: any, yes: boolean) => {
    e.preventDefault();
    const selectedDaytimeActivitiesData = daytimeActivities?.map((id) => {
      const activity = data?.allDaytimeActivities.find((transp) => transp.id === id);
      if (activity) {
        return {
          id: activity.id,
          name: activity.name,
        };
      } else return undefined;
    });
    if (!selectedDaytimeActivitiesData && yes) {
      toast.error('Please make your daytime activities selection');
      return;
    }

    await dispatch(submitDaytimeActivities({ data: selectedDaytimeActivitiesData }));
    if (gotoSummary) {
      //was clicked by checkout button (synthetic, non-human click)
      history.push('/builder/summary');
    } else {
      // history.push('/builder/nighttime-activities'); //temp-no-night
      history.push('/builder/personal-services');
    }
  };
  const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);

  const [showPopup, setShowPopup] = useState(false);
  const [daytimeActivitiesPopupID, setDaytimeActivitiesPopupID] = useState('');

  const [sorting, setSorting] = useState('priceDesc');

  const { sortedArray } = useSort({
    array: data?.allDaytimeActivities,
    groupSize,
    sorting,
    type: 'daytime',
  });
  return (
    <>
      <FormArea
        heading={heading}
        paragraph={paragraph}
        isSubmitting={dataCollectionStatus === 'loading'}
        stepLabel="Daytime Activitites"
        onSubmit={handleSubmit}
        initialValue={!!initialDaytimeActivities}
        setCustomYes={seDaytimeActivitiesYes}
        multiSelect
      >
        <SelectWrapper>
          <Typography style={{ marginRight: '15px' }} variant="h4" component="h4">
            Sort:
          </Typography>
          <Select
            nomb
            options={[
              { name: 'Price - High to Low', value: 'priceDesc' },
              { name: 'Price - Low to High', value: 'priceAsc' },
            ]}
            onChange={(e) => {
              setSorting(e.target.value);
            }}
          />
        </SelectWrapper>

        {loadingDaytimeActivities && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
            </div>
          </div>
        )}
        <Cards>
          {daytimeActivitiesYes !== undefined &&
            sortedArray.map((daytimeActivitiesfetched: any, i: number) => {
              const selected = daytimeActivities?.includes(daytimeActivitiesfetched.id);
              const price = PriceService.daytimeActivities(
                {
                  groupSize,
                  price: daytimeActivitiesfetched.price,
                  activityType: daytimeActivitiesfetched.activityType,
                },
                { usd: true, labeled: true, noCeroCero: true, rounded: true }
              );
              const cardProps = {
                withActivitiesPopup: {
                  showPopup,
                  daytimeActivitiesPopupID,
                  daytimeActivitiesfetched,
                  setShowPopup,
                  setDaytimeActivities,
                },
                image: daytimeActivitiesfetched?.cardImage?.responsiveImage,
                title: daytimeActivitiesfetched.name,
                icons: [{ Icon: Person, text: daytimeActivitiesfetched.groupSizeMin + '+' }],
                paragraph: daytimeActivitiesfetched.shortDescription,
                button1: {
                  text: 'LEARN MORE',
                  onClick: () => {
                    setDaytimeActivitiesPopupID(daytimeActivitiesfetched?.id);
                    setShowPopup(true);
                  },
                },
                button2: {
                  text: selected ? 'Remove Activity' : 'Select Activity',
                  onClick: () =>
                    setDaytimeActivities((prev) => {
                      return toggleFromArray(prev, daytimeActivitiesfetched.id);
                    }),
                },
                featured: daytimeActivitiesfetched.featured,
                selected,
                price,
              };
              if (daytimeActivitiesYes) {
                return (
                  <CardWrapper key={daytimeActivitiesfetched.id}>
                    <Card {...cardProps} />
                  </CardWrapper>
                );
              } else {
                if (i < 3) {
                  return (
                    <CardWrapper key={daytimeActivitiesfetched.id}>
                      <Card {...cardProps} />
                    </CardWrapper>
                  );
                } else {
                  return null;
                }
              }
            })}
        </Cards>
      </FormArea>
      <div id="scrollHelper" />
    </>
  );
};

export default DaytimeActivities;
