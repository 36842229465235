import CardLarge from 'components/shared/card-large';
import Baths from 'components/shared/icons/baths';
import Beds from 'components/shared/icons/beds';
import People from 'components/shared/icons/people';
import { submitHousing } from 'features/group-data-collection/groupSlice';
import { EstimateRow, EstimateWrapper, SubmitButtonWrapper } from 'features/summary-stage/summary/styled';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { daysDiff, toUSD, toUSDNoDecimal } from 'utils';
import { useAppDispatch, usePrices, usePricesExperience } from 'app/hooks';
import { Container, Wrapper, Col1, Col2 } from './styled';
import { GROUP_DATA } from 'app/queries';
import { useExperience } from 'hooks/useExperience';
import SubmitButton from 'components/shared/submit-button';
import SignUpPopup from 'components/shared/sign-up-popup';
import { Button, Card, Typography } from '@material-ui/core';
import { ApolloQueryResult } from '@apollo/client';
import { PriceService } from 'services/price';
import { ExpGroupData } from '../expGroupSlice';
import CardSummary from 'components/shared/card-summary';
import SumDestination from 'components/shared/icons/sum-destination';
import SumSize from 'components/shared/icons/sum-size';
import SumCalendar from 'components/shared/icons/sum-calendar';

interface ISummary {
  _id?: string;
  group?: ExpGroupData;
  showSignUp: boolean;
  setShowSignUp: (args: any) => void;
  refetchGroup: any;
}

const Summary: FunctionComponent<ISummary> = ({
  _id,
  group: groupData,
  refetchGroup,
  showSignUp,
  setShowSignUp,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {
    housingData,
    transportationData,
    daytimeActivitiesData,
    nighttimeActivitiesData,
    personalServicesData,
    group,
    loading,
  } = useExperience(groupData);

  const {
    housingPricePerPerson,
    totalPerPayee,
    totalPerPayeeExact,
    totalPerPersonExact,
    transportationPerPerson,
    daytimeActivitiesPerPerson,
    personalServicesPerPerson,
    renderPrice,
  } = usePricesExperience(_id);
  const isCapped = Number(group?.groupSize) <= (group?.members?.length || 0);

  return (
    <Container>
      {/* <Card
        sx={{
          boxShadow: 'none',
          border: `1px solid #03A0EF`,
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>Dont waste time, set your payment authorization now! </Typography>
        <Button
          color="primary"
          variant="contained"
          href="https://bidoevents.com/payment-plan-wizard.php"
          target="_blank"
        >
          {' '}
          PAY NOW
        </Button>
      </Card> */}
      <CardSummary
        mb="0px"
        icons={[
          {
            icon: <SumDestination />,
            text1: 'DESTINATION',
            text2: `${group?.destination?.place}, ${group?.destination?.country}`,
          },
          {
            icon: <SumCalendar />,
            text1: 'DATE',
            text2: `${new Date(`${group?.fromDate}T00:00:00`).toDateString()} - ${new Date(
              `${group?.untilDate}T00:00:00`
            ).toDateString()}`,
          },
          {
            icon: <SumSize />,
            text1: 'GROUP SIZE',
            text2: `${group?.groupSize} Adults`,
          },
        ]}
      />

      <Wrapper>
        <Col1>
          {housingData?.allHousings && housingData?.allHousings?.length > 0 && (
            <CardLarge
              fullWidth
              pricePerPerson
              title1="HOUSING"
              image={housingData?.allHousings[0].cardImage?.responsiveImage}
              title2={housingData?.allHousings[0].name}
              icons={[
                { icon: <Beds></Beds>, text: `${housingData?.allHousings[0].bedrooms}` },
                { icon: <Baths></Baths>, text: `${housingData?.allHousings[0].bathrooms}` },
                { icon: <People></People>, text: `${housingData?.allHousings[0].groupSizeMax}` },
              ]}
              price={housingPricePerPerson && toUSDNoDecimal(housingPricePerPerson)}
              style={{ marginBottom: '13px' }}
            ></CardLarge>
          )}
          {transportationData?.map((transportation) => {
            const transportationPrice =
              group?.tripLength &&
              PriceService.transportation(
                {
                  isThisAnAirportTransfer: transportation.isThisAnAirportTransfer,
                  price: transportation.price,
                  trip: transportation.trip,
                  groupSize: group?.groupSize,
                  activityType: transportation.activityType,
                  tripLength: group?.tripLength,
                },
                { usd: true, noCeroCero: true, rounded: true }
              );
            return (
              <CardLarge
                pricePerPerson
                qty={transportation.qty}
                title1="TRANSPORTATION"
                image={transportation.cardImage?.responsiveImage}
                title2={transportation.name}
                icons={[{ icon: <People></People>, text: `${transportation.groupSizeMax}` }]}
                price={transportationPrice}
                style={{ marginBottom: '13px' }}
              ></CardLarge>
            );
          })}
          {daytimeActivitiesData?.allDaytimeActivities.map((daytimeActivity) => {
            const price = PriceService.daytimeActivities(
              {
                activityType: daytimeActivity.activityType,
                price: daytimeActivity.price,
                groupSize: group?.groupSize,
              },
              { usd: true, noCeroCero: true, rounded: true }
            );
            return (
              <CardLarge
                fullWidth
                pricePerPerson
                title1="DAYTIME ACTIVITY"
                image={daytimeActivity.cardImage?.responsiveImage}
                title2={daytimeActivity.name}
                icons={[{ icon: <People></People>, text: `${daytimeActivity.groupSizeMax}` }]}
                price={price}
                style={{ marginBottom: '13px' }}
              ></CardLarge>
            );
          })}
          {nighttimeActivitiesData?.allNighttimeActivities.map((nighttimeActivity) => {
            const price = PriceService.nighttimeActivities(
              {
                activityType: nighttimeActivity.activityType,
                price: nighttimeActivity.price,
                groupSize: group?.groupSize,
              },
              { usd: true, noCeroCero: true, rounded: true }
            );
            return (
              <CardLarge
                fullWidth
                pricePerPerson
                title1="NIGHTTIME ACTIVITY"
                image={nighttimeActivity.cardImage?.responsiveImage}
                title2={nighttimeActivity.name}
                icons={[{ icon: <People></People>, text: `${nighttimeActivity.groupSizeMax}` }]}
                price={price}
                style={{ marginBottom: '13px' }}
              ></CardLarge>
            );
          })}
          {personalServicesData?.map((personalService) => {
            const price =
              group &&
              group.fromDate &&
              PriceService.personalServices(
                {
                  activityType: personalService.activityType,
                  fromDate: group?.fromDate,
                  price: personalService.price,
                  reach: personalService.reach,
                  untilDate: group?.untilDate,
                  groupSize: group?.groupSize,
                },
                { usd: true, noCeroCero: true, rounded: true }
              );
            return (
              <CardLarge
                fullWidth
                pricePerPerson
                qty={personalService.qty}
                title1="PERSONAL SERVICE"
                image={personalService.cardImage?.responsiveImage}
                title2={personalService.name}
                icons={[{ icon: <People></People>, text: `${personalService.groupSizeMax}` }]}
                price={price}
                style={{ marginBottom: '13px' }}
              ></CardLarge>
            );
          })}
        </Col1>
        <Col2>
          <EstimateWrapper>
            <div
              style={{
                padding: '10px 30px 12px 22px',
              }}
            >
              <Typography style={{ color: '#fff' }} variant="h4" component="h4">
                Estimate
              </Typography>
            </div>
            <div style={{ width: '100%', height: '1px', backgroundColor: '#4B4D5C' }} />
            <div
              style={{
                padding: '22px 30px 12px 27px',
              }}
            >
              <EstimateRow>
                <Typography style={{ marginBottom: '16px', color: '#03A0EF' }} variant="h6" component="h6">
                  GROUP ID
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {group?.bido_id}
                </Typography>
              </EstimateRow>
              <EstimateRow>
                <Typography style={{ marginBottom: '16px', color: '#03A0EF' }} variant="h6" component="h6">
                  GROUP SIZE
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {group?.groupSize}
                </Typography>
              </EstimateRow>
              <Typography style={{ marginBottom: '16px', color: '#03A0EF' }} variant="h6" component="h6">
                GROUP PACKAGE
              </Typography>
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Housing
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {housingPricePerPerson ? (
                    <>
                      {renderPrice(housingPricePerPerson || 0)} <span style={{ fontSize: '12px' }}>/ person</span>{' '}
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Transportation
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {transportationPerPerson ? (
                    <>
                      {' '}
                      {renderPrice(transportationPerPerson)}
                      <span style={{ fontSize: '12px' }}>/ person</span>
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Activities
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {daytimeActivitiesPerPerson ? (
                    <>
                      {renderPrice(daytimeActivitiesPerPerson)} <span style={{ fontSize: '12px' }}>/ person</span>{' '}
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>
              {/* <EstimateRow> //temp-no-night
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Nighttime Activities
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {nighttimeActivitiesPerPerson !== '$0' ? (
                    <>
                      {nighttimeActivitiesPerPerson}
                      <span style={{ fontSize: '12px' }}>/ person</span>
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>*/}
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Personal Services
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {personalServicesPerPerson ? (
                    <>
                      {renderPrice(personalServicesPerPerson)}
                      <span style={{ fontSize: '12px' }}>/ person</span>
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>
            </div>
            {/* <div
              style={{
                border: '1px solid #4B4D5C',
                borderRadius: '6px',
                padding: '10px',
                margin: '0 30px 28px 27px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography style={{ color: '#fff' }} variant="h6" component="h6">
                Group Total
              </Typography>
              <Typography style={{ color: '#03A0EF', fontWeight: 'lighter' }} variant="h6" component="h6">
                {totalPrice}
              </Typography>
            </div> */}
            <div style={{ width: '100%', height: '0', borderBottom: '1px dashed #4B4D5C' }} />
            <div style={{ padding: '16px 29px 21px 22px' }}>
              <Typography style={{ marginBottom: '16px', color: '#1ECC7A' }} variant="h6" component="h6">
                TOTAL PER PAYEE
              </Typography>
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Payees
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  <span style={{ fontSize: '12px' }}>{group?.payees}</span>
                </Typography>
              </EstimateRow>
              <div
                style={{
                  border: '1px solid #4B4D5C',
                  borderRadius: '6px',
                  backgroundColor: '#4B4D5C',
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h6" component="h6">
                  Each Payee Pays
                </Typography>
                <Typography style={{ color: '#1ECC7A', fontWeight: 'lighter' }} variant="h6" component="h6">
                  {renderPrice(totalPerPayee)}
                </Typography>
              </div>
            </div>
          </EstimateWrapper>
          <SubmitButtonWrapper>
            {/* {!isCapped && (
              <SubmitButton
                onClick={() => (window.location.href = 'https://bidoevents.com/payment-plan-wizard.php')}
              >
                Sign Up
              </SubmitButton>
            )} */}
            {!isCapped && (
              <SubmitButton onClick={() => setShowSignUp(true)} style={{ marginLeft: 'auto' }}>
                Sign Up
              </SubmitButton>
            )}
          </SubmitButtonWrapper>
          {showSignUp && (
            <SignUpPopup
              group={groupData}
              totalPerPersonExact={totalPerPersonExact}
              totalPerPayeeExact={totalPerPayeeExact}
              closePopup={() => setShowSignUp(false)}
              refetchGroup={refetchGroup}
            />
          )}
        </Col2>
      </Wrapper>
    </Container>
  );
};

export default Summary;
