import groupReducer, { initialState } from 'features/group-data-collection/groupSlice';
import pricesReducer, { initialState as initialStatePrices } from 'features/group-data-collection/pricesSlice';
import expGroupReducer, { initialState as initialStateExpGroup } from 'features/experience-page/expGroupSlice';
// import userReducer, { initialState as initialStateUser } from 'features/group-data-collection/groupSlice';
import { configureStore, ThunkAction, Action, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { MigrationManifest, PersistedState } from 'redux-persist/es/types';

const reducers = combineReducers({
  group: groupReducer,
  prices: pricesReducer,
  expGroup: expGroupReducer,
  // user: userReducer,
});

const migrations = {
  6: (state: any) => {
    return {
      group: { ...initialState },
      prices: { ...initialStatePrices },
      expGroup: { ...initialStateExpGroup },
    };
  },
};

const persistConfig = {
  key: 'redux-store',
  version: 6,
  storage,
  migrate: createMigrate(migrations as unknown as MigrationManifest, { debug: true }),
  autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
