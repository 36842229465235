export const LUMP_SUM_FORM_BASE_URL = 'https://bidoevents.com/lump-sum-wizard.php'; //TODO: ADD FINAL URL HERE
export const SUBSCRIPTION_FORM_BASE_URL = 'https://bidoevents.com/payment-plan-wizard.php'; //TODO: ADD FINAL URL HERE

export const CONFIG = {
  installmentOptions(monthsTilTrip: number) {
    let paymentInstallmentOptions = [
      {
        name: 'Lump sum',
        value: 'lump-sum',
      },
    ];

    if (monthsTilTrip >= 2 && monthsTilTrip < 12) {
      paymentInstallmentOptions.push({
        name: 'Payment Plan',
        value: 'payment-plan',
      });
    }
    return paymentInstallmentOptions;
  },
  LUMP_SUM_FORM_BASE_URL,
  SUBSCRIPTION_FORM_BASE_URL,
};

export default CONFIG;
