// eslint-disable-next-line import/no-anonymous-default-export
const Beds = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18.009" height="13.558" viewBox="0 0 18.009 13.558">
      <path
        id="Trazado_1400"
        data-name="Trazado 1400"
        d="M1030.342,769.857l-.378-3.949v-3.073a1.373,1.373,0,0,0-1.329-1.411h-9.34a1.375,1.375,0,0,0-1.33,1.411V765.9l-.045.475h-2.13v-1.264h1.394l-.837-3.742h-1.982l-1.017,3.742h1.442v1.264h-.453a1.532,1.532,0,0,0-1.53,1.53v4.607a1.531,1.531,0,0,0,1.53,1.53h3.628v.375a.482.482,0,1,0,.962,0v-.391H1029v.392h0v.044a.482.482,0,1,0,.964-.044v-.391h.367a.5.5,0,0,0,.481-.51v-3.092A.534.534,0,0,0,1030.342,769.857Zm-15.212-7.49h.416l.39,1.742h-1.28Zm3.8.468a.382.382,0,0,1,.368-.391h9.34a.38.38,0,0,1,.367.391v2.442h-.524v-1.653a.448.448,0,0,0-.482-.4h-3.4a.448.448,0,0,0-.481.4v1.653h-.306v-1.653a.446.446,0,0,0-.477-.4h-3.4a.448.448,0,0,0-.482.4v1.653h-.523Zm8.588,1.411v1.031h-2.433v-1.03Zm-4.67,0v1.031h-2.43v-1.03Zm-3.987,2.456a.518.518,0,0,0,.069-.264v-.14H1029v.14a.538.538,0,0,0,.068.261l.321,3.367h-10.855Zm-4.521.671h3.487l-.063.656h-1.753v1h1.657l-.064.675h-3.794v-1.8A.53.53,0,0,1,1014.337,767.373Zm-.53,5.137V770.7h3.308v.712h-1.107v1h1.107v.624h-2.778A.53.53,0,0,1,1013.807,772.51Zm16.044.495h-11.774v-1.919h11.774Z"
        transform="translate(-1012.807 -761.367)"
        fill="#bac3c9"
      />
    </svg>
  );
};

export default Beds;
