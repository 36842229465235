import React, { FunctionComponent, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import StepLabel from 'components/shared/step-label';
import { ButtonsWrapper, StyledButton } from './styled';
import SubmitButton from 'components/shared/submit-button';
import { ButtonsWrapper as ButtonsWrapper1 } from 'components/shared';
import BackButton from 'components/shared/back-button';
export interface FormAreaProps {
  initialValue?: boolean;
  stepLabel: string;
  heading: string;
  paragraph: string;
  children: React.ReactNode;
  isSubmitting?: boolean;
  onSubmit: (e: any, yes: boolean) => Promise<void>;
  setCustomYes?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  multiSelect?: boolean;
}

const FormArea: FunctionComponent<FormAreaProps> = ({
  stepLabel,
  heading,
  paragraph,
  initialValue,
  children,
  isSubmitting,
  onSubmit,
  setCustomYes,
  multiSelect,
  ...props
}) => {
  const [yes, setYes] = useState<boolean | undefined>(!!initialValue || true);
  return (
    <form onSubmit={(e) => onSubmit(e, !!yes)}>
      <StepLabel>{stepLabel}</StepLabel>
      <Typography
        variant="h4"
        component="h4"
        style={{
          marginBottom: '22px',
          opacity: 0,
          animation: 'fadein forwards 1s',
        }}
      >
        {heading || <Skeleton animation="wave" width={'70%'} />}
        {multiSelect && <Typography>You can select as many as you want.</Typography>}
      </Typography>
      <ButtonsWrapper
        style={{
          opacity: 0,
          animation: 'fadein forwards 1s',
        }}
      >
        <StyledButton
          variant={yes ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            if (setCustomYes) setCustomYes(true);
            setYes(true);
          }}
          className={yes ? 'pulsing' : ''}
        >
          Yes
        </StyledButton>
        <StyledButton
          variant={!yes ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => {
            if (setCustomYes) setCustomYes(false);
            setYes(false);
          }}
          className={!yes ? 'pulsing' : ''}
        >
          No
        </StyledButton>
      </ButtonsWrapper>
      {yes === false && (
        <>
          {paragraph ? (
            <Typography
              variant="h6"
              component="h6"
              style={{
                marginBottom: '40px',
                fontWeight: 'normal',
                opacity: 0,
                animation: 'fadein forwards 1s',
              }}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ) : (
            <>
              <Skeleton height={'40px'} animation="wave" width={'100%'} />
              <Skeleton height={'40px'} animation="wave" width={'20%'} />
            </>
          )}
        </>
      )}
      {yes !== undefined && children}
      {yes !== undefined && (
        <ButtonsWrapper1>
          <BackButton>Back</BackButton>
          <SubmitButton style={{ marginLeft: 'auto' }} loading={isSubmitting} id="submitStep">
            NEXT
          </SubmitButton>
        </ButtonsWrapper1>
      )}
    </form>
  );
};

export default FormArea;
