import React, { FunctionComponent } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Label, LabelText } from 'components/shared/label';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';

export interface PhoneInputProps {
  disabled?: boolean;
  error?: string;
  fullWidth?: boolean;
  inputProps?: any;
}

const Wrapper = styled(Label)`
  // tech debt
  .styled-phone-input {
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.bgInput};
    border: 1px solid ${({ theme }) => theme.colors.bg};
    height: 40px;
    display: block;
    margin-bottom: 15px;
    width: 100%;
    &::placeholder {
      color: ${({ theme }) => theme.colors.placeholder};
      font-size: 16px;
    }
    input {
      background: transparent !important;
      border: none !important;
    }
    .flag-dropdown {
      border: none !important;
      background: transparent !important;
      border-right: 1px solid #eaeaea !important;
      padding-right: 5px !important;
    }
    .selected-flag {
      background: transparent !important;
    }
  }
`;

const StyledPhoneInput: FunctionComponent<PhoneInputProps> = ({ disabled, error, fullWidth, ...props }) => {
  return (
    <Wrapper fullWidth>
      <LabelText fullWidth>Phone</LabelText>
      <PhoneInput
        containerClass="styled-phone-input"
        disabled={disabled}
        country={'us'}
        {...{ ...props, inputProps: { ...props.inputProps, autocomplete: 'off', autoComplete: 'off' } }}
      />
      {error && <p className="error-message">{error}</p>}
    </Wrapper>
  );
};

export default StyledPhoneInput;
