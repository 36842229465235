import React, { FunctionComponent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAppSelector, useMustHaveGroupId } from 'app/hooks';
import { BUILDER_PERSONALSERVICES_QUERY_DATA, BUILDER_PERSONALSERVICES_QUERY } from 'app/queries';
import {
  groupInfoSelector,
  destinationInfoSelector,
  submitPersonalServices,
  selectPersonalServices,
  selectGroupDataCollectionStatus,
  gotoSummarySelector,
  selectPayees,
} from 'features/group-data-collection/groupSlice';
import { Cards, SelectWrapper, StyledSkeleton } from './styled';
import Select from 'components/shared/select';
import Card from 'components/shared/card';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FormArea from '../form-area';
import { daysDiff, toggleFromArray, toggleFromObjArray, toUSD } from 'utils';
import Person from 'components/shared/icons/people';
import { CardWrapper } from 'components/shared';
import toast from 'react-hot-toast';
import { submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
import { Typography } from '@material-ui/core';
import useSort from 'hooks/useSort';
import { PriceService } from 'services/price';

export interface PersonalServicesProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const heading = 'Would you like to have any personal services?';
const paragraph = 'Are you sure? We have some great featured deals selected specifically for you.';

const PersonalServices: FunctionComponent<PersonalServicesProps> = ({ setActiveStep, ...props }) => {
  useEffect(() => {
    setActiveStep(3); //temp-no-night
  }, [setActiveStep]);
  useMustHaveGroupId();
  const history = useHistory();
  const initialPersonalServices = useAppSelector(selectPersonalServices);
  const [personalServices, setPersonalServices] = useState<null | { id?: string; qty?: number | string }[]>(
    initialPersonalServices || null
  );
  const group = useAppSelector(groupInfoSelector);
  const { groupSize } = group;
  const [personalServicesYes, sePersonalServicesYes] = useState<undefined | boolean>(!!initialPersonalServices || true);
  const { destination } = useAppSelector(destinationInfoSelector);
  const { data, loading: loadingPersonalServices } = useQuery<BUILDER_PERSONALSERVICES_QUERY_DATA>(
    BUILDER_PERSONALSERVICES_QUERY,
    {
      variables: {
        destination: destination.id,
        groupSize,
        // activityType: 'group',
      },
    }
  );
  const dispatch = useDispatch();
  const gotoSummary = useAppSelector(gotoSummarySelector);
  useEffect(() => {
    // we're insterested in knowing that the user got to ready because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'personal services' }));
  }, [dispatch]);

  const handleSubmit = async (e: any, yes: boolean) => {
    e.preventDefault();
    const selectedPersonalServicesData = personalServices?.map(({ id, qty }) => {
      const activity = data?.allPersonalServices.find((transp) => transp.id === id);
      if (activity) {
        return {
          id: activity.id,
          name: activity.name,
          qty: `${qty}`,
        };
      } else return undefined;
    });
    if (!selectedPersonalServicesData && yes) {
      toast.error('Please make your  personal services selection');
      return;
    }
    await dispatch(submitPersonalServices({ data: selectedPersonalServicesData }));
    if (gotoSummary) {
      //was clicked by checkout button (synthetic, non-human click)
      history.push('/builder/summary');
    } else {
      history.push('/builder/summary');
    }
  };
  const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);

  const [showPopup, setShowPopup] = useState(false);
  const [servicesPopupID, setServicesPopupID] = useState('');

  const [sorting, setSorting] = useState('priceDesc');

  const { sortedArray } = useSort({
    array: data?.allPersonalServices,
    groupSize,
    sorting,
    type: 'personal',
  });
  return (
    <>
      <FormArea
        heading={heading}
        paragraph={paragraph}
        isSubmitting={dataCollectionStatus === 'loading'}
        stepLabel="Personal Services"
        onSubmit={handleSubmit}
        initialValue={!!initialPersonalServices}
        setCustomYes={sePersonalServicesYes}
        multiSelect
      >
        <SelectWrapper>
          <Typography style={{ marginRight: '15px' }} variant="h4" component="h4">
            Sort:
          </Typography>
          <Select
            nomb
            options={[
              { name: 'Price - High to Low', value: 'priceDesc' },
              { name: 'Price - Low to High', value: 'priceAsc' },
            ]}
            onChange={(e) => {
              setSorting(e.target.value);
            }}
          />
        </SelectWrapper>
        {loadingPersonalServices && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
            </div>
          </div>
        )}
        <Cards>
          {personalServicesYes !== undefined &&
            sortedArray.map((personalServicesfetched: any, i: number) => {
              const selected = personalServices?.map((x) => x.id)?.includes(personalServicesfetched?.id);
              const thisPersonalService = personalServices?.find((x) => x.id == personalServicesfetched?.id);
              const qty = thisPersonalService?.qty;
              const reach = personalServicesfetched.reach;
              const price = PriceService.personalServices(
                {
                  reach,
                  groupSize,
                  price: personalServicesfetched?.price,
                  activityType: personalServicesfetched.activityType,
                  fromDate: group.fromDate,
                  untilDate: group.untilDate,
                },
                {
                  noCeroCero: true,
                  usd: true,
                  rounded: true,
                  labeled: true,
                }
              );
              const cardProps = {
                image: personalServicesfetched?.cardImage?.responsiveImage,
                title: personalServicesfetched?.name,
                icons: [{ Icon: Person, text: personalServicesfetched?.groupSizeMin + '+' }],
                paragraph: personalServicesfetched?.shortDescription,
                withQty: true,
                maxQty: 30,
                initialQty: qty || 1,
                withServicesPopup: {
                  showPopup,
                  servicesPopupID,
                  servicesfetched: personalServicesfetched,
                  setShowPopup,
                  setServices: setPersonalServices,
                },

                button1: {
                  text: 'LEARN MORE',
                  onClick: () => {
                    setServicesPopupID(personalServicesfetched?.id);
                    setShowPopup(true);
                  },
                },
                button2: {
                  text: selected ? 'Remove' : 'Select',
                  onClick: (qty: number) => {
                    setPersonalServices((prev) => {
                      return toggleFromObjArray(prev, { ...personalServicesfetched, qty });
                    });
                  },
                },
                featured: personalServicesfetched?.featured,
                selected,
                price,
              };
              if (personalServicesYes) {
                return (
                  <CardWrapper key={personalServicesfetched?.id}>
                    <Card {...cardProps} />
                  </CardWrapper>
                );
              } else {
                if (i < 3) {
                  return (
                    <CardWrapper key={personalServicesfetched?.id}>
                      <Card {...cardProps} />
                    </CardWrapper>
                  );
                } else {
                  return null;
                }
              }
            })}
        </Cards>
      </FormArea>
      <div id="scrollHelper" />
    </>
  );
};

export default PersonalServices;
