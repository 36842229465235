import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Container as MaterialContainer } from '@material-ui/core';

export const Container = styled(MaterialContainer)`
  min-height: 86vh;
  @media ${md} {
    min-height: 100vh;
  }
`;

export const ProgressMobile = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 22px 24px;
  display: flex;
  @media ${md} {
    display: none;
  }
`;

export const ProgressTabletAndAbove = styled.div`
  width: 100%;
  text-align: center;
  justify-content: space-between;
  padding: 22px 24px;
  display: none;
  @media ${md} {
    display: block;
    width: 13.5%;
    padding-top: 54px;
  }
  @media ${lg} {
    width: 8%;
  }
`;

export const Logo = styled.img`
  width: 50px;
  margin-right: 5px;
  min-width: 50px;
  @media only screen and (max-width: 374px) {
    width: auto;
  }
  @media ${md} {
    min-width: 0;
    width: 44px;
    margin-right: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 86vh;
  @media ${md} {
    display: flex;
    min-height: 100vh;
  }
`;

export const FormArea = styled.div`
  width: 100%;
  min-height: 86vh;
  @media ${md} {
    padding: 54px 48px;
    width: 100%;
    border-left: 2px solid ${({ theme }) => theme.colors.bgLight};
    min-height: 100vh;
  }
  @media ${lg} {
    width: 100%;
    padding: 54px 30px 40px 40px;
  }
`;

export const SummaryAreaMobile = styled.div`
  background: ${({ theme }) => theme.colors.bgDark};
  padding: 11px 30px;
  min-height: 73px;
  @media ${md} {
    display: none;
  }
`;

export const SummaryAreaTabletAndAbove = styled.div`
  display: none;

  @media ${md} {
    position: fixed;
    display: block;
    left: calc(768px * 0.732 + (100vw - 768px) / 2);
    width: calc((768px * 0.268) + (100vw - 768px) / 2);
    background: ${({ theme }) => theme.colors.bgLight};
    padding: 57px 25px;
    min-height: 100vh;
  }
  @media ${lg} {
    position: relative;
    left: 0;
    width: 20%;
    padding: 57px 29px;
  }
`;

export const ImageArea = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  display: none;
  @media ${lg} {
    display: block;
    height: 100%;
    width: 20%;
    overflow: hidden;
  }
`;

export const ImageWrapper = styled.div`
  @media ${lg} {
    position: fixed;
    top: 0;
    // function as model for image placement
    left: calc(1170px * 0.785 + (100vw - 1170px) / 2);
    width: calc((1170px * 0.215) + (100vw - 1170px) / 2);
    height: 100vh;
    overflow: hidden;
  }
`;
