import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { md, lg } from 'styles/themes';
import { Container as ExperienceContainer } from '../experience-builder/styled';

export const Container = styled(ExperienceContainer)`
  padding: 10px;
  height: 90vh;
  @media ${md} {
    padding: 20px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  background-color: rgb(246, 247, 249);
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  text-transform: none;
  font-weight: bold;
  display: block;
  margin-bottom: 15px;
  @media ${md} {
    width: 225px;
    display: inline;
    margin-bottom: 0;
  }
  @media ${lg} {
    width: 250px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 10px;
`;
