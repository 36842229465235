import { useState, useEffect } from 'react';

interface useWindowWidthInterface {
  windowWidth: number;
}

export default function useWindowWidth(initialValue = 0): useWindowWidthInterface {
  const [windowWidth, setWW] = useState(initialValue);
  useEffect(() => {
    if (window) {
      setWW(window.innerWidth);
      window.addEventListener('resize', () => setWW(window.innerWidth));
      return () => window.removeEventListener('resize', () => setWW(window.innerWidth));
    }
  }, []);

  return {
    windowWidth,
  };
}
