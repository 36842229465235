import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getExpGroupData, selectExpGroup } from 'features/experience-page/expGroupSlice';
import { useEffect } from 'react';

export function useExperienceGroupData(_id?: string) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (_id) {
      dispatch(getExpGroupData({ _id }));
    }
  }, [_id, dispatch]);
  const extGroupState = useAppSelector(selectExpGroup);
  const { status, data } = extGroupState;
  const loading = status === 'loading';
  const refetch = () => _id && dispatch(getExpGroupData({ _id }));
  return { data, loading, refetch };
}
