import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Container as MaterialContainer } from '@material-ui/core';

export const Container = styled(MaterialContainer)``;

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 46px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${md} {
    padding-bottom: 76px;
    padding-top: 62px;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    @media ${md} {
      font-size: 28px;
      margin-bottom: 28px;
    }
  }
`;

export const NumbsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 21px;
  margin-bottom: 32px;
  border: 1px solid #ededed;
  padding: 10px 0 3px 10px;
  @media ${md} {
    padding: 4px 1px 0;
  }
`;

export const NumbCircle = styled.div`
  margin-right: 10px;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0px;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  border: 1px solid #ededed;
  color: #bac3c9;
  font-size: 12px;
  &.active {
    border: none;
    color: #fff;
    background: transparent linear-gradient(183deg, #03a0ef 0%, #1a7aac 100%) 0% 0% no-repeat padding-box;
  }
  @media ${md} {
    margin-bottom: 4px;
    margin-right: 4px;
    margin-left: 4px;
  }
`;

export const PeopleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.last {
    margin-right: 0;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 28px;
  margin-bottom: 10px;
  &.last {
    margin-right: 0;
  }
`;
