import { useState, useEffect } from 'react';
import { PriceService } from 'services/price';

interface useSortInterface {
  sortedArray: any;
}

export default function useSort({
  array,
  fromDate,
  untilDate,
  groupSize,
  sorting,
  type,
}: {
  array: any;
  fromDate?: string;
  untilDate?: string;
  groupSize: number | string | undefined;
  sorting: string;
  type: string;
}): useSortInterface {
  const [sortedArray, setSA] = useState([] as any[]);

  useEffect(() => {
    switch (sorting) {
      case 'priceDesc':
        if (array) {
          const arrayToSort = [...array];
          if (type === 'housing' && fromDate && untilDate) {
            setSA(
              arrayToSort
                .sort((a, b) => {
                  return (
                    (PriceService.computeSeasonalPrice({
                      peakSeasonEnds: b?.destination.peakSeasonEnds,
                      peakSeasonStarts: b?.destination.peakSeasonStarts,
                      highSeasonStarts: b?.destination.highSeasonStarts,
                      highSeasonEnds: b?.destination.highSeasonEnds,
                      priceHigh: b?.priceHigh,
                      priceLow: b?.priceLow,
                      priceForHoliday: b?.priceForHoliday,
                      fromDate: new Date(fromDate),
                      untilDate: new Date(untilDate),
                      groupSize: groupSize,
                    }) || 0) -
                    (PriceService.computeSeasonalPrice({
                      peakSeasonEnds: a?.destination.peakSeasonEnds,
                      peakSeasonStarts: a?.destination.peakSeasonStarts,
                      highSeasonStarts: a?.destination.highSeasonStarts,
                      highSeasonEnds: a?.destination.highSeasonEnds,
                      priceHigh: a?.priceHigh,
                      priceLow: a?.priceLow,
                      priceForHoliday: a?.priceForHoliday,
                      fromDate: new Date(fromDate),
                      untilDate: new Date(untilDate),
                      groupSize: groupSize,
                    }) || 0)
                  );
                })
                .sort((a, b) => {
                  if (a.featured && b.featured) return 0;
                  if (!a.featured && !b.featured) return 0;
                  if (!a.featured && b.featured) return 1;
                  return -1;
                })
            );
          } else {
            setSA(
              arrayToSort
                .sort((a, b) => b?.price / (Number(groupSize) || 1) - a?.price / (Number(groupSize) || 1))
                .sort((a, b) => {
                  if (a.featured && b.featured) return 0;
                  if (!a.featured && !b.featured) return 0;
                  if (!a.featured && b.featured) return 1;
                  return -1;
                })
            );
          }
        }

        break;
      case 'priceAsc':
        if (array) {
          const arrayToSort = [...array];
          if (type === 'housing' && fromDate && untilDate) {
            setSA(
              arrayToSort
                .sort((a, b) => {
                  return (
                    (PriceService.computeSeasonalPrice({
                      peakSeasonEnds: a?.destination.peakSeasonEnds,
                      peakSeasonStarts: a?.destination.peakSeasonStarts,
                      highSeasonStarts: a?.destination.highSeasonStarts,
                      highSeasonEnds: a?.destination.highSeasonEnds,
                      priceHigh: a?.priceHigh,
                      priceLow: a?.priceLow,
                      priceForHoliday: a?.priceForHoliday,
                      fromDate: new Date(fromDate),
                      untilDate: new Date(untilDate),
                      groupSize: groupSize,
                    }) || 0) -
                    (PriceService.computeSeasonalPrice({
                      peakSeasonEnds: b?.destination.peakSeasonEnds,
                      peakSeasonStarts: b?.destination.peakSeasonStarts,
                      highSeasonStarts: b?.destination.highSeasonStarts,
                      highSeasonEnds: b?.destination.highSeasonEnds,
                      priceHigh: b?.priceHigh,
                      priceLow: b?.priceLow,
                      priceForHoliday: b?.priceForHoliday,
                      fromDate: new Date(fromDate),
                      untilDate: new Date(untilDate),
                      groupSize: groupSize,
                    }) || 0)
                  );
                })
                .sort((a, b) => {
                  if (a.featured && b.featured) return 0;
                  if (!a.featured && !b.featured) return 0;
                  if (!a.featured && b.featured) return 1;
                  return -1;
                }) || []
            );
          } else {
            setSA(
              arrayToSort
                .sort((a, b) => a?.price / (Number(groupSize) || 1) - b?.price / (Number(groupSize) || 1))
                .sort((a, b) => {
                  if (a.featured && b.featured) return 0;
                  if (!a.featured && !b.featured) return 0;
                  if (!a.featured && b.featured) return 1;
                  return -1;
                })
            );
          }
        }
        break;
      case 'bedsDesc':
        if (array) {
          const arrayToSort = [...array];
          if (type === 'housing') {
            setSA(
              arrayToSort
                .sort((a, b) => {
                  return Number.parseInt(b?.sleeps || 0) - Number.parseInt(a?.sleeps || 0);
                })
                .sort((a, b) => {
                  if (a.featured && b.featured) return 0;
                  if (!a.featured && !b.featured) return 0;
                  if (!a.featured && b.featured) return 1;
                  return -1;
                }) || []
            );
          }
        }
        break;
      case 'bedsAsc':
        if (array) {
          const arrayToSort = [...array];
          if (type === 'housing') {
            setSA(
              arrayToSort
                .sort((a, b) => {
                  return Number.parseInt(a?.sleeps || 0) - Number.parseInt(b?.sleeps || 0);
                })
                .sort((a, b) => {
                  if (a.featured && b.featured) return 0;
                  if (!a.featured && !b.featured) return 0;
                  if (!a.featured && b.featured) return 1;
                  return -1;
                }) || []
            );
          }
        }
        break;
      case 'seatsDesc':
        if (array) {
          const arrayToSort = [...array];
          if (type === 'transportation') {
            setSA(
              arrayToSort
                .sort((a, b) => {
                  return Number.parseInt(b?.passengers || 0) - Number.parseInt(a?.passengers || 0);
                })
                .sort((a, b) => {
                  if (a.featured && b.featured) return 0;
                  if (!a.featured && !b.featured) return 0;
                  if (!a.featured && b.featured) return 1;
                  return -1;
                }) || []
            );
          }
        }
        break;
      case 'seatsAsc':
        if (array) {
          const arrayToSort = [...array];
          if (type === 'transportation') {
            setSA(
              arrayToSort
                .sort((a, b) => {
                  return Number.parseInt(a?.passengers || 0) - Number.parseInt(b?.passengers || 0);
                })
                .sort((a, b) => {
                  if (a.featured && b.featured) return 0;
                  if (!a.featured && !b.featured) return 0;
                  if (!a.featured && b.featured) return 1;
                  return -1;
                }) || []
            );
          }
        }
        break;

      default:
        break;
    }
  }, [sorting, array, fromDate, groupSize, untilDate]);

  return {
    sortedArray,
  };
}
