import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Button } from '@material-ui/core';

export const ButtonsWrapper = styled.div`
  max-width: 400px;
  margin-bottom: 30px;

  @media ${lg} {
  }
`;

export const StyledButton = styled(Button)`
  min-width: 125px;
  margin-right: 17px;
`;