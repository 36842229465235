import { useQuery } from '@apollo/client';
import {
  SUMMARY_HOUSING_QUERY_DATA,
  SUMMARY_HOUSING_QUERY,
  SUMMARY_TRANSPORTATION_QUERY_DATA,
  SUMMARY_TRANSPORTATION_QUERY,
  SUMMARY_DAYTIMEACTIVITIES_QUERY_DATA,
  SUMMARY_DAYTIMEACTIVITIES_QUERY,
  SUMMARY_NIGHTTIMEACTIVITIES_QUERY_DATA,
  SUMMARY_NIGHTTIMEACTIVITIES_QUERY,
  SUMMARY_PERSONALSERVICES_QUERY_DATA,
  SUMMARY_PERSONALSERVICES_QUERY,
  GROUP_DATA,
} from 'app/queries';
import { ExpGroupData } from 'features/experience-page/expGroupSlice';
import { PriceService } from 'services/price';
import {
  computePersDataWithQty,
  computeTotalPrice,
  computeTranspDataWithQty,
  daysDiff,
  toUSD,
  toUSDNoDecimal,
} from 'utils';
export const useExperience = (group?: ExpGroupData) => {
  const { data: housingData, loading: loadingHousing } = useQuery<SUMMARY_HOUSING_QUERY_DATA>(SUMMARY_HOUSING_QUERY, {
    variables: {
      id: group?.housing?.id,
    },
    skip: !group?.housing?.id,
  });

  const { data: transportationData, loading: loadingTransportation } = useQuery<SUMMARY_TRANSPORTATION_QUERY_DATA>(
    SUMMARY_TRANSPORTATION_QUERY,
    {
      variables: {
        ids: group?.transportation?.map(({ id }) => id),
      },
      skip: !group?.transportation,
    }
  );

  const { data: daytimeActivitiesData, loading: loadingDaytimeactivities } =
    useQuery<SUMMARY_DAYTIMEACTIVITIES_QUERY_DATA>(SUMMARY_DAYTIMEACTIVITIES_QUERY, {
      variables: {
        ids: group?.daytimeActivities?.map(({ id }) => id),
      },
      skip: !group?.daytimeActivities,
    });

  const { data: nighttimeActivitiesData, loading: loadingNighttimeactivities } =
    useQuery<SUMMARY_NIGHTTIMEACTIVITIES_QUERY_DATA>(SUMMARY_NIGHTTIMEACTIVITIES_QUERY, {
      variables: {
        ids: group?.nighttimeActivities?.map(({ id }) => id),
      },
      skip: !group?.nighttimeActivities,
    });

  const { data: personalServicesData, loading: loadingPersonalServicesData } =
    useQuery<SUMMARY_PERSONALSERVICES_QUERY_DATA>(SUMMARY_PERSONALSERVICES_QUERY, {
      variables: {
        ids: group?.personalServices?.map(({ id }) => id),
      },
      skip: !group?.personalServices,
    });

  const loading =
    loadingHousing ||
    loadingTransportation ||
    loadingDaytimeactivities ||
    loadingNighttimeactivities ||
    loadingPersonalServicesData;

  return {
    housingData,
    daytimeActivitiesData,
    nighttimeActivitiesData,
    group,
    loading,
    transportationData: transportationData?.allTransportations?.map(({ name, id, ...other }) => {
      const tr = group?.transportation?.find((transp) => transp.id == id);
      return {
        ...tr,
        ...other,
        name,
      };
    }),
    personalServicesData: personalServicesData?.allPersonalServices?.map(({ name, id, ...other }) => {
      const ps = group?.personalServices?.find((transp) => transp.id == id);
      return {
        ...ps,
        ...other,
        name,
      };
    }),
  };
};
