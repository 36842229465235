export const SumDestination = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51.43" height="51.429" viewBox="0 0 51.43 51.429">
      <g id="Group_1873" data-name="Group 1873" transform="translate(0 0)">
        <g id="Group_1702" data-name="Group 1702" transform="translate(0 0)">
          <g id="Group_1698" data-name="Group 1698" transform="translate(11.214 9.602)">
            <g id="Group_1693" data-name="Group 1693" transform="translate(12.015 5.359)">
              <path
                id="Path_1382"
                data-name="Path 1382"
                d="M7225.14-845.046h-12.014a.751.751,0,0,1-.751-.751c0-2.667,2.969-4.756,6.759-4.756s6.757,2.089,6.757,4.756A.75.75,0,0,1,7225.14-845.046Zm-11.116-1.5h10.218c-.565-1.416-2.675-2.5-5.108-2.5S7214.589-847.964,7214.024-846.548Z"
                transform="translate(-7212.375 850.553)"
                fill="#353743"
              />
            </g>
            <g id="Group_1694" data-name="Group 1694" transform="translate(0 5.359)">
              <path
                id="Path_1383"
                data-name="Path 1383"
                d="M7135.14-845.046a.751.751,0,0,1-.751-.751c0-1.764-2.407-3.254-5.255-3.254-2.434,0-4.545,1.087-5.109,2.5h5.886a.75.75,0,0,1,.75.751.75.75,0,0,1-.75.751h-6.785a.751.751,0,0,1-.75-.751c0-2.667,2.969-4.756,6.758-4.756s6.758,2.089,6.758,4.756A.751.751,0,0,1,7135.14-845.046Z"
                transform="translate(-7122.375 850.553)"
                fill="#353743"
              />
            </g>
            <g id="Group_1695" data-name="Group 1695" transform="translate(11.155)">
              <path
                id="Path_1384"
                data-name="Path 1384"
                d="M7206.7-881.833a.753.753,0,0,1-.749-.7,7.67,7.67,0,0,1,2.484-5.662c2.68-2.68,6.257-3.3,8.143-1.416a.752.752,0,0,1,.22.531.752.752,0,0,1-.22.531l-4.8,4.8a.752.752,0,0,1-1.062,0,.75.75,0,0,1,0-1.062l4.162-4.162c-1.4-.6-3.661.122-5.382,1.842a6.261,6.261,0,0,0-2.048,4.5.75.75,0,0,1-.7.8Z"
                transform="translate(-7205.936 890.7)"
                fill="#353743"
              />
            </g>
            <g id="Group_1696" data-name="Group 1696" transform="translate(2.658 8.496)">
              <path
                id="Path_1385"
                data-name="Path 1385"
                d="M7143.9-816.195a.75.75,0,0,1-.531-.22,4.194,4.194,0,0,1-.964-3.925,8.471,8.471,0,0,1,2.38-4.217c2.681-2.68,6.256-3.3,8.142-1.416a.752.752,0,0,1,.221.531.752.752,0,0,1-.221.531l-8.5,8.5A.744.744,0,0,1,7143.9-816.195Zm6.224-9.351a6.474,6.474,0,0,0-4.276,2.052,6.967,6.967,0,0,0-1.97,3.45,3.257,3.257,0,0,0,.126,1.934l7.226-7.226A2.794,2.794,0,0,0,7150.122-825.546Z"
                transform="translate(-7142.287 827.061)"
                fill="#353743"
              />
            </g>
            <g id="Group_1697" data-name="Group 1697" transform="translate(11.587 9.364)">
              <path
                id="Path_1386"
                data-name="Path 1386"
                d="M7218.36-798.369h-4a.751.751,0,0,1-.751-.751c0-10.2-1.521-17.375-4.173-19.674a.75.75,0,0,1-.076-1.059.751.751,0,0,1,1.06-.076q.092.081.187.17a.747.747,0,0,1,.086-.4.752.752,0,0,1,1.016-.311c5.052,2.685,7.407,9.468,7.407,21.345A.751.751,0,0,1,7218.36-798.369Zm-3.256-1.5h2.5c-.09-10.533-2.1-16.627-6.291-19.092C7213.169-816.552,7215.026-811.282,7215.1-799.871Z"
                transform="translate(-7209.172 820.553)"
                fill="#353743"
              />
            </g>
          </g>
          <g id="Group_1700" data-name="Group 1700" transform="translate(0 0)">
            <path
              id="Path_1388"
              data-name="Path 1388"
              d="M7064.09-911.2a25.744,25.744,0,0,1-25.716-25.715,25.744,25.744,0,0,1,25.716-25.715A25.744,25.744,0,0,1,7089.8-936.91,25.744,25.744,0,0,1,7064.09-911.2Zm0-49.927a24.24,24.24,0,0,0-24.213,24.213A24.24,24.24,0,0,0,7064.09-912.7,24.24,24.24,0,0,0,7088.3-936.91,24.24,24.24,0,0,0,7064.09-961.123Z"
              transform="translate(-7038.374 962.625)"
              fill="#353743"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SumDestination;
