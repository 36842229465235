import {
  SUMMARY_HOUSING_QUERY_DATA,
  SUMMARY_HOUSING_QUERY,
  SUMMARY_TRANSPORTATION_QUERY_DATA,
  SUMMARY_TRANSPORTATION_QUERY,
  SUMMARY_DAYTIMEACTIVITIES_QUERY_DATA,
  SUMMARY_DAYTIMEACTIVITIES_QUERY,
  SUMMARY_NIGHTTIMEACTIVITIES_QUERY_DATA,
  SUMMARY_NIGHTTIMEACTIVITIES_QUERY,
  SUMMARY_PERSONALSERVICES_QUERY_DATA,
  SUMMARY_PERSONALSERVICES_QUERY,
  GROUP_DATA,
} from 'app/queries';
import { groupState } from 'features/group-data-collection/groupSlice';
import { TransportationTrip } from 'types';

/**
 *
 * @param fromDate Date
 * @param untilDate Date
 * @returns Number of days between two dates, rounds up.
 */
export const daysDiff = (fromDate: Date, untilDate: Date): number => {
  const diffTime = Math.abs(fromDate.getTime() - untilDate.getTime());
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export function getQueryParams(searchQuery: string) {
  const search = searchQuery;
  const params = new URLSearchParams(search);
  let paramObj: { [key: string]: any } = {};
  for (var value of params.keys()) {
    paramObj[value] = params.get(value);
  }
  return paramObj;
}

export const parseDate = (dateInput: Date) => {
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const weekDay = weekDays[dateInput.getDay()];
  const month = months[dateInput.getMonth()];
  const date = dateInput.getDate();
  const year = dateInput.getFullYear();
  const hours = dateInput.getHours();
  const minutes = dateInput.getMinutes();

  return {
    weekDay,
    month,
    date,
    year,
    hours,
    minutes: `${minutes}`.length === 1 ? `0${minutes}` : minutes,
  };
};

/**
 *
 * @param arr Array holding items
 * @param item item to toggle from array
 * @returns Array[T]
 */
export function toggleFromArray<T>(arr: T[] | null, item: T) {
  if (arr) {
    if (arr.includes(item)) {
      //remove clicked
      const newarr = [...arr];
      newarr.splice(arr.indexOf(item), 1);
      return newarr;
    } else {
      //add clicked
      return [...arr, item];
    }
  } else return [item]; //first clicked
}

interface ObjectWithId {
  id?: string;
  qty?: number | string;
  trip?: TransportationTrip;
}

/**
 *
 * @param arr Array holding items
 * @param item item to toggle from array
 * @returns Array[T]
 */
export function toggleFromObjArray(arr: ObjectWithId[] | null, item: ObjectWithId) {
  if (arr) {
    const indexOf = arr.map((x) => x.id).indexOf(item.id);
    if (indexOf !== -1) {
      //remove clicked
      const newarr = [...arr];
      newarr.splice(indexOf, 1);
      return newarr;
    } else {
      //add clicked
      return [...arr, item];
    }
  } else return [item]; //first clicked
}

/**
 * @function
 * Formats float to USD value including currency symbol
 */
const toUsd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

/**
 *
 * @param number number to format as USD.
 * @returns string with number formatted as USD
 */
export function toUSD(number: number | string): string {
  return toUsd.format(Number(number));
}

export function toUSDNoDecimal(number: number): string {
  return `${toUSD(Math.round(number))}`.replace(/\D00(?=\D*$)/, '');
}

interface ComputeTotalPrice {
  housingPricePerPerson: number;
  groupSize: number | string | undefined;
  transportationDataWithQty?: SUMMARY_TRANSPORTATION_QUERY_DATA | undefined;
  daytimeActivitiesData: SUMMARY_DAYTIMEACTIVITIES_QUERY_DATA | undefined;
  nighttimeActivitiesData: SUMMARY_NIGHTTIMEACTIVITIES_QUERY_DATA | undefined;
  personalServicesData: SUMMARY_PERSONALSERVICES_QUERY_DATA | undefined;
  fromDate: Date;
  untilDate: Date;
}

/**
 *
 * @param any
 * @returns Total group price, accounting for group size and days of trip.
 */
export const computeTotalPrice = ({
  housingPricePerPerson,
  groupSize,
  fromDate,
  untilDate,
  transportationDataWithQty,
  daytimeActivitiesData,
  nighttimeActivitiesData,
  personalServicesData,
}: ComputeTotalPrice) => {
  if (groupSize) {
    //total price of housing accounting for group size and days of trip
    const housingTotal = housingPricePerPerson && groupSize ? housingPricePerPerson * Number(groupSize) : 0;
    //total price of transportation account for group size and quantity
    const transportationTotal =
      transportationDataWithQty?.allTransportations?.reduce(
        (acc, curr) => acc + Number.parseFloat(`${curr.price * Number(curr.qty || 0)}`),
        0
      ) || 0;
    //total daytime activities
    const daytimeActivitiesTotal =
      daytimeActivitiesData?.allDaytimeActivities.reduce((acc, curr) => {
        const price = Number.parseFloat(`${curr.price}`);
        if (curr.activityType === 'group') {
          return acc + price;
        } else {
          //individual: must multiply by the group size in orger to get the total cost
          return acc + price * Number(groupSize);
        }
      }, 0) || 0;
    const nighttimeActivitiesTotal =
      nighttimeActivitiesData?.allNighttimeActivities.reduce((acc, curr) => {
        const price = Number.parseFloat(`${curr.price}`);
        if (curr.activityType === 'group') {
          return acc + price;
        } else {
          //individual: must multiply by the group size in orger to get the total cost
          return acc + price * Number(groupSize);
        }
      }, 0) || 0;
    const personalServicesTotal =
      personalServicesData?.allPersonalServices?.reduce((acc, curr) => {
        const reach = curr.reach;
        const isTrip = reach === 'trip';
        const isIndividual = curr.activityType === 'individual';
        const basePrice = isIndividual //group price must be divided by groupSize
          ? Number.parseFloat(`${curr?.price}`) * Number(groupSize)
          : Number.parseFloat(`${curr?.price}`);
        const priceMultiplier = isTrip ? daysDiff(fromDate, untilDate) : 1; // trip price must be multiplied by duration of trip.
        const price = basePrice * priceMultiplier * (curr.qty || 1);
        return acc + price;
      }, 0) || 0;

    return (
      housingTotal + transportationTotal + daytimeActivitiesTotal + nighttimeActivitiesTotal + personalServicesTotal
    );
  } else {
    return 0;
  }
};

export const computeTranspDataWithQty = (
  transportationData: SUMMARY_TRANSPORTATION_QUERY_DATA | undefined,
  group: groupState | GROUP_DATA
) => {
  return transportationData?.allTransportations?.map((transp) => {
    const match = group?.transportation?.find((x) => x.id == transp.id);
    return {
      ...transp,
      qty: match?.qty || 0,
      trip: match?.trip || 'one-way',
    };
  });
};

export const computePersDataWithQty = (
  personalServicesData: SUMMARY_PERSONALSERVICES_QUERY_DATA | undefined,
  group: groupState | GROUP_DATA
) => {
  return personalServicesData?.allPersonalServices?.map((service) => {
    const match = group?.personalServices?.find((x) => x.id == service.id);
    return {
      ...service,
      qty: match?.qty || 0,
    };
  });
};

export const computeMonthsTilTrip = (fromDate: string) => {
  return (
    Math.floor((new Date(fromDate || new Date()).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24 * 30)) || 1
  );
};
