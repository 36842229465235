import { Image } from 'react-datocms';
import styled from 'styled-components';
import { lg } from 'styles/themes';
export const StyledImage = styled(Image)`
  transition: ease-out 0.25s;
  @media ${lg} {
    width: calc((1170px * 0.23) + (100vw - 1170px) / 2);
    height: 100vh;
    position: absolute !important;
    top: 0;
    left: 0;
    object-fit: cover;
    animation-name: floating;
    animation-duration: 120s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes floating {
    0% {
      transform: translate(0, 0px) scale(1);
    }
    25% {
      transform: translate(-12px, -5px) scale(1.1);
    }
    50% {
      transform: translate(0px, 5px) scale(1.1);
    }
    100% {
      transform: translate(0, 0px);
    }
  }
`;
