import styled from 'styled-components';
import { lg, md } from 'styles/themes';
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
  position: relative;
  margin-bottom: 50px;
`;

export const Typography = styled.div`
  font-size: 14px;
`;

export const IconsWrapper = styled.div`
  width: 100%;
  border: solid 1px #eaeaea;
  border-radius: 8px;
  @media ${md} {
    display: flex;
    flex-direction: row;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: solid 1px #eaeaea;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${md} {
    display: block;
    width: 33%;
    border-bottom: none;
    text-align: start;
  }

  @media ${lg} {
    padding: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImageIconWrapper = styled.div`
  width: 52px;
  height: 52px;
  margin-right: 5px;
`;

export const ImageIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-right: 2px;
`;

export const FlexText = styled.div`
  display: flex;
  flex-direction: column;
`;
