import { FunctionComponent, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Root,
  Popup,
  Image,
  ImageWrapper,
  InfoWrapper,
  ImageWrapper2,
  ImageWrapper3,
  Overlay,
  Col1,
  Col2,
  DetailsWrapper,
  Row,
  IconNumFlex,
  ButtonsWrapper,
  ImagesPopup,
  SelectWrapper,
  Select,
} from './styled';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { useQuery } from '@apollo/client';
import { BUILDER_SINGLE_PERSONALSERVICES_QUERY_DATA, BUILDER_SINGLE_PERSONALSERVICES_QUERY } from 'app/queries';
import { Button, Typography } from '@material-ui/core';
import ButtonImages from 'components/shared/icons/button-images';
import BackButton from '../back-button';
import Portal from '../portal';

interface IServicesPopup {
  id: string;
  props?: any;
  setServices: (qty: number | string) => void;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  initialQty?: number | string;
}
SwiperCore.use([Pagination, Navigation]);

const ServicesPopup: FunctionComponent<IServicesPopup> = ({
  setShowPopup,
  setServices,
  id,
  initialQty = 1,
  ...props
}) => {
  const { data, loading: loadingTransportation } = useQuery<BUILDER_SINGLE_PERSONALSERVICES_QUERY_DATA>(
    BUILDER_SINGLE_PERSONALSERVICES_QUERY,
    {
      variables: {
        id: id,
      },
    }
  );
  const params: Swiper = {
    slidesPerView: 1,
    preloadImages: false,
    autoplay: true,
    loop: true,
    navigation: true,
    pagination: {
      clickable: true,
      el: '.swiper-pagination-hero',
    },
  };
  const service = data?.allPersonalServices[0];
  const images = service?.showcaseImages;
  const [openImages, setOpenImages] = useState(false);
  const [mainPopup, setMainPopup] = useState(true);

  return (
    <Portal>
      <Root>
        <Overlay onClick={() => setShowPopup(false)} />
        {!openImages && mainPopup && (
          <Popup>
            <p
              onClick={() => setShowPopup(false)}
              style={{
                position: 'absolute',
                top: '8.5px',
                right: '8.5px',
                zIndex: 100,
                margin: '0',
                fontSize: '20px',
                lineHeight: '14px',
                color: '#353743',
                cursor: 'pointer',
              }}
            >
              x
            </p>
            {!loadingTransportation && (
              <div>
                <Typography style={{ marginBottom: '20px' }} variant="h5" component="h5">
                  {service?.name}
                </Typography>
                <div className="swiperWrapper">
                  <Swiper {...params}>
                    {images &&
                      images.map(({ responsiveImage }, i) => {
                        return (
                          <SwiperSlide>
                            <ImageWrapper>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper>
                          </SwiperSlide>
                        );
                      })}
                    {/* <div className="swiper-pagination-hero"></div> */}
                  </Swiper>
                </div>
                <div className="images">
                  <div style={{ width: '49.5%' }}>
                    {images?.[0]?.responsiveImage && (
                      <ImageWrapper>
                        <Image data={images[0].responsiveImage}></Image>
                      </ImageWrapper>
                    )}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '49.5%' }}>
                    {images &&
                      images.map(({ responsiveImage }, i) => {
                        if (i === 0 || i > 4) return null;
                        return (
                          <ImageWrapper2>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper2>
                        );
                      })}
                  </div>
                  <ButtonImages
                    onClick={() => {
                      setOpenImages(true);
                      setMainPopup(false);
                    }}
                    style={{ cursor: 'pointer', position: 'absolute', bottom: '10px', right: '10px' }}
                  />
                </div>
                <InfoWrapper>
                  <Col1>
                    <Typography style={{ marginBottom: '13px' }} variant="h5" component="h5">
                      Description
                    </Typography>
                    <Typography
                      variant="caption"
                      dangerouslySetInnerHTML={{ __html: service?.description || '' }}
                      component="p"
                    ></Typography>
                  </Col1>
                </InfoWrapper>
                <ButtonsWrapper>
                  <BackButton className="backButton__popup" customOnClick={() => setShowPopup(false)} />
                </ButtonsWrapper>
              </div>
            )}
          </Popup>
        )}
        {!mainPopup && openImages && (
          <ImagesPopup>
            <p
              onClick={() => {
                setOpenImages(false);
                setMainPopup(true);
              }}
              style={{
                position: 'absolute',
                top: '8.5px',
                right: '8.5px',
                zIndex: 100,
                margin: '0',
                fontSize: '20px',
                lineHeight: '14px',
                color: '#353743',
                cursor: 'pointer',
              }}
            >
              x
            </p>
            <Swiper {...params}>
              {images &&
                images.map(({ responsiveImage }, i) => {
                  return (
                    <SwiperSlide>
                      <ImageWrapper3>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper3>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </ImagesPopup>
        )}
      </Root>
    </Portal>
  );
};

export default ServicesPopup;
