import React, { FunctionComponent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAppSelector, useMustHaveGroupId } from 'app/hooks';
import { BUILDER_NIGHTTIMEACTIVITIES_QUERY, BUILDER_NIGHTTIMEACTIVITIES_QUERY_DATA } from 'app/queries';
import {
  groupInfoSelector,
  destinationInfoSelector,
  submitNighttimeActivities,
  selectNighttimeActivities,
  selectGroupDataCollectionStatus,
  gotoSummarySelector,
} from 'features/group-data-collection/groupSlice';
import { Cards, SelectWrapper, StyledSkeleton } from './styled';
import Select from 'components/shared/select';
import { CardWrapper } from 'components/shared';
import Card from 'components/shared/card';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FormArea from '../form-area';
import { toggleFromArray, toUSD } from 'utils';
import Person from 'components/shared/icons/people';
import toast from 'react-hot-toast';
import { submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
import { Typography } from '@material-ui/core';
import useSort from 'hooks/useSort';
import { PriceService } from 'services/price';

export interface NighttimeActivitiesProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const heading = 'Do you want to do any activities at night time?';
const paragraph = 'Are you sure? We have some great featured deals selected specifically for you.';

const NighttimeActivities: FunctionComponent<NighttimeActivitiesProps> = ({ setActiveStep, ...props }) => {
  useEffect(() => {
    setActiveStep(3);
  }, [setActiveStep]);
  useMustHaveGroupId();
  const history = useHistory();
  const initialNighttimeActivities = useAppSelector(selectNighttimeActivities);
  const [nighttimeActivities, setNighttimeActivities] = useState<null | string[]>(initialNighttimeActivities || null);
  const [nighttimeActivitiesYes, seNighttimeActivitiesYes] = useState<undefined | boolean>(
    !!initialNighttimeActivities || true
  );
  const { groupSize } = useAppSelector(groupInfoSelector);
  const { destination } = useAppSelector(destinationInfoSelector);
  const { data, loading: loadingNighttimeActivities } = useQuery<BUILDER_NIGHTTIMEACTIVITIES_QUERY_DATA>(
    BUILDER_NIGHTTIMEACTIVITIES_QUERY,
    {
      variables: {
        destination: destination.id,
        groupSize,
        // activityType: 'group',
      },
    }
  );
  const dispatch = useDispatch();
  const gotoSummary = useAppSelector(gotoSummarySelector);
  useEffect(() => {
    // we're insterested in knowing that the user got to ready because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'nighttime activities' }));
  }, [dispatch]);
  const handleSubmit = async (e: any, yes: boolean) => {
    e.preventDefault();
    const selectedNighttimeActivitiesData = nighttimeActivities?.map((id) => {
      const activity = data?.allNighttimeActivities.find((transp) => transp.id === id);
      if (activity) {
        return {
          id: activity.id,
          name: activity.name,
        };
      } else return undefined;
    });
    if (!selectedNighttimeActivitiesData && yes) {
      toast.error('Please make your nighttime activity selection');
      return;
    }
    await dispatch(submitNighttimeActivities({ data: selectedNighttimeActivitiesData }));
    if (gotoSummary) {
      //was clicked by checkout button (synthetic, non-human click)
      history.push('/builder/summary');
    } else {
      history.push('/builder/personal-services');
    }
  };
  const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);

  const [sorting, setSorting] = useState('priceDesc');

  const { sortedArray } = useSort({
    array: data?.allNighttimeActivities,
    groupSize,
    sorting,
    type: 'nighttime',
  });
  return (
    <>
      <FormArea
        heading={heading}
        paragraph={paragraph}
        isSubmitting={dataCollectionStatus === 'loading'}
        stepLabel="Nighttime Activitites"
        onSubmit={handleSubmit}
        initialValue={!!initialNighttimeActivities}
        setCustomYes={seNighttimeActivitiesYes}
        multiSelect
      >
        <SelectWrapper>
          <Typography style={{ marginRight: '15px' }} variant="h4" component="h4">
            Sort:
          </Typography>
          <Select
            nomb
            options={[
              { name: 'Price - High to Low', value: 'priceDesc' },
              { name: 'Price - Low to High', value: 'priceAsc' },
            ]}
            onChange={(e) => {
              setSorting(e.target.value);
            }}
          />
        </SelectWrapper>

        {loadingNighttimeActivities && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
              <StyledSkeleton animation="wave" height={'270px'} />
            </div>
          </div>
        )}
        <Cards>
          {nighttimeActivitiesYes !== undefined &&
            sortedArray.map((nighttimeActivitiesfetched: any, i: number) => {
              const selected = nighttimeActivities?.includes(nighttimeActivitiesfetched?.id);
              const price = PriceService.nighttimeActivities(
                {
                  groupSize,
                  price: nighttimeActivitiesfetched.price,
                  activityType: nighttimeActivitiesfetched.activityType,
                },
                { usd: true, labeled: true, noCeroCero: true, rounded: true }
              );

              const cardProps = {
                image: nighttimeActivitiesfetched?.cardImage?.responsiveImage,
                title: nighttimeActivitiesfetched?.name,
                icons: [{ Icon: Person, text: nighttimeActivitiesfetched?.groupSizeMin + '+' }],
                paragraph: nighttimeActivitiesfetched?.shortDescription,
                button2: {
                  text: selected ? 'Remove Activity' : 'Select Activity',
                  onClick: () =>
                    setNighttimeActivities((prev) => {
                      return toggleFromArray(prev, nighttimeActivitiesfetched?.id);
                    }),
                },
                featured: nighttimeActivitiesfetched?.featured,
                selected,
                price,
              };
              if (nighttimeActivitiesYes) {
                return (
                  <CardWrapper key={nighttimeActivitiesfetched?.id}>
                    <Card {...cardProps} />
                  </CardWrapper>
                );
              } else {
                if (i < 3) {
                  return (
                    <CardWrapper key={nighttimeActivitiesfetched?.id}>
                      <Card {...cardProps} />
                    </CardWrapper>
                  );
                } else {
                  return null;
                }
              }
            })}
        </Cards>
      </FormArea>
      <div id="scrollHelper" />
    </>
  );
};

export default NighttimeActivities;
