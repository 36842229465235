import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Container,
  ProgressTabletAndAbove,
  Wrapper,
  FormArea,
  ImageArea,
  ImageWrapper,
  Logo,
  SummaryAreaTabletAndAbove,
  SummaryAreaMobile,
  ProgressMobile,
} from './styled';
import Check from '@material-ui/icons/Check';
import useWindowWidth from 'hooks/useWindowWidth';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Route, Switch, useLocation } from 'react-router-dom';
import Housing from './housing';
import Stepper from 'features/lead-capturing/stepper-custom';
import Showcase from './showcase';
import { useQuery } from '@apollo/client';
import { BUILDER_WIZARD_CONFIG_QUERY } from 'app/queries';
import { IStep } from 'features/lead-capturing';
import Transportation from './transportation';
import HousingIcon from 'components/shared/icons/housing';
import TransportationIcon from 'components/shared/icons/transportation';
import DaytimeActivitiesIcon from 'components/shared/icons/activities-daytime';
import NighttimeActivitiesIcon from 'components/shared/icons/activities-nighttime';
import PersonalServicesIcon from 'components/shared/icons/personal-services';
import DaytimeActivities from './daytime-activities';
import NighttimeActivities from './nighttime-activities';
import PersonalServices from './personal-services';
import SummaryTablet from './summary-tablet';
import SummaryMobile from './summary-mobile';
import { ResponsiveImageType } from 'react-datocms';
export interface IExperienceBuilder {}

const useIconsStyles = makeStyles((theme: any) =>
  createStyles({
    housing: {
      width: 16,
    },
    transportation: {
      width: 16,
    },
    daytime: {
      width: 16,
    },
    nighttime: {
      width: 16,
    },
    personal: {
      width: 16,
    },
    check: {
      width: 18,
    },
  })
);

const useIconsStylesMobile = makeStyles((theme: any) =>
  createStyles({
    housing: {
      width: 14,
    },
    transportation: {
      width: 14,
    },
    daytime: {
      width: 14,
    },
    nighttime: {
      width: 14,
    },
    personal: {
      width: 14,
    },
    check: {
      width: 14,
    },
  })
);

const ExperienceBuilder: FunctionComponent<IExperienceBuilder> = ({ ...props }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { windowWidth } = useWindowWidth();
  const isMobile = windowWidth < 769;
  const iconsClasses = useIconsStyles();
  const iconsClassesMobile = useIconsStylesMobile();
  const { data } = useQuery(BUILDER_WIZARD_CONFIG_QUERY);
  let location = useLocation();
  const [showcaseImages, setShowcaseImages] = React.useState<{ responsiveImage: ResponsiveImageType }[]>(
    data?.builderWizardConfig?.showcaseImagesHousing || []
  );
  useEffect(() => {
    switch (location.pathname) {
      case '/builder':
        setShowcaseImages(data?.builderWizardConfig?.showcaseImagesHousing);
        break;
      case '/builder/housing':
        setShowcaseImages(data?.builderWizardConfig?.showcaseImagesHousing);
        break;
      case '/builder/transportation':
        setShowcaseImages(data?.builderWizardConfig?.showcaseImagesTransportation);
        break;
      case '/builder/daytime-activities':
        setShowcaseImages(data?.builderWizardConfig?.showcaseImagesDaytimeActivity);
        break;
      case '/builder/nighttime-activities':
        setShowcaseImages(data?.builderWizardConfig?.showcaseImagesNighttimeActivity);
        break;
      case '/builder/personal-services':
        setShowcaseImages(data?.builderWizardConfig?.showcaseImagesPersonalService);
        break;
      case '/builder/summary':
        setShowcaseImages(data?.builderWizardConfig?.showcaseImagesHousing);
        break;
      default:
        setShowcaseImages(data?.builderWizardConfig?.showcaseImagesHousing);
        break;
    }
  }, [location.pathname, data, data?.builderWizardConfig]);

  const steps: IStep[] = [
    {
      link: '/builder',
      icon: <HousingIcon className={isMobile ? iconsClassesMobile.housing : iconsClasses.housing}></HousingIcon>,
      tooltip: 'Housing',
    },
    {
      link: '/builder/transportation',
      icon: (
        <TransportationIcon
          className={isMobile ? iconsClassesMobile.transportation : iconsClasses.transportation}
        ></TransportationIcon>
      ),
      tooltip: 'Transportation',
    },
    {
      link: '/builder/daytime-activities',
      icon: (
        <DaytimeActivitiesIcon
          className={isMobile ? iconsClassesMobile.daytime : iconsClasses.daytime}
        ></DaytimeActivitiesIcon>
      ),
      // tooltip: 'Day Activities',//temp-no-night
      tooltip: 'Activities',
    },
    // {//temp-no-night
    //   link: '/builder/nighttime-activities',
    //   icon: (
    //     <NighttimeActivitiesIcon
    //       className={isMobile ? iconsClassesMobile.nighttime : iconsClasses.nighttime}
    //     ></NighttimeActivitiesIcon>
    //   ),
    //   tooltip: 'Night Activities',
    // },
    {
      link: '/builder/personal-services',
      icon: (
        <PersonalServicesIcon
          className={isMobile ? iconsClassesMobile.personal : iconsClasses.personal}
        ></PersonalServicesIcon>
      ),
      tooltip: 'Personal Services',
    },
    {
      link: '/builder/summary',
      icon: <Check className={isMobile ? iconsClassesMobile.check : iconsClasses.check}></Check>,
      tooltip: 'Summary',
    },
  ];

  const [showSummary, setShowSummary] = useState(true);

  return (
    <>
      <ProgressMobile>
        <Logo src="/svg/bido-logo.svg" alt="BIDO logo" />
        <Stepper connectorWidthMobile={7} activeStep={activeStep} steps={steps} />
      </ProgressMobile>
      <SummaryAreaMobile>
        <SummaryMobile toggle={() => setShowSummary((prev) => !prev)} />
      </SummaryAreaMobile>
      <Container fixed>
        <Wrapper>
          <ProgressTabletAndAbove>
            <Logo src="/svg/bido-logo.svg" alt="BIDO logo" />
            <Stepper activeStep={activeStep} steps={steps} />
          </ProgressTabletAndAbove>
          <FormArea enlarge={!showSummary}>
            <Switch>
              <Route path="/builder/personal-services">
                <PersonalServices setActiveStep={setActiveStep} />
              </Route>
              <Route path="/builder/nighttime-activities">
                <NighttimeActivities setActiveStep={setActiveStep} />
              </Route>
              <Route path="/builder/daytime-activities">
                <DaytimeActivities setActiveStep={setActiveStep} />
              </Route>
              <Route path="/builder/transportation">
                <Transportation setActiveStep={setActiveStep} />
              </Route>
              <Route path="/">
                <Housing setActiveStep={setActiveStep} />
              </Route>
            </Switch>
          </FormArea>
          <SummaryAreaTabletAndAbove show={true}>
            <SummaryTablet toggle={() => setShowSummary((prev) => !prev)} show={showSummary} />
          </SummaryAreaTabletAndAbove>
          <ImageArea>
            <ImageWrapper>{data && <Showcase images={showcaseImages} />}</ImageWrapper>
          </ImageArea>
        </Wrapper>
      </Container>
    </>
  );
};

export default ExperienceBuilder;
