import React, { FunctionComponent, useState } from 'react';
import { Container, Progress, Wrapper, FormArea, ImageArea, ImageWrapper, Logo } from './styled';
import Stepper from './stepper-custom';
import Start from './start';
import Destination from './destination';
import GroupInfo from './group-info';
import Check from '@material-ui/icons/Check';
import Scheduled from './scheduled';
import Schedule from './schedule';
import Ready from './ready';
import useWindowWidth from 'hooks/useWindowWidth';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { makeStyles, createStyles } from '@material-ui/styles';
import { LEAD_CAPTURING_CONFIG_QUERY } from 'app/queries';
import Showcase from './showcase';
import { useEffect } from 'react';
import { ResponsiveImageType } from 'react-datocms';
export interface LeadCapturingProps {}

const useCheckStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: 20,
    },
  })
);

const useCheckStylesMobile = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: 16,
    },
  })
);

export interface IStep {
  icon: React.ReactElement;
  link: string;
  tooltip?: string;
}

const LeadCapturing: FunctionComponent<LeadCapturingProps> = () => {
  let location = useLocation();
  const [messageRestric, setMessageResstrict] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [scheduleStepper, setScheduleStepper] = React.useState(location.pathname.includes('schedule'));
  const { windowWidth } = useWindowWidth();
  const isMobile = windowWidth < 769;
  const checkClasses = useCheckStyles();
  const checkClassesMobile = useCheckStylesMobile();
  const { data } = useQuery(LEAD_CAPTURING_CONFIG_QUERY);

  useEffect(() => {
    setScheduleStepper(location.pathname.includes('schedule'));
  }, [location]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const steps: IStep[] = [
    { link: '/', icon: <span>1</span> },
    { link: '/destination', icon: <span>2</span> },
    { link: '/group-info', icon: <span>3</span> },
    { link: '/ready', icon: <Check className={isMobile ? checkClassesMobile.root : checkClasses.root}></Check> },
  ];

  const scheduleSteps: IStep[] = [
    { link: '/schedule', icon: <span>1</span> },
    { link: '/scheduled', icon: <Check className={isMobile ? checkClassesMobile.root : checkClasses.root}></Check> },
  ];

  const showcaseImages: { responsiveImage: ResponsiveImageType; messageRestric: boolean }[] =
    data?.leadCapturingConfig?.showcaseImages;

  return (
    <Container fixed>
      <Wrapper>
        <Progress>
          <Logo src="/svg/bido-logo.svg" alt="BIDO logo" />
          {scheduleStepper ? (
            <Stepper connectorWidthMobile={130} activeStep={activeStep} steps={scheduleSteps} />
          ) : (
            <Stepper connectorWidthMobile={15} activeStep={activeStep} steps={steps} />
          )}
        </Progress>
        <FormArea>
          <Switch>
            <Route path="/scheduled">
              <Scheduled
                heading={data?.leadCapturingConfig?.scheduledCallHeading}
                setActiveStep={setActiveStep}
                paragraph={data?.leadCapturingConfig?.scheduledParagraph}
              />
            </Route>
            <Route path="/schedule">
              <Schedule
                heading={data?.leadCapturingConfig.scheduleHeading}
                setActiveStep={setActiveStep}
                paragraph={data?.leadCapturingConfig.scheduleParagraph}
              />
            </Route>
            <Route path="/ready">
              <Ready
                heading={data?.leadCapturingConfig?.step4Heading}
                setActiveStep={setActiveStep}
                subHeading={data?.leadCapturingConfig.step4SubHeading}
                paragraph={data?.leadCapturingConfig.step4Paragraph}
                button1Text={data?.leadCapturingConfig.step4Button1Text}
                button2Text={data?.leadCapturingConfig.step4Button2Text}
              />
            </Route>
            <Route path="/group-info">
              <GroupInfo
                budgets={data?.leadCapturingConfig?.budgets}
                heading={data?.leadCapturingConfig?.step3Heading}
                handleNext={handleNext}
                setActiveStep={setActiveStep}
              />
            </Route>
            <Route path="/destination">
              <Destination
                heading={data?.leadCapturingConfig?.step2Heading}
                reasonsForTravel={data?.leadCapturingConfig?.reasonsForTravel}
                setActiveStep={setActiveStep}
                handleNext={handleNext}
              />
            </Route>
            <Route path="/">
              <Start setActiveStep={setActiveStep} heading={data?.leadCapturingConfig?.step1Heading} />
            </Route>
          </Switch>
        </FormArea>
        <ImageArea>
          <ImageWrapper>{data && <Showcase images={showcaseImages} />}</ImageWrapper>
        </ImageArea>
      </Wrapper>
    </Container>
  );
};

export default LeadCapturing;
