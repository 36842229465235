import CONFIG from 'app/config';
import { GenCardAuthorizationV1 } from './types';

const PayService = {
  genCardAuthorizationURLv1({
    name,
    email,
    phone,
    title,
    plan,
    bido_id,
    totalPerPayeeExact,
    _id,
    monthsTilTrip,
  }: GenCardAuthorizationV1) {
    const params = `?name=${name}&email=${email}&phone=${phone}&title=${title}&plan=${plan}&bido_id=${bido_id}&per_person_total=${String(
      totalPerPayeeExact
    )?.replace('$', '')}&_id=${_id}`;
    if (plan === 'lump-sum') {
      return CONFIG.LUMP_SUM_FORM_BASE_URL + params;
    } else if (plan === 'payment-plan') {
      const monthlyPayment =
        totalPerPayeeExact && (Number(`${totalPerPayeeExact}`?.replace(/,|\$/g, '')) / monthsTilTrip).toFixed(2);
      return CONFIG.SUBSCRIPTION_FORM_BASE_URL + params + `&monthly=${monthlyPayment}`;
    }
    return '';
  },
};

export default PayService;
