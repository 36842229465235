import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from 'styled-components';
import { materialTheme, styledTheme } from './styles/themes';
import { GlobalStyle } from './styles/global';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Toaster } from 'react-hot-toast';
/* from https://www.datocms.com/docs/content-delivery-api/your-first-request */
import { setContext } from '@apollo/client/link/context';
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  ServerError,
  ApolloLink,
  createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { graphqlUri, realmApp, getAccessToken } from 'context/realm';
import { RealmAppContext } from 'context/realm';

// Configure the ApolloClient to connect to your app's GraphQL endpoint
const httpLinkMongo = new HttpLink({
  uri: graphqlUri,
  // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
  // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
  // access token before sending the request.
  fetch: async (uri, options) => {
    const userdata = await getAccessToken();
    if (options && userdata?.accessToken) {
      const newOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${userdata?.accessToken}`,
        },
      };
      return fetch(uri, newOptions);
    } else {
      throw new Error('Must log in...');
    }
  },
});
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
  if (networkError) {
    if ((networkError as ServerError).statusCode === 401) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
});

let persistor = persistStore(store);

const token = process.env.REACT_APP_DATO_API_TOKEN;
const httpLink = createHttpLink({
  fetch,
  uri: process.env.REACT_APP_DATOCMS_DELIVERY_URL,
});
const authLinkDato = setContext((_, { headers }) => {
  return {
    headers: Object.assign(headers || {}, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  };
});

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'mongo',
    errorLink.concat(httpLinkMongo),
    from([authLinkDato, httpLink])
  ),
  cache: new InMemoryCache(),
});

// /***** end from ******/
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Toaster />
      <RealmAppContext.Provider value={realmApp}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ApolloProvider client={client}>
              <StyledEngineProvider injectFirst>
                <StylesProvider jss={jss}>
                  <MaterialThemeProvider theme={materialTheme}>
                    <ThemeProvider theme={styledTheme}>
                      <CssBaseline />
                      <GlobalStyle />
                      <App />
                    </ThemeProvider>
                  </MaterialThemeProvider>
                </StylesProvider>
              </StyledEngineProvider>
            </ApolloProvider>
          </LocalizationProvider>
        </PersistGate>
      </RealmAppContext.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
