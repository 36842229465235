import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { lg, md } from 'styles/themes';

export const ButtonsWrapper = styled.div`
  @media ${md} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  @media ${lg} {
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  text-transform: none;
  font-weight: bold;
  display: block;
  margin-bottom: 15px;
  @media ${md} {
    width: 225px;
    display: inline;
    margin-bottom: 0;
  }
  @media ${lg} {
    width: 250px;
  }
`;

export const PulsingButton = styled(StyledButton)`
  &:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:before {
    opacity: 1;
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;
