import { GroupsCollection } from 'schemas/db';

async function getBidoId(_id: string) {
  if (_id) {
    const res = await fetch('https://data.mongodb-api.com/app/wizard-qpxfp/endpoint/wizard/getBidoId', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ _id }),
    });
    if (res.ok) {
      const resJson: { bido_id: string } = await res.json();
      if (resJson.bido_id) {
        return resJson.bido_id;
      } else {
        return null;
      }
    }
  }
}

export interface GetPricesResponse {
  _id: string;
  totalPrice: number;
  totalPerPerson: number;
  totalPerPayee: number;
  totalPerPersonExact: number;
  totalPerPayeeExact: number;
  housingTotal: number;
  housingPricePerPerson: number;
  transportationTotal: number;
  transportationPerPerson: number;
  daytimeActivitiesTotal: number;
  daytimeActivitiesPerPerson: number;
  nighttimeActivitiesTotal: number;
  nighttimeActivitiesPerPerson: number;
  personalServicesTotal: number;
  personalServicesPerPerson: number;
  errors: any[];
}

export interface GetPrices extends GetPricesResponse {
  ok: boolean;
}

const getPrices = async (_id: string): Promise<GetPrices> => {
  const res = await fetch(`https://us-central1-bido-wizard.cloudfunctions.net/getPrices?_id=${_id}`, {
    method: 'get',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
  });
  const resJson: GetPricesResponse = await res.json();
  return { ...resJson, ok: res.ok };
};

interface GetExpGroupDataResponse {
  groupData: GroupsCollection;
  ok: boolean;
}

const getExpGroupData = async (_id: string): Promise<GetExpGroupDataResponse> => {
  const res = await fetch(`https://data.mongodb-api.com/app/wizard-qpxfp/endpoint/wizard/getGroup`, {
    method: 'post',
    body: JSON.stringify({ _id }),
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
  });
  const groupData: GroupsCollection = await res.json();
  return { groupData, ok: res.ok };
};

const api = { getBidoId, getPrices, getExpGroupData };

export default api;
