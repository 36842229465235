// eslint-disable-next-line import/no-anonymous-default-export
const Edit = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="8.015" height="8.018" viewBox="0 0 8.015 8.018">
      <path
        id="Path_1400"
        data-name="Path 1400"
        d="M4547.123-1108.772c-.22-.224-.447-.452-.695-.7a.906.906,0,0,0-1.427,0c-1.606,1.609-3.316,3.324-5.018,5.037a.785.785,0,0,0-.235.382c-.078.416-.145.829-.216,1.265-.024.149-.048.3-.074.452l0,.019a.467.467,0,0,0,.135.4.464.464,0,0,0,.324.114.825.825,0,0,0,.139-.012c.084-.014.168-.031.25-.047a4.056,4.056,0,0,1,.59-.085,2.361,2.361,0,0,0,1.667-.826c1.052-1.088,2.143-2.175,3.2-3.226.445-.443.9-.9,1.355-1.354A.9.9,0,0,0,4547.123-1108.772Zm-1.439-.089a2.371,2.371,0,0,1,.409.357l.089.088a2.315,2.315,0,0,1,.341.394,1.331,1.331,0,0,1-.259.229l-.821-.821A1.229,1.229,0,0,1,4545.683-1108.861Zm-.159,1.748-3.875,3.835-.731-.736,3.864-3.828Z"
        transform="translate(-4539.454 1109.817)"
        fill="#353743"
      />
    </svg>
  );
};

export default Edit;
