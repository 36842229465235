import React, { FunctionComponent } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

export interface SubmitButtonProps {
  loading?: boolean;
  style?: React.CSSProperties;
  id?: string;
  noArrow?: boolean;
  onClick?: () => any | Promise<any>;
  children?: React.ReactNode;
  className?: string;
  dataClipboardText?: string;
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  loading,
  style = {},
  id,
  onClick,
  noArrow,
  children,
  className,
  dataClipboardText,
  ...props
}) => {
  return (
    <Button
      data-clipboard-text={dataClipboardText}
      id={id}
      disabled={loading}
      type={onClick ? 'button' : 'submit'}
      color="primary"
      onClick={
        onClick
          ? onClick
          : () =>
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }, 700)
      }
      variant="contained"
      size="large"
      style={{ minWidth: '110px', ...style }}
      className={className || ''}
    >
      {loading ? (
        <CircularProgress color="inherit" size="25.5px" />
      ) : (
        <>
          {children || 'Next'}
          {!noArrow && <ArrowRightAltIcon style={{ marginLeft: '5px' }} />}
        </>
      )}
    </Button>
  );
};

export default SubmitButton;
