import React, { FunctionComponent, useEffect, useState } from 'react';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import BackButton from 'components/shared/back-button';
import { reset, selectGroup, setBidoID, submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
import { Skeleton } from '@material-ui/lab';
import api from 'api';
import styled from 'styled-components';
import { lg } from 'styles/themes';

declare global {
  interface Window {
    Calendly: any;
  }
}
window.Calendly = window.Calendly || {};

export interface ScheduleProps {
  heading?: string;
  paragraph?: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const Schedule: FunctionComponent<ScheduleProps> = ({ setActiveStep, heading, paragraph, ...props }) => {
  const dispatch = useAppDispatch();
  const [customLoading, setCustomLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const { group } = useAppSelector(selectGroup);
  const { _id, bido_id } = group;
  useEffect(() => {
    setActiveStep(0);
  }, [setActiveStep]);

  useEffect(() => {
    const calendlyScript = document.createElement('script');
    calendlyScript.type = 'text/javascript';
    calendlyScript.src = 'https://assets.calendly.com/assets/external/widget.js';
    calendlyScript.async = true;
    document.head.appendChild(calendlyScript);
  }, []);

  useEffect(() => {
    // we're insterested in knowing that the user got to ready because there's nothing to submit on this step
    (async () => {
      if (_id && !bido_id) {
        const bido_id = await api.getBidoId(`${_id}`);
        if (bido_id) {
          dispatch(setBidoID({ bido_id }));
          setLoading(false);
        } else {
          dispatch(reset(null));
        }
      } else {
        if (bido_id) {
          setLoading(false);
        }
      }
    })();
  }, [dispatch, _id, bido_id]);

  useEffect(() => {
    dispatch(submitLastSeenAtStep({ at: 'schedule' }));
  }, []);

  useEffect(() => {
    // we're insterested in knowing that the user got to schedule because there's nothing to submit on this step
    console.log(group?.groupLead?.phone, 'group?.groupLead?.phone', bido_id);
    function isCalendlyEvent(e: { data: { event: string } }) {
      return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    try {
      if (bido_id && !loading) {
        const calendlyInterval = setInterval(() => {
          if (window.Calendly && bido_id) {
            window.addEventListener('message', function (e) {
              if (isCalendlyEvent(e)) {
                console.log(e.data);
                if (e.data.event == 'calendly.event_type_viewed') {
                  setCustomLoading(false);
                }
              }
            });
            console.log('IRUN');
            window.clearInterval(calendlyInterval);
            window.Calendly.initInlineWidget({
              url: 'https://calendly.com/bido-greg/15-minute-meeting-bido-package-builder',
              parentElement: document.getElementById('calendly-root'),
              prefill: {
                name: `${group?.groupLead?.firstName} ${group?.groupLead?.lastName}`,
                email: group?.groupLead?.email,
                phone: '14075454545',
                customAnswers: {
                  a1: group?.groupLead?.phone,
                  a2: bido_id,
                },
              },
            });
          }
        }, 500);
      }
    } catch (error) {}
  }, [dispatch, loading, bido_id, group]);

  return (
    <>
      <Typography variant="h4" component="h4" style={{ marginBottom: '40px' }}>
        {heading || <Skeleton animation="wave" width={'70%'} />}
      </Typography>
      {paragraph ? (
        <Typography
          variant="h5"
          component="h5"
          style={{ marginBottom: '40px', fontWeight: 'normal' }}
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      ) : (
        <>
          <Skeleton height={'40px'} animation="wave" width={'100%'} />
          <Skeleton height={'40px'} animation="wave" width={'20%'} />
        </>
      )}
      {customLoading && (
        // eslint-disable-next-line react/jsx-no-undef
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      )}
      <div id="calendly-root" style={{ width: '100%' }} />

      <BackButton />
    </>
  );
};

export default Schedule;
