import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Label, LabelText } from 'components/shared/label';
import React from 'react';

const StyledInput = styled.input<{ [key: string]: unknown }>`
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.bgInput};
  border: 1px solid ${({ theme }) => theme.colors.bg};
  height: 40px;
  padding: 10px 20px;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
    font-size: 16px;
  }
`;

interface InputProps {
  label?: string;
  className?: string;
  [key: string]: any;
  error?: string;
}

export const Input: FunctionComponent<InputProps> = React.forwardRef(
  ({ label, className, error, fullWidth, ...props }, ref) => {
    return (
      <Label className={className || ''} fullWidth>
        {label && <LabelText>{label}</LabelText>}
        {error && <p className="error-message">{error}</p>}
        <StyledInput {...props} ref={ref} />
      </Label>
    );
  }
);
