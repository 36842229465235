import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Container as MaterialContainer } from '@material-ui/core';

export const Container = styled(MaterialContainer)``;

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 46px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${md} {
    padding-bottom: 76px;
    padding-top: 62px;
  }

  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    @media ${md} {
      font-size: 28px;
      margin-bottom: 28px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`;
