import React, { FunctionComponent } from 'react';

import { Wrapper, FlexText, IconWrapper, IconsWrapper, ImageIcon, FlexWrapper, ImageIconWrapper } from './styled';
import { Typography } from '@material-ui/core';
import { styledTheme } from 'styles/themes';

export interface CardSummaryProps {
  icons?: { icon: React.ReactNode; text1: string; text2: string }[];
  mb?: string;
}

const CardSummary: FunctionComponent<CardSummaryProps> = ({ icons, mb, ...props }) => {
  return (
    <Wrapper style={{ marginBottom: mb || '40px' }}>
      <FlexWrapper>
        <IconsWrapper>
          {icons?.map((e, i, arr) => {
            return (
              <IconWrapper style={{ borderRight: i == arr.length - 1 ? 'none' : '1px solid #eaeaea' }}>
                <ImageIconWrapper>{e.icon}</ImageIconWrapper>
                <FlexText>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    {e.text1}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      fontSize: '14px',
                      color: styledTheme.colors.primary,
                    }}
                  >
                    {e.text2}
                  </Typography>
                </FlexText>
              </IconWrapper>
            );
          })}
        </IconsWrapper>
      </FlexWrapper>
    </Wrapper>
  );
};

export default CardSummary;
