import { FunctionComponent, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Button } from '@material-ui/core';
import {
  Wrapper,
  ImageWrapper,
  IconWrapper,
  IconsWrapper,
  ButtonsCardWrapper,
  Image,
  TextWrapper,
  SelectWrapper,
  FeaturedDiv,
  Select,
} from './styled';
import { Typography } from '@material-ui/core';
import { styledTheme } from 'styles/themes';
import { ResponsiveImageType } from 'react-datocms';
import FeaturedIcon from 'icons/featured';
import TransportationPopup from 'components/shared/transportation-popup';
import ActivityPopup from 'components/shared/activities-popup';
import ServicesPopup from 'components/shared/services-popup';
import { TRANSPORTATION_DATA } from 'app/queries';
import { toggleFromObjArray } from 'utils';
import { TransportationTrip } from 'types';

export interface CardProps {
  image: ResponsiveImageType;
  title?: string;
  iconsNew?: { text1?: string | number; text2?: string }[];
  paragraph?: string;
  button1?: { text: string; onClick: () => void };
  button2: {
    text: string;
    onClick: (arg?: any, arg1?: any) => void;
    scrollOnClick?: boolean;
  };
  featured?: boolean;
  className?: string;
  selected?: boolean;
  price?: string | number | number;
  trip?: TransportationTrip;
  setTrip?: (arg: any) => void;
  withQty?: boolean;
  isThisAnAirportTransfer?: boolean;
  initialQty?: number | string;
  maxQty?: number;
  withTransportationPopup?: {
    showPopup?: boolean;
    transportationPopupID: string;
    transportationfetched?: TRANSPORTATION_DATA;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setTransportation: (
      value: React.SetStateAction<
        | {
            id?: string | undefined;
            qty?: number | string | undefined;
            trip?: TransportationTrip;
          }[]
        | null
      >
    ) => void;
    qty?: number | string;
    trip?: TransportationTrip;
  };
  withActivitiesPopup?: {
    showPopup?: boolean;
    daytimeActivitiesPopupID: string;
    daytimeActivitiesfetched?: any;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setDaytimeActivities: (arg: any) => void;
    qty?: number | string;
  };
  withServicesPopup?: {
    showPopup?: boolean;
    servicesPopupID: string;
    servicesfetched?: any;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setServices: (arg: any) => void;
  };
}

const Card: FunctionComponent<CardProps> = ({
  image,
  isThisAnAirportTransfer,
  title,
  iconsNew,
  paragraph,
  button1,
  button2,
  featured,
  price,
  className,
  selected,
  withQty,
  initialQty,
  maxQty,
  withTransportationPopup,
  withActivitiesPopup,
  withServicesPopup,
  trip,
  setTrip,
  ...props
}) => {
  const [qty, setQty] = useState<number | string | undefined>(initialQty);

  useEffect(() => {
    if (selected) {
      button2.onClick(qty, trip);
      setTimeout(() => {
        button2.onClick(qty, trip);
      }, 1);
    }
  }, [trip, qty]);

  return (
    <Wrapper className={className || ''} selected={selected}>
      {withTransportationPopup && ( //TODO: TECHNICAL DEBT
        <>
          {withTransportationPopup.showPopup &&
            withTransportationPopup.transportationPopupID == withTransportationPopup.transportationfetched?.id && (
              <TransportationPopup
                trip={trip}
                setTrip={setTrip}
                qty={qty}
                setQty={setQty}
                selected={selected}
                isThisAnAirportTransfer={isThisAnAirportTransfer}
                setShowPopup={withTransportationPopup.setShowPopup}
                setTransportation={(qty: number | string, trip?: TransportationTrip) => {
                  withTransportationPopup.setTransportation((prev) => {
                    setQty(qty);
                    if (setTrip) {
                      setTrip(trip);
                    }
                    return toggleFromObjArray(prev, {
                      ...withTransportationPopup.transportationfetched,
                      qty,
                      trip,
                    });
                  });
                }}
                id={withTransportationPopup.transportationPopupID}
                initialQty={withTransportationPopup.qty}
              />
            )}
        </>
      )}
      {withActivitiesPopup && ( //TODO: TECHNICAL DEBT
        <>
          {withActivitiesPopup.showPopup &&
            withActivitiesPopup.daytimeActivitiesPopupID == withActivitiesPopup.daytimeActivitiesfetched?.id && (
              <ActivityPopup
                setShowPopup={withActivitiesPopup.setShowPopup}
                setDaytimeActivities={(qty: number | string) => {
                  withActivitiesPopup.setDaytimeActivities((prev: any) => {
                    setQty(qty);
                    return toggleFromObjArray(prev, { ...withActivitiesPopup.daytimeActivitiesfetched, qty });
                  });
                }}
                id={withActivitiesPopup.daytimeActivitiesPopupID}
              />
            )}
        </>
      )}
      {withServicesPopup && ( //TODO: TECHNICAL DEBT
        <>
          {withServicesPopup.showPopup &&
            withServicesPopup.servicesPopupID == withServicesPopup.servicesfetched?.id && (
              <ServicesPopup
                setShowPopup={withServicesPopup.setShowPopup}
                setServices={(qty: number | string) => {
                  withServicesPopup.setServices((prev: any) => {
                    setQty(qty);
                    return toggleFromObjArray(prev, { ...withServicesPopup.servicesfetched, qty });
                  });
                }}
                id={withServicesPopup.servicesPopupID}
              />
            )}
        </>
      )}
      {featured && (
        <FeaturedDiv style={{ position: 'absolute', right: '7.75px', top: '6.54px', zIndex: 1 }}>
          <img
            alt="star"
            src="/svg/starFeatured.svg"
            style={{
              width: '12px',
              marginRight: '2px',
              marginBottom: '0.6px',
            }}
          />
          Featured
        </FeaturedDiv>
        // <FeaturedIcon style={{ position: 'absolute', right: '-7px', top: '-7px', zIndex: 1, width: '23px' }} />
      )}
      <ImageWrapper>{image && <Image className="card-image" data={image} />}</ImageWrapper>
      <Typography
        variant="h6"
        component="h6"
        style={{
          marginBottom: '6.5px',
          fontSize: '14px',
          textAlign: 'center',
          padding: '0 10px',
        }}
      >
        {title}
      </Typography>
      {iconsNew && (
        <IconsWrapper>
          {iconsNew?.map(({ text1, text2 }) => {
            if (!text1 || !text2) return null;
            return (
              <>
                <IconWrapper>
                  {/* <Icon /> */}
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      marginBottom: '0px',
                      fontSize: '14px',
                      marginTop: '0px',
                      marginLeft: '4px',
                    }}
                  >
                    {text1}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h6"
                    style={{
                      marginBottom: '0px',
                      fontSize: '10px',
                      marginTop: '0px',
                      marginLeft: '4px',
                    }}
                  >
                    {text2}
                  </Typography>
                </IconWrapper>
              </>
            );
          })}
        </IconsWrapper>
      )}
      {paragraph && (
        <Typography
          style={{
            marginBottom: '0px',
            fontSize: '12px',
            textAlign: 'center',
            color: styledTheme.colors.body,
            paddingLeft: '11px',
            paddingRight: '11px',
          }}
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      )}
      <TextWrapper>
        <ButtonsCardWrapper>
          {button1 && (
            <Button
              size="large"
              onClick={button1.onClick}
              style={{
                minWidth: '110px',
                fontSize: '11px',
                fontWeight: 'bold',
                textDecoration: 'underline',
                color: styledTheme.colors.bodyLight,
              }}
            >
              {button1.text}
            </Button>
          )}
          {price && (
            <Typography variant="h5" component="p" style={{ fontSize: '16px', marginBottom: '8px' }}>
              {price}
            </Typography>
          )}
          {withQty && (
            <SelectWrapper>
              <Typography component="p">Quantity</Typography>
              <Select
                value={Number(qty)}
                onChange={(e) => {
                  setQty(e.target.value);
                }}
              >
                {new Array(maxQty || 5)
                  .fill('beer')
                  .map((_, i) => ({ name: `${i}`, value: `${i}` }))
                  .map((option) => (
                    <option key={`${option.name}-${title}`} value={option.value}>
                      {option.name}
                    </option>
                  ))
                  .slice(1)}
              </Select>
            </SelectWrapper>
          )}
          {isThisAnAirportTransfer && setTrip && (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <Typography component="p">Trip</Typography>
              <SelectWrapper>
                <Select
                  value={trip}
                  onChange={(e) => {
                    setTrip(e.target.value);
                  }}
                  style={{ width: '100%' }}
                >
                  <option value="one-way">One way</option>
                  <option value="round-trip">Round trip</option>
                </Select>
              </SelectWrapper>
            </div>
          )}
          <Button
            size="small"
            variant="contained"
            color="secondary"
            style={{ minWidth: '100%', textTransform: 'capitalize' }}
            onClick={() => {
              button2.onClick(qty, trip);
              if (!selected) {
                setTimeout(() => {
                  if (button2.scrollOnClick)
                    document.getElementById('scrollHelper')?.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }, 800);
              }
            }}
          >
            {button2.text}
          </Button>
        </ButtonsCardWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

export default Card;
