// eslint-disable-next-line import/no-anonymous-default-export
const Housing = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18.91" height="18.91" viewBox="0 0 18.91 18.91">
      <g id="Group_1380" data-name="Group 1380" transform="translate(0 0)">
        <g id="Group_1369" data-name="Group 1369" transform="translate(0 7.055)">
          <path
            id="Path_1268"
            data-name="Path 1268"
            d="M2993.228-2577.635a.636.636,0,0,1-.636-.636v-5.615l-4.067-4.067-4.067,4.067v5.615a.637.637,0,0,1-.636.636.636.636,0,0,1-.636-.636v-5.879a.635.635,0,0,1,.186-.449l4.7-4.7a.637.637,0,0,1,.9,0l4.7,4.7a.636.636,0,0,1,.186.449v5.879A.637.637,0,0,1,2993.228-2577.635Z"
            transform="translate(-2983.185 2589.49)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1370" data-name="Group 1370" transform="translate(0 17.637)">
          <path
            id="Path_1269"
            data-name="Path 1269"
            d="M3001.459-2430.228h-17.637a.636.636,0,0,1-.636-.636.636.636,0,0,1,.636-.636h17.637a.636.636,0,0,1,.636.636A.636.636,0,0,1,3001.459-2430.228Z"
            transform="translate(-2983.185 2431.5)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1371" data-name="Group 1371" transform="translate(5.879)">
          <path
            id="Path_1270"
            data-name="Path 1270"
            d="M3071.594-2685.312a.636.636,0,0,1-.636-.636v-8.23a.636.636,0,0,1,.636-.636.636.636,0,0,1,.636.636v8.23A.636.636,0,0,1,3071.594-2685.312Z"
            transform="translate(-3070.958 2694.814)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1372" data-name="Group 1372" transform="translate(17.637)">
          <path
            id="Path_1271"
            data-name="Path 1271"
            d="M3247.136-2675.9a.636.636,0,0,1-.636-.636v-17.637a.636.636,0,0,1,.636-.636.636.636,0,0,1,.636.636v17.637A.636.636,0,0,1,3247.136-2675.9Z"
            transform="translate(-3246.5 2694.814)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1373" data-name="Group 1373" transform="translate(5.879)">
          <path
            id="Path_1272"
            data-name="Path 1272"
            d="M3083.352-2693.542h-11.758a.636.636,0,0,1-.636-.636.636.636,0,0,1,.636-.636h11.758a.636.636,0,0,1,.636.636A.636.636,0,0,1,3083.352-2693.542Z"
            transform="translate(-3070.958 2694.814)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1374" data-name="Group 1374" transform="translate(8.231 2.352)">
          <path
            id="Path_1273"
            data-name="Path 1273"
            d="M3109.053-2656.083H3106.7a.637.637,0,0,1-.636-.636v-2.352a.636.636,0,0,1,.636-.636h2.352a.636.636,0,0,1,.636.636v2.352A.637.637,0,0,1,3109.053-2656.083Zm-1.716-1.273h1.079v-1.079h-1.079Z"
            transform="translate(-3106.065 2659.707)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1375" data-name="Group 1375" transform="translate(12.934 2.352)">
          <path
            id="Path_1274"
            data-name="Path 1274"
            d="M3179.271-2656.083h-2.352a.636.636,0,0,1-.636-.636v-2.352a.636.636,0,0,1,.636-.636h2.352a.636.636,0,0,1,.636.636v2.352A.636.636,0,0,1,3179.271-2656.083Zm-1.716-1.273h1.079v-1.079h-1.079Z"
            transform="translate(-3176.283 2659.707)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1376" data-name="Group 1376" transform="translate(3.527 12.934)">
          <path
            id="Path_1275"
            data-name="Path 1275"
            d="M3038.836-2498.093h-2.352a.636.636,0,0,1-.636-.636v-2.351a.636.636,0,0,1,.636-.636h2.352a.635.635,0,0,1,.636.636v2.351A.636.636,0,0,1,3038.836-2498.093Zm-1.716-1.272h1.079v-1.079h-1.079Z"
            transform="translate(-3035.848 2501.717)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1377" data-name="Group 1377" transform="translate(8.231 7.055)">
          <path
            id="Path_1276"
            data-name="Path 1276"
            d="M3109.053-2585.865H3106.7a.636.636,0,0,1-.636-.636v-2.352a.637.637,0,0,1,.636-.636h2.352a.637.637,0,0,1,.636.636v2.352A.636.636,0,0,1,3109.053-2585.865Zm-1.716-1.273h1.079v-1.079h-1.079Z"
            transform="translate(-3106.065 2589.489)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1378" data-name="Group 1378" transform="translate(12.934 7.055)">
          <path
            id="Path_1277"
            data-name="Path 1277"
            d="M3179.271-2585.865h-2.352a.636.636,0,0,1-.636-.636v-2.352a.636.636,0,0,1,.636-.636h2.352a.636.636,0,0,1,.636.636v2.352A.636.636,0,0,1,3179.271-2585.865Zm-1.716-1.273h1.079v-1.079h-1.079Z"
            transform="translate(-3176.283 2589.489)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1379" data-name="Group 1379" transform="translate(12.934 11.758)">
          <path
            id="Path_1278"
            data-name="Path 1278"
            d="M3179.271-2515.648h-2.352a.636.636,0,0,1-.636-.636v-2.352a.636.636,0,0,1,.636-.636h2.352a.636.636,0,0,1,.636.636v2.352A.636.636,0,0,1,3179.271-2515.648Zm-1.716-1.273h1.079V-2518h-1.079Z"
            transform="translate(-3176.283 2519.272)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default Housing;
