import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container, Wrapper, TopbarFlex, Topbar, Logo } from './styled';
import { Route, Switch } from 'react-router-dom';
import SubmitButton from 'components/shared/submit-button';
import { reset, selectFunnel, submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Summary from './summary';
import GroupStatus from './group-status';
import { Typography } from '@material-ui/core';
import { getQueryParams } from 'utils';
import ClipboardJS from 'clipboard';
import toast from 'react-hot-toast';
import PayPrompt from './pay-prompt';
import { useQueryParams } from 'hooks/useQueryParams';
import Registered from './registered';
import { useExperienceGroupData } from 'hooks/useExperienceGroupData';
export interface IExperiencePage {
  match: {
    params?: {
      _id?: string;
    };
  };
}

const ExperiencePage: FunctionComponent<IExperiencePage> = ({ ...props }) => {
  const _id = props?.match?.params?._id;
  const funnel = useAppSelector(selectFunnel);
  const [showSignUp, setShowSignUp] = useState(false);
  const searchParams = useQueryParams();
  const { registered, pay } = searchParams;
  console.log(pay, 'pay');
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!pay || pay !== 'true') {
      dispatch(reset(null));
    }
    // we're insterested in knowing that the user got to schedule because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'experience' }));
  }, [dispatch, pay]);

  useEffect(() => {
    new ClipboardJS('.share');
  }, []);

  useEffect(() => {
    const params = getQueryParams(window.location.search);
    if (params.admin === 'true') {
    }
  }, []);

  const { data: group, refetch } = useExperienceGroupData(_id);

  return (
    <>
      <Topbar>
        <Container>
          <TopbarFlex>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Logo src="/svg/bido-logo.svg" alt="BIDO logo" />
              <Typography className="topbarTitle" variant="h4" component="h4">
                Package Summary
              </Typography>
            </div>
            <SubmitButton
              noArrow
              className="share"
              style={{ minWidth: '113px', height: 'auto', textTransform: 'none', marginLeft: 'auto' }}
              dataClipboardText={`https://builder.bidotravel.com/experience/${_id}`}
            >
              <span onClick={() => toast.success('Link copied!')}>Share Link</span>
            </SubmitButton>
          </TopbarFlex>
        </Container>
      </Topbar>
      <Wrapper>
        <Switch>
          <Route path="/">
            <>
              {group && (
                <>
                  {registered && <Registered />}
                  {group && (pay == 'true' || funnel) && <PayPrompt group={group} refetchGroup={refetch} />}
                  <GroupStatus group={group} />
                  <Summary
                    _id={_id}
                    group={group}
                    refetchGroup={refetch}
                    showSignUp={showSignUp}
                    setShowSignUp={setShowSignUp}
                  />
                </>
              )}
            </>
          </Route>
        </Switch>
      </Wrapper>
    </>
  );
};

export default ExperiencePage;
