import { FunctionComponent, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector, useCanvasConfetti, useMustHaveGroupId } from 'app/hooks';
import { ButtonsWrapper, StyledButton, PulsingButton } from './styled';
import { selectGroupLead, groupInfoSelector, destinationInfoSelector } from 'features/group-data-collection/groupSlice';
import { useHistory } from 'react-router';
import { Skeleton } from '@material-ui/lab';
import { submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
export interface ReadyProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  heading?: string;
  subHeading?: string;
  paragraph?: string;
  button1Text?: string;
  button2Text?: string;
}

const Ready: FunctionComponent<ReadyProps> = ({
  setActiveStep,
  heading,
  subHeading,
  paragraph,
  button1Text,
  button2Text,
  ...props
}) => {
  useMustHaveGroupId();
  useEffect(() => {
    setActiveStep(3);
  }, [setActiveStep]);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { firstName } = useAppSelector(selectGroupLead);
  const { groupSize } = useAppSelector(groupInfoSelector);
  const {
    destination: { appointmentsOnly },
  } = useAppSelector(destinationInfoSelector);
  const headingWithName = heading?.replace(/{username}/g, firstName);

  useCanvasConfetti({});

  useEffect(() => {
    // we're insterested in knowing that the user got to ready because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'ready' }));
  }, [dispatch]);

  const allowBuilder = groupSize && groupSize < 20 && !appointmentsOnly;

  return (
    <>
      <div id="confetti-container">
        <Typography variant="h2" component="h2" style={{ marginBottom: '15px' }}>
          🎉
        </Typography>
        <Typography variant="h4" component="h4" style={{ marginBottom: '5px' }}>
          {headingWithName || <Skeleton animation="wave" width={'30%'} />}
        </Typography>
        <Typography variant="h4" component="h4" style={{ marginBottom: '15px', fontWeight: 'normal' }}>
          {allowBuilder
            ? subHeading || <Skeleton animation="wave" width={'100%'} />
            : 'We have received your information'}
        </Typography>
        {paragraph ? (
          <Typography
            style={{ marginBottom: '51px' }}
            dangerouslySetInnerHTML={{
              __html: allowBuilder
                ? paragraph
                : 'Please click the button below to build your BIDO experience with a specialized agent.',
            }}
          />
        ) : (
          <>
            <Skeleton animation="wave" width={'100%'} />
            <Skeleton animation="wave" width={'60%'} style={{ marginBottom: '51px' }} />
          </>
        )}
      </div>
      <ButtonsWrapper>
        {allowBuilder && (
          <PulsingButton
            onClick={() => history.push('/builder/')}
            color="primary"
            variant="contained"
            size="large"
            style={{ minWidth: '212px !important' }}
          >
            {button1Text || '...'}
          </PulsingButton>
        )}
        <StyledButton
          onClick={() => history.push('/schedule')}
          color="primary"
          variant="outlined"
          size="large"
          style={{ minWidth: '212px !important' }}
        >
          {button2Text || '...'}
        </StyledButton>
      </ButtonsWrapper>
    </>
  );
};

export default Ready;
