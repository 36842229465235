import styled from 'styled-components';
import { md, lg } from 'styles/themes';

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const Row = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 21px;
  @media ${md} {
  }
  @media ${lg} {
  }
`;
