// eslint-disable-next-line import/no-anonymous-default-export
const Beds = (props: any) => {
  return (
    <svg
      id="Group_1345"
      {...props}
      data-name="Group 1345"
      xmlns="http://www.w3.org/2000/svg"
      width="13.698"
      height="13.503"
      viewBox="0 0 13.698 13.503"
    >
      <g id="Group_1313" data-name="Group 1313" transform="translate(0.849)">
        <path
          id="Path_1228"
          data-name="Path 1228"
          d="M1773.019-646.976a.5.5,0,0,1-.481-.51v-3.6a.38.38,0,0,0-.368-.39h-9.34a.38.38,0,0,0-.368.39v3.6a.5.5,0,0,1-.481.51.5.5,0,0,1-.481-.51v-3.6a1.374,1.374,0,0,1,1.33-1.411h9.34a1.374,1.374,0,0,1,1.33,1.411v3.6A.5.5,0,0,1,1773.019-646.976Z"
          transform="translate(-1761.5 652.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1314" data-name="Group 1314" transform="translate(0 8.641)">
        <path
          id="Path_1229"
          data-name="Path 1229"
          d="M1759.717-516.479h-12.736a.5.5,0,0,1-.481-.51.5.5,0,0,1,.481-.51h12.736a.5.5,0,0,1,.481.51A.5.5,0,0,1,1759.717-516.479Z"
          transform="translate(-1746.5 517.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1315" data-name="Group 1315" transform="translate(0 8.432)">
        <path
          id="Path_1230"
          data-name="Path 1230"
          d="M1746.981-513.33a.532.532,0,0,1-.481-.572v-3.027a.532.532,0,0,1,.481-.572.532.532,0,0,1,.481.572v3.027A.532.532,0,0,1,1746.981-513.33Z"
          transform="translate(-1746.5 517.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1316" data-name="Group 1316" transform="translate(12.736 8.432)">
        <path
          id="Path_1231"
          data-name="Path 1231"
          d="M1971.981-513.33a.533.533,0,0,1-.481-.572v-3.027a.532.532,0,0,1,.481-.572.532.532,0,0,1,.481.572v3.027A.533.533,0,0,1,1971.981-513.33Z"
          transform="translate(-1971.5 517.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1317" data-name="Group 1317" transform="translate(0 11.581)">
        <path
          id="Path_1232"
          data-name="Path 1232"
          d="M1759.717-471.479h-12.736a.5.5,0,0,1-.481-.51.5.5,0,0,1,.481-.51h12.736a.5.5,0,0,1,.481.51A.5.5,0,0,1,1759.717-471.479Z"
          transform="translate(-1746.5 472.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1318" data-name="Group 1318" transform="translate(0.849 11.581)">
        <path
          id="Path_1233"
          data-name="Path 1233"
          d="M1761.981-470.578a.5.5,0,0,1-.481-.51v-.9a.5.5,0,0,1,.481-.51.5.5,0,0,1,.481.51v.9A.5.5,0,0,1,1761.981-470.578Z"
          transform="translate(-1761.5 472.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1319" data-name="Group 1319" transform="translate(11.887 11.581)">
        <path
          id="Path_1234"
          data-name="Path 1234"
          d="M1956.981-470.578a.5.5,0,0,1-.481-.51v-.9a.5.5,0,0,1,.481-.51.5.5,0,0,1,.481.51v.9A.5.5,0,0,1,1956.981-470.578Z"
          transform="translate(-1956.5 472.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1320" data-name="Group 1320" transform="translate(2.335 1.801)">
        <path
          id="Path_1235"
          data-name="Path 1235"
          d="M1788.231-619.578a.446.446,0,0,1-.481-.4V-622.1a.447.447,0,0,1,.481-.4.447.447,0,0,1,.481.4v2.121A.447.447,0,0,1,1788.231-619.578Z"
          transform="translate(-1787.75 622.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1321" data-name="Group 1321" transform="translate(5.731 1.801)">
        <path
          id="Path_1236"
          data-name="Path 1236"
          d="M1848.231-619.578a.447.447,0,0,1-.481-.4V-622.1a.447.447,0,0,1,.481-.4.447.447,0,0,1,.481.4v2.121A.447.447,0,0,1,1848.231-619.578Z"
          transform="translate(-1847.75 622.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1322" data-name="Group 1322" transform="translate(2.335 1.801)">
        <path
          id="Path_1237"
          data-name="Path 1237"
          d="M1791.627-621.479h-3.4a.5.5,0,0,1-.481-.51.5.5,0,0,1,.481-.51h3.4a.5.5,0,0,1,.481.51A.5.5,0,0,1,1791.627-621.479Z"
          transform="translate(-1787.75 622.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1323" data-name="Group 1323" transform="translate(7.004 1.801)">
        <path
          id="Path_1238"
          data-name="Path 1238"
          d="M1870.731-619.578a.447.447,0,0,1-.481-.4V-622.1a.447.447,0,0,1,.481-.4.447.447,0,0,1,.481.4v2.121A.447.447,0,0,1,1870.731-619.578Z"
          transform="translate(-1870.25 622.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1324" data-name="Group 1324" transform="translate(10.401 1.801)">
        <path
          id="Path_1239"
          data-name="Path 1239"
          d="M1930.731-619.578a.446.446,0,0,1-.481-.4V-622.1a.446.446,0,0,1,.481-.4.446.446,0,0,1,.481.4v2.121A.446.446,0,0,1,1930.731-619.578Z"
          transform="translate(-1930.25 622.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1325" data-name="Group 1325" transform="translate(7.004 1.801)">
        <path
          id="Path_1240"
          data-name="Path 1240"
          d="M1874.127-621.479h-3.4a.5.5,0,0,1-.481-.51.5.5,0,0,1,.481-.51h3.4a.5.5,0,0,1,.481.51A.5.5,0,0,1,1874.127-621.479Z"
          transform="translate(-1870.25 622.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1326" data-name="Group 1326" transform="translate(0.849 3.853)">
        <path
          id="Path_1241"
          data-name="Path 1241"
          d="M1773.019-576.479h-11.038a.5.5,0,0,1-.481-.51.5.5,0,0,1,.481-.51h11.038a.5.5,0,0,1,.481.51A.5.5,0,0,1,1773.019-576.479Z"
          transform="translate(-1761.5 577.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1327" data-name="Group 1327" transform="translate(0.424 3.895)">
        <path
          id="Path_1242"
          data-name="Path 1242"
          d="M1754.48-571.8a.375.375,0,0,1-.06,0,.617.617,0,0,1-.418-.7l.425-4.442c.033-.345.273-.589.537-.547a.617.617,0,0,1,.418.7l-.424,4.442A.548.548,0,0,1,1754.48-571.8Z"
          transform="translate(-1753.999 577.501)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1328" data-name="Group 1328" transform="translate(11.887 3.895)">
        <path
          id="Path_1243"
          data-name="Path 1243"
          d="M1957.4-571.8a.548.548,0,0,1-.477-.551l-.425-4.442a.617.617,0,0,1,.418-.7c.264-.043.5.2.537.546l.425,4.442a.617.617,0,0,1-.418.7A.363.363,0,0,1,1957.4-571.8Z"
          transform="translate(-1956.498 577.501)"
          fill="#bac3c9"
        />
      </g>
    </svg>
  );
};

export default Beds;
