import { useAppSelector, usePrices } from 'app/hooks';
import { selectGroupLead } from 'features/group-data-collection/groupSlice';
import { FunctionComponent } from 'react';
import { Container, Wrapper, ButtonWrapper } from './styled';
import SubmitButton from 'components/shared/submit-button';
import { Box, Button, Card, Checkbox, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'components/shared/select';
import PhoneInput from 'components/shared/phone-input';
import { Input } from 'components/shared/input';
import CONFIG from 'app/config';
import { GROUP_DATA } from 'app/queries';
import { ApolloQueryResult } from '@apollo/client';
import PayService from 'services/pay';
import { computeMonthsTilTrip } from 'utils';
import { ExpGroupData } from '../expGroupSlice';

interface PayPromptProps {
  group: ExpGroupData;
  refetchGroup: any;
}
type SignUpFormValues = {
  name: string;
  email: string;
  phone: string;
  title: string;
  plan: string;
  payee: string;
};
const PayPrompt: FunctionComponent<PayPromptProps> = ({ group, refetchGroup }) => {
  const { totalPerPayeeExact } = usePrices();
  const { firstName, lastName, email, phone } = useAppSelector(selectGroupLead);
  const monthsTilTrip = computeMonthsTilTrip(`${group?.fromDate}`);
  const onSubmitSignUp = async (data: SignUpFormValues) => {
    if (totalPerPayeeExact && group) {
      const payUrl = PayService.genCardAuthorizationURLv1({
        ...data,
        totalPerPayeeExact: `${totalPerPayeeExact}`,
        monthsTilTrip,
        bido_id: `${group.bido_id}`,
        _id: `${group._id}`,
      });
      console.log(payUrl, 'payUrl');
      window.location.href = payUrl;
    } else {
      console.log(data, totalPerPayeeExact);
    }
  };
  const {
    register: registerSignUp,
    handleSubmit: handleSubmitSignUp,
    formState: { errors: errorsSignUp, isSubmitting: isSubmittingSignUp },
    control,
  } = useForm<SignUpFormValues>({
    defaultValues: {
      name: `${firstName} ${lastName}`,
      email,
      phone,
    },
  });

  const paymentInstallmentOptions = CONFIG.installmentOptions(monthsTilTrip);

  return (
    <Container>
      <Wrapper style={{ textAlign: 'center' }}>
        <Typography variant="h2" component="h2" style={{ marginBottom: '15px' }}>
          🎉
        </Typography>
        <Typography variant="h4" component="h4" style={{ marginBottom: '25px', textAlign: 'center' }}>
          Congratulations, {firstName}!
        </Typography>
        <Typography style={{ marginBottom: '21px' }}>
          Your experience has been generated!. Please verify it by completing our credit card authorization process.
        </Typography>
        <Typography style={{ marginBottom: '41px' }}>
          Fill out the fields below and click the button to proceed:
        </Typography>
        <form onSubmit={handleSubmitSignUp(onSubmitSignUp)} style={{ textAlign: 'start', maxWidth: '600px' }}>
          <Input
            style={{
              width: '100%',
              maxWidth: '100%',
            }}
            label="Name"
            {...registerSignUp('name', { required: { value: true, message: 'Please provide your name' } })}
            placeholder="Greg"
            error={errorsSignUp?.name?.message}
          />
          <Input
            style={{
              width: '100%',
              maxWidth: '100%',
            }}
            label="Email"
            {...registerSignUp('email', { required: { value: true, message: 'Please provide your email' } })}
            placeholder="email@email.com"
            error={errorsSignUp?.email?.message}
          />
          <Controller
            control={control}
            name="phone"
            rules={{
              required: {
                value: true,
                message: 'Phone number is required',
              },
            }}
            render={({ field }) => (
              <PhoneInput
                {...{
                  ...field,
                  error: errorsSignUp?.phone?.message,
                  style: {
                    width: '100%',
                    height: '100%',
                  },
                }}
              />
            )}
          />
          <Select
            style={{ maxWidth: '100%', width: '100%' }}
            label="TITLE"
            {...registerSignUp('title', {
              required: { value: true, message: 'Please choose a title' },
            })}
            options={[
              {
                name: 'Group Leader',
                value: 'Group Leader',
              },
            ]}
            error={errorsSignUp?.title?.message}
          />
          <Select
            style={{ maxWidth: '100%', width: '100%' }}
            label="INSTALLMENT OPTIONS"
            {...registerSignUp('plan', {
              required: { value: true, message: 'Please choose a plan' },
            })}
            options={paymentInstallmentOptions}
            error={errorsSignUp?.plan?.message}
          />
          {/* <Select
                style={{ maxWidth: '100%', width: '100%' }}
                label="ARE YOU A PAYEE?"
                {...registerSignUp('payee')}
                options={[
                  { name: 'Yes', value: 'yes' },
                  { name: 'No', value: 'no' },
                ]}
                error={errorsSignUp?.payee?.message}
              /> */}
          <Typography sx={{ mb: '20px' }}>
            After clicking the "START AUTHORIZATION PROCESS" button, you'll be redirected to a payment capture form.
            Please submit your card information to complete the process.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button size="large" variant="contained" color="primary" type="submit">
              Start Authorization Process
            </Button>
          </div>
        </form>
      </Wrapper>
    </Container>
  );
};

export default PayPrompt;
