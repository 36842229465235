// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <svg
      style={{ transform: 'rotate(180deg)' }}
      className="MuiSvgIcon-root jss79"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" fill="#EAEAEA"></path>
    </svg>
  );
};
