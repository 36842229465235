import { FunctionComponent, useState } from 'react';
import { Typography } from '@material-ui/core';
import {
  Wrapper,
  ActionIconsWrapper
} from "./styled";
import Edit from 'components/shared/icons/edit';
import Erase from 'components/shared/icons/erase';

interface DetailCardProps {
  name?: string;
  children?: React.ReactNode;
  handleEdit?: (args: any) => void;
  handleErease?: (args: any) => void | Promise<void>;
}

const DetailCard: FunctionComponent<DetailCardProps> = ({ name, children, handleEdit,
  handleErease }) => {
  const [showActions, setShowActions] = useState(false);
  return (
    <Wrapper onMouseEnter={() => setShowActions(true)} onMouseLeave={() => setShowActions(false)}>
      <ActionIconsWrapper style={{ opacity: showActions ? 1 : 0 }}>
        <Edit style={{ marginRight: '7px' }} onClick={handleEdit}></Edit>
        {handleErease && <Erase onClick={handleErease}></Erase>}
      </ActionIconsWrapper>
      <Typography variant="overline" component="h6" style={{ lineHeight: '15px', fontWeight: 'bold' }}>
        {name}
      </Typography>
      {children}
    </Wrapper>
  )
};

export default DetailCard;
