import styled from 'styled-components';
import { md, lg } from 'styles/themes';

export const Wrapper = styled.div`
  transition: 0.2s ease-out;
  padding: 5px 13px;
  position: relative;
  margin-bottom: 2px;
  &:hover {
    background: #e4e6ea 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }
  @media ${lg} {
  }
`;

export const ActionIconsWrapper = styled.div`
  transition: 0.2s ease-out;
  display: flex;
  position: absolute;
  cursor: pointer;
  top: 6px;
  right: 12px;
`;
