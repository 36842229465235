import { FunctionComponent, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useAppDispatch } from 'app/hooks';
import { FlexWrapper, Row } from './styled';
import { submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
import { Skeleton } from '@material-ui/lab';
import { FromIcon } from 'components/shared/date/FromIcon';
import { getQueryParams, parseDate } from 'utils';
import ScheduleIcon from '@material-ui/icons/Schedule';

export interface ScheduledProps {
  heading?: string;
  paragraph?: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

type CalendlyEvent = {
  answer_1: string;
  assigned_to: string;
  event_end_time: string;
  event_start_time: string;
  event_type_name: string;
  event_type_uuid: string;
  invitee_email: string;
  invitee_full_name: string;
  invitee_uuid: string;
  [key: string]: any;
};

const Scheduled: FunctionComponent<ScheduledProps> = ({ setActiveStep, heading, paragraph, ...props }) => {
  // const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);
  // const loading = dataCollectionStatus === 'loading';
  useEffect(() => {
    setActiveStep(1);
  }, [setActiveStep]);
  const dispatch = useAppDispatch();
  const [calendlyEvent, setCalendlyEvent] = useState<CalendlyEvent | null>(null);
  useEffect(() => {
    const event = getQueryParams(window.location.search) as CalendlyEvent;
    if (event) {
      setCalendlyEvent(event);
    } else {
      //TODO:trigger user alert
    }
    // we're insterested in knowing that the user got to scheduled because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'scheduled' }));
  }, [dispatch]);
  const date = calendlyEvent?.event_start_time && parseDate(new Date(calendlyEvent.event_start_time));
  return (
    <>
      <FlexWrapper>
        <div>
          <Typography variant="h4" component="h4" style={{ marginBottom: '22px' }}>
            {heading || <Skeleton animation="wave" width={'70%'} />}
          </Typography>
          {paragraph ? (
            <Typography style={{ marginBottom: '42px' }} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ) : (
            <>
              <Skeleton height={'40px'} animation="wave" width={'100%'} />
              <Skeleton height={'40px'} animation="wave" width={'20%'} />
            </>
          )}

          {typeof date == 'object' && (
            <>
              <Row>
                <FromIcon />
                <Typography variant="h4" component="h4" style={{ marginLeft: '12px' }}>
                  {date?.weekDay} | {date?.month} {date?.date}, {date?.year}
                </Typography>
              </Row>
              <Row>
                <ScheduleIcon />
                <Typography variant="h4" component="h4" style={{ marginLeft: '12px' }}>
                  {date?.hours}:{date?.minutes}
                </Typography>
              </Row>
            </>
          )}

          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/z3azLFwQ-SU"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ boxShadow: 'none', border: 'none' }}
          ></iframe>
        </div>
        {/* <ButtonsWrapper>
          <ContinueButton loading={loading} />
        </ButtonsWrapper> */}
      </FlexWrapper>
    </>
  );
};

export default Scheduled;
