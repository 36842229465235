import styled from 'styled-components';
import { md, lg } from 'styles/themes';

export const Form = styled.form`
  width: 100%;
  height: 100%;
  min-height: 72vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media ${lg} {
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const CardWrapper = styled.div`
  max-width: 48%;
  margin-bottom: 24px;
  @media ${md} {
    width: 164px;
  }
  @media ${lg} {
    width: 216px;
  }
`;
