//CONSIDER EDITING DASHBOARD DATA COLLECTION IF YOU HAVE TO TOUCH THIS FILE
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { GROUP_CREATE_URL, GROUP_UPDATE_URL } from './endpoints';
import { FormValues } from 'features/lead-capturing/start';
import { Destination } from 'features/lead-capturing/destination';
import moment, { Moment } from 'moment';
import { FormValuesGroupInfo } from 'features/lead-capturing/group-info';
import { AtStep } from 'app/types';
import { daysDiff } from 'utils';
import { TransportationTrip } from 'types';
import { MonitoringService } from 'services/monitoring';
interface GroupLead {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  contactMethod: string;
}
export interface groupState {
  status: 'idle' | 'loading' | 'failed';
  _id?: string; // MongoDB group id
  bido_id?: string;
  groupLead: GroupLead;
  error: string;
  destination: {
    __typename: 'DestinationRecord';
    place: string;
    id: string;
    country: string;
    appointmentsOnly: boolean;
  };
  travelReason: string;
  fromDate: string;
  untilDate: string;
  tripLength?: string;
  groupSize?: number;
  payees?: number | string;
  budget?: string;
  housing?: string;
  transportation?: { id?: string; qty?: number | string; name?: string; trip?: TransportationTrip }[];
  daytimeActivities?: any[]; //TODO properly type this
  nighttimeActivities?: any[]; //TODO properly type this
  personalServices?: any[]; //TODO properly type this
  summaryReached: boolean;
  gotoSummary: boolean;
  funnel: boolean;
}

export const initialState: groupState = {
  _id: undefined,
  bido_id: undefined,
  groupLead: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    contactMethod: '',
  },
  status: 'idle',
  error: '',
  destination: {
    __typename: 'DestinationRecord',
    place: '',
    id: '',
    country: '',
    appointmentsOnly: false,
  },
  travelReason: '',
  fromDate: '',
  untilDate: '',
  tripLength: undefined,
  groupSize: undefined,
  payees: undefined,
  budget: undefined,
  housing: undefined,
  transportation: undefined,
  daytimeActivities: undefined,
  nighttimeActivities: undefined,
  personalServices: undefined,
  summaryReached: false,
  gotoSummary: false,
  funnel: false,
};

const submitStart = createAsyncThunk('group/submitStart', async ({ data }: { data: FormValues }, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const _id = state.group._id; //TODO: double check this
  let submit = {
    ...data,
    _id,
    last_seen_at_step: 'start',
    changed_at: new Date().toISOString(),
    first_seen_at: new Date().toISOString(),
  };

  const res = await fetch(GROUP_CREATE_URL, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(submit),
  });
  console.log(res);
  if (res.ok) {
    //next
    const resJson = await res.json();
    console.log(resJson);
    MonitoringService.identify(resJson.$oid, {
      name: `${data.firstName} ${data.lastName}`,
      ...data,
    });
    return {
      groupLead: data,
      _id: resJson.$oid,
    };
  } else {
    //reject with value
    return thunkAPI.rejectWithValue("We couldn't submit your request.");
  }
});

interface MetaArgsSubmitDestination {
  destination: Destination;
  fromDate: Moment;
  untilDate: Moment;
  travelReason: string;
}

const submitDestination = createAsyncThunk(
  'group/submitDestination',
  async ({ data }: { data: MetaArgsSubmitDestination }, thunkAPI) => {
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }
    const fromDate = moment(data.fromDate).startOf('day').format('YYYY-MM-DD'); // guarantees type being a Moment before getting ISO string
    const untilDate = moment(data.untilDate).startOf('day').format('YYYY-MM-DD'); // guarantees type being a Moment before getting ISO string;
    const tripLength = `${daysDiff(new Date(`${fromDate}T00:00:00`), new Date(`${untilDate}T00:00:00`))}`;

    let submit = {
      ...data,
      fromDate,
      untilDate,
      tripLength,
      destination: {
        ...data.destination,
        name: `${data.destination.place} - ${data.destination.country}`,
      },
      _id,
      last_seen_at_step: 'destination' as const,
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      console.log(resJson);
      return {
        ...data,
        fromDate: moment(data.fromDate).startOf('day').format('YYYY-MM-DD'), // guarantees type being a Moment before getting ISO string
        untilDate: moment(data.untilDate).startOf('day').format('YYYY-MM-DD'), // guarantees type being a Moment before getting ISO string
        tripLength,
      };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

const submitGroupInfo = createAsyncThunk(
  'group/submitGroupInfo',
  async ({ data }: { data: FormValuesGroupInfo }, thunkAPI) => {
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }

    let submit = {
      ...data,
      payees: `${data.groupSize}`,
      _id,
      last_seen_at_step: 'group-info' as const,
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      console.log(resJson);
      return { ...data, payees: `${data.groupSize}` };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

const submitLastSeenAtStep = createAsyncThunk(
  'group/submitLastSeenAtStep',
  async ({ at }: { at: AtStep }, thunkAPI) => {
    //TODO: implement proper at enum
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }

    let submit = {
      _id,
      last_seen_at_step: at,
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      console.log(resJson);
      return { at };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

type SubmitHousingdata =
  | {
      id: string;
      name: string;
    }
  | undefined;

const submitHousing = createAsyncThunk(
  'group/submitHousing',
  async ({ data }: { data: SubmitHousingdata }, thunkAPI) => {
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }

    let submit = {
      housing: data,
      _id,
      last_seen_at_step: 'housing',
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      console.log(resJson);
      return {
        housing: data,
        _id: resJson.$oid,
      };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

type SubmitTransportationData =
  | (
      | {
          id: string;
          name: string;
          qty?: number | string;
          trip?: TransportationTrip;
        }
      | undefined
    )[]
  | undefined
  | null;

const submitTransportation = createAsyncThunk(
  'group/submitTransportation',
  async ({ data }: { data: SubmitTransportationData }, thunkAPI) => {
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }

    let submit = {
      transportation: data,
      _id,
      last_seen_at_step: 'transportation',
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      return {
        transportation: data,
        _id: resJson.$oid,
      };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

type SubmitDaytimeActivitiesData =
  | Array<
      | {
          id: string;
          name: string;
          qty?: number | string;
        }
      | undefined
    >
  | undefined;

const submitDaytimeActivities = createAsyncThunk(
  'group/submitDaytimeActivities',
  async ({ data }: { data: SubmitDaytimeActivitiesData }, thunkAPI) => {
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }

    let submit = {
      daytimeActivities: data,
      _id,
      last_seen_at_step: 'daytimeActivities',
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      console.log(resJson);
      return {
        daytimeActivities: data,
        _id: resJson.$oid,
      };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

type SubmitNighttimeActivitiesData =
  | Array<
      | {
          id?: string;
          name?: string;
          qty?: string;
        }
      | undefined
    >
  | undefined;

const submitNighttimeActivities = createAsyncThunk(
  'group/submitNighttimeActivities',
  async ({ data }: { data: SubmitNighttimeActivitiesData }, thunkAPI) => {
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }

    let submit = {
      nighttimeActivities: data,
      _id,
      last_seen_at_step: 'nighttimeActivities',
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      console.log(resJson);
      return {
        nighttimeActivities: data,
        _id: resJson.$oid,
      };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

const submitPersonalServices = createAsyncThunk(
  'group/submitPersonalServices',
  async ({ data }: { data: SubmitDaytimeActivitiesData }, thunkAPI) => {
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }

    let submit = {
      personalServices: data,
      _id,
      last_seen_at_step: 'personalServices',
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      return {
        personalServices: data,
        _id: resJson.$oid,
      };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

const updatePayees = createAsyncThunk(
  'group/updatePayees',
  async ({ payees }: { payees: number | string }, thunkAPI) => {
    //TODO: implement proper at enum
    const {
      group: { _id },
    } = thunkAPI.getState() as RootState;

    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return thunkAPI.rejectWithValue("Yikes!, something went wrong, let's go from the top...");
    }

    let submit = {
      _id,
      payees: `${payees}`,
      changed_at: new Date().toISOString(),
    };

    const res = await fetch(GROUP_UPDATE_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(submit),
    });
    console.log(res);
    if (res.ok) {
      //next
      const resJson = await res.json();
      console.log(resJson);
      return { payees: `${payees}` };
    } else {
      //reject with value
      return thunkAPI.rejectWithValue("We couldn't submit your request.");
    }
  }
);

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    summaryReached: (state, action) => {
      state.summaryReached = true;
      state.gotoSummary = false;
    },
    summaryAfterSubmit: (state, action) => {
      state.gotoSummary = true;
    },
    setBidoID: (state, action) => {
      state.bido_id = action.payload.bido_id;
    },
    toExperience: (state, action) => {
      state.funnel = true;
    },
    reset: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitStart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitStart.fulfilled, (state, action) => {
        state.status = 'idle';
        state._id = action.payload._id; // sets group _id to match mongoDB
        state.groupLead = action.payload.groupLead; //sets group lead info
      })
      .addCase(submitStart.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder
      .addCase(updatePayees.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePayees.fulfilled, (state, action) => {
        state.status = 'idle';
        state.payees = `${action.payload.payees}`;
      })
      .addCase(updatePayees.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder
      .addCase(submitDestination.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitDestination.fulfilled, (state, action) => {
        state.status = 'idle';
        state.travelReason = action.payload.travelReason;
        state.destination = action.payload.destination;
        state.fromDate = action.payload.fromDate;
        state.untilDate = action.payload.untilDate;
        state.tripLength = action.payload.tripLength;
      })
      .addCase(submitDestination.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder
      .addCase(submitGroupInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitGroupInfo.fulfilled, (state, action) => {
        state.status = 'idle';
        state.groupSize = action.payload.groupSize;
        state.payees = `${action.payload.payees}`;
        state.budget = action.payload.budget;
      })
      .addCase(submitGroupInfo.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder
      .addCase(submitHousing.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitHousing.fulfilled, (state, action) => {
        state.status = 'idle';
        state.housing = action.payload.housing?.id;
      })
      .addCase(submitHousing.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder
      .addCase(submitTransportation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitTransportation.fulfilled, (state, action) => {
        state.status = 'idle';
        state.transportation = action.payload.transportation
          ?.map((transp) => {
            return { id: transp?.id, qty: transp?.qty, trip: transp?.trip } || undefined;
          })
          .filter((x) => x);
      })
      .addCase(submitTransportation.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder
      .addCase(submitDaytimeActivities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitDaytimeActivities.fulfilled, (state, action) => {
        state.status = 'idle';
        state.daytimeActivities = action.payload.daytimeActivities
          ?.map((activity) => {
            return activity?.id;
          })
          .filter((x) => x);
      })
      .addCase(submitDaytimeActivities.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder
      .addCase(submitNighttimeActivities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitNighttimeActivities.fulfilled, (state, action) => {
        state.status = 'idle';
        state.nighttimeActivities = action.payload.nighttimeActivities
          ?.map((activity) => {
            return activity?.id || undefined;
          })
          .filter((x) => x);
      })
      .addCase(submitNighttimeActivities.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder
      .addCase(submitPersonalServices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitPersonalServices.fulfilled, (state, action) => {
        state.status = 'idle';
        state.personalServices = action.payload.personalServices
          ?.map((service) => {
            return { id: service?.id, qty: service?.qty } || undefined;
          })
          .filter((x) => x);
      })
      .addCase(submitPersonalServices.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    builder.addCase(submitLastSeenAtStep.rejected, (state, action) => {
      //TODO: do not alert the user about this.
      state.status = 'failed';
      if (action.error.message) {
        state.error = action.error.message;
      }
    });
  },
});

export const selectGroup = (state: RootState) => state;
export const selectFunnel = (state: RootState) => state.group.funnel;
export const selectPayees = (state: RootState) => state.group.payees;
export const gotoSummarySelector = (state: RootState) => state.group.gotoSummary;
export const summaryReachedSelector = (state: RootState) => state.group.summaryReached;
export const selectHousing = (state: RootState) => state.group.housing;
export const selectTransportation = (state: RootState) => state.group.transportation;
export const selectDaytimeActivities = (state: RootState) => state.group.daytimeActivities;
export const selectNighttimeActivities = (state: RootState) => state.group.nighttimeActivities;
export const selectPersonalServices = (state: RootState) => state.group.personalServices;
export const selectGroupId = (state: RootState) => state.group._id;
export const selectGroupLead = (state: RootState) => state.group.groupLead;
export const destinationInfoSelector = (state: RootState) => ({
  travelReason: state.group.travelReason,
  destination: state.group.destination,
  fromDate: state.group.fromDate,
  untilDate: state.group.untilDate,
  tripLength: state.group.tripLength,
});
export const groupInfoSelector = (state: RootState) => ({
  groupSize: state.group.groupSize,
  budget: state.group.budget,
  fromDate: state.group.fromDate,
  untilDate: state.group.untilDate,
  tripLength: state.group.tripLength,
});
export const selectGroupDataCollectionStatus = (state: RootState) => state.group.status;
export const { summaryReached, summaryAfterSubmit, reset, setBidoID, toExperience } = groupSlice.actions;
export {
  submitStart,
  submitDestination,
  submitGroupInfo,
  submitLastSeenAtStep,
  submitHousing,
  submitTransportation,
  submitDaytimeActivities,
  submitNighttimeActivities,
  submitPersonalServices,
  updatePayees,
};

export default groupSlice.reducer;
