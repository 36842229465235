import React, { FunctionComponent } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useAppDispatch, useAppSelector, usePrices, useSummary } from 'app/hooks';
import {
  selectGroup,
  submitHousing,
  submitTransportation,
  summaryAfterSubmit,
  summaryReachedSelector,
} from 'features/group-data-collection/groupSlice';
import CaretRight from './CaretRight';
import { Estimate, Collapse, Wrapper } from './styles';
import DetailCard from './detail-card';
import { useHistory } from 'react-router';
import QtyBubble from 'components/shared/qty-bubble';
import { toUSDNoDecimal } from 'utils';

export interface SummaryTabletProps {
  show?: boolean;
  toggle: (args: any) => any;
}

const SummaryTablet: FunctionComponent<SummaryTabletProps> = ({ show, toggle, ...props }) => {
  const {
    housingData,
    transportationData,
    daytimeActivitiesData,
    nighttimeActivitiesData,
    personalServicesData,
    group,
  } = useSummary();

  const { totalPerPerson, renderPrice } = usePrices();
  const destination = `${group.destination.place}, ${group.destination.country}`;
  const date = `${new Date(`${group.fromDate}T00:00:00`).toDateString()} - ${new Date(
    `${group.untilDate}T00:00:00`
  ).toDateString()}`;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const Detail = withStyles({
    root: {
      lineHeight: '15px',
      fontSize: '12px',
      fontWeight: 'bold',
      position: 'relative',
      marginBottom: '5px',
    },
  })(Typography);

  const removeHousing = async () => {
    dispatch(submitHousing({ data: undefined }));
  };

  const removeTransportation = async () => {
    dispatch(submitTransportation({ data: undefined }));
  };

  const summaryReached = useAppSelector(summaryReachedSelector);
  const {
    group: { bido_id },
  } = useAppSelector(selectGroup);
  const housing = housingData?.allHousings[0].name;
  const transportation = transportationData;
  const daytimeActivities = daytimeActivitiesData?.allDaytimeActivities;
  const nighttimeActivities = nighttimeActivitiesData?.allNighttimeActivities;
  const personalServices = personalServicesData;
  return (
    <Wrapper>
      {/* <Collapse onClick={toggle} rotate={show}>
        <CaretRight />
      </Collapse> */}
      <Typography variant="h6" component="h6" style={{ marginBottom: '20px' }}>
        Summary
      </Typography>
      <DetailCard name="Group ID">
        <Detail color="primary">{bido_id}</Detail>
      </DetailCard>
      <DetailCard name="Destination" handleEdit={() => history.push('/destination')}>
        <Detail color="primary">{destination}</Detail>
      </DetailCard>
      <DetailCard name="Date">
        <Detail color="primary">{date}</Detail>
      </DetailCard>
      <DetailCard name="Group Size" handleEdit={() => history.push('/group-info')}>
        <Detail color="primary">{group.groupSize} Adults</Detail>
      </DetailCard>
      <DetailCard name="Payees">
        <Detail color="primary">{group.payees} Adults</Detail>
      </DetailCard>
      {housing && (
        <DetailCard name="Housing" handleEdit={() => history.push('/builder/housing')} handleErease={removeHousing}>
          <Detail color="primary">{housing}</Detail>
        </DetailCard>
      )}
      {transportation && (
        <DetailCard
          name="Transportation"
          handleEdit={() => history.push('/builder/transportation')}
          handleErease={removeTransportation}
        >
          {transportation?.map((transp) => (
            <Detail color="primary" key={transp.id}>
              {transp.name}{' '}
              <Box
                sx={{
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  margin: 'auto',
                  height: '20px',
                  right: '-20px',
                }}
              >
                <QtyBubble qty={transp.qty} />
              </Box>
            </Detail>
          ))}
        </DetailCard>
      )}
      {daytimeActivities && daytimeActivities.length > 0 && (
        <DetailCard name="ACTIVITIES" handleEdit={() => history.push('/builder/daytime-activities')}>
          {daytimeActivities?.map(({ id, name }) => (
            <Detail color="primary" key={id}>
              {name}
            </Detail>
          ))}
        </DetailCard>
      )}
      {nighttimeActivities && nighttimeActivities.length > 0 && (
        <DetailCard name="Nighttime ACTIVITies" handleEdit={() => history.push('/builder/nighttime-activities')}>
          {nighttimeActivities?.map(({ id, name }) => (
            <Detail color="primary" key={id}>
              {name}
            </Detail>
          ))}
        </DetailCard>
      )}
      {personalServices && personalServices.length > 0 && (
        <DetailCard name="Personal Services" handleEdit={() => history.push('/builder/personal-services')}>
          {personalServices?.map(({ id, name, qty }) => (
            <Detail color="primary" key={id}>
              {name}
              <Box
                sx={{
                  position: 'absolute',
                  top: '-3px',
                  bottom: '0',
                  margin: 'auto',
                  right: '-20px',
                }}
              >
                <QtyBubble qty={qty} />
              </Box>
            </Detail>
          ))}
        </DetailCard>
      )}
      <Estimate>
        <Typography
          variant="h6"
          component="h6"
          style={{ marginBottom: '6px', color: 'white', borderBottom: '1px solid #fafafa11' }}
        >
          Estimate
        </Typography>
        {/* <Detail
          style={{
            color: 'white',
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '0',
          }}
        >
          GROUP TOTAL
          <Detail color="primary" style={{ display: 'inline-box' }}>
            {totalPrice}
          </Detail>
        </Detail> */}
        <Detail
          style={{
            color: 'white',
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '0',
          }}
        >
          PER PERSON
          <Detail color="primary" style={{ display: 'inline-box' }}>
            {renderPrice(totalPerPerson)}
          </Detail>
        </Detail>
        {summaryReached && (
          <Button
            variant="contained"
            color="primary"
            style={{ width: '100%', marginTop: '10px', marginBottom: '5px' }}
            onClick={() => {
              dispatch(summaryAfterSubmit({}));
              setTimeout(() => {
                (document.querySelector('#submitStep') as HTMLButtonElement).click();
              }, 10);
            }}
          >
            Checkout
          </Button>
        )}
      </Estimate>
    </Wrapper>
  );
};

export default SummaryTablet;
