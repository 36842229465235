export const SumCalendar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51.428" height="51.429" viewBox="0 0 51.428 51.429">
      <g id="Group_1872" data-name="Group 1872" transform="translate(0 0)">
        <g id="Group_1692" data-name="Group 1692" transform="translate(12.015 11.881)">
          <g id="Group_1661" data-name="Group 1661" transform="translate(0 8.677)">
            <path
              id="Path_1351"
              data-name="Path 1351"
              d="M7617.157-807.123h-26.031a.75.75,0,0,1-.751-.751.751.751,0,0,1,.751-.751h26.031a.751.751,0,0,1,.75.751A.751.751,0,0,1,7617.157-807.123Z"
              transform="translate(-7590.375 808.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1662" data-name="Group 1662" transform="translate(13.016 0)">
            <path
              id="Path_1352"
              data-name="Path 1352"
              d="M7688.626-866.917a.751.751,0,0,1-.751-.751v-5.206a.751.751,0,0,1,.751-.751.751.751,0,0,1,.751.751v5.206A.751.751,0,0,1,7688.626-866.917Z"
              transform="translate(-7687.875 873.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1663" data-name="Group 1663" transform="translate(8.677 0)">
            <path
              id="Path_1353"
              data-name="Path 1353"
              d="M7656.125-866.917a.751.751,0,0,1-.751-.751v-5.206a.751.751,0,0,1,.751-.751.751.751,0,0,1,.751.751v5.206A.751.751,0,0,1,7656.125-866.917Z"
              transform="translate(-7655.374 873.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1664" data-name="Group 1664" transform="translate(17.354 0)">
            <path
              id="Path_1354"
              data-name="Path 1354"
              d="M7721.126-866.917a.751.751,0,0,1-.751-.751v-5.206a.751.751,0,0,1,.751-.751.751.751,0,0,1,.75.751v5.206A.751.751,0,0,1,7721.126-866.917Z"
              transform="translate(-7720.375 873.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1665" data-name="Group 1665" transform="translate(4.339 0)">
            <path
              id="Path_1355"
              data-name="Path 1355"
              d="M7623.626-866.917a.751.751,0,0,1-.751-.751v-5.206a.751.751,0,0,1,.751-.751.751.751,0,0,1,.75.751v5.206A.751.751,0,0,1,7623.626-866.917Z"
              transform="translate(-7622.875 873.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1666" data-name="Group 1666" transform="translate(21.693 0)">
            <path
              id="Path_1356"
              data-name="Path 1356"
              d="M7753.626-866.917a.752.752,0,0,1-.752-.751v-5.206a.752.752,0,0,1,.752-.751.751.751,0,0,1,.75.751v5.206A.751.751,0,0,1,7753.626-866.917Z"
              transform="translate(-7752.874 873.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1667" data-name="Group 1667" transform="translate(0 2.603)">
            <path
              id="Path_1357"
              data-name="Path 1357"
              d="M7615.422-829.195h-22.561a2.489,2.489,0,0,1-2.486-2.486v-21.693a.75.75,0,0,1,.751-.751h26.031a.751.751,0,0,1,.75.751v21.693A2.489,2.489,0,0,1,7615.422-829.195Zm-23.546-23.428v20.942a.986.986,0,0,0,.985.984h22.561a.985.985,0,0,0,.983-.984v-20.942Z"
              transform="translate(-7590.375 854.125)"
              fill="#353743"
            />
          </g>
          <g id="Group_1668" data-name="Group 1668" transform="translate(4.339 12.148)">
            <path
              id="Path_1358"
              data-name="Path 1358"
              d="M7623.626-777.652a.751.751,0,0,1-.751-.751v-3.471a.751.751,0,0,1,.751-.751.751.751,0,0,1,.75.751v3.471A.751.751,0,0,1,7623.626-777.652Z"
              transform="translate(-7622.875 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1669" data-name="Group 1669" transform="translate(7.809 12.148)">
            <path
              id="Path_1359"
              data-name="Path 1359"
              d="M7649.625-777.652a.751.751,0,0,1-.75-.751v-3.471a.751.751,0,0,1,.75-.751.751.751,0,0,1,.751.751v3.471A.751.751,0,0,1,7649.625-777.652Z"
              transform="translate(-7648.875 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1670" data-name="Group 1670" transform="translate(4.339 12.148)">
            <path
              id="Path_1360"
              data-name="Path 1360"
              d="M7627.1-781.123h-3.47a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h3.47a.751.751,0,0,1,.751.751A.751.751,0,0,1,7627.1-781.123Z"
              transform="translate(-7622.875 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1671" data-name="Group 1671" transform="translate(4.339 15.619)">
            <path
              id="Path_1361"
              data-name="Path 1361"
              d="M7627.1-755.123h-3.47a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h3.47a.751.751,0,0,1,.751.751A.751.751,0,0,1,7627.1-755.123Z"
              transform="translate(-7622.875 756.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1672" data-name="Group 1672" transform="translate(4.339 19.09)">
            <path
              id="Path_1362"
              data-name="Path 1362"
              d="M7623.626-725.652a.751.751,0,0,1-.751-.751v-3.471a.751.751,0,0,1,.751-.751.751.751,0,0,1,.75.751v3.471A.751.751,0,0,1,7623.626-725.652Z"
              transform="translate(-7622.875 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1673" data-name="Group 1673" transform="translate(7.809 19.09)">
            <path
              id="Path_1363"
              data-name="Path 1363"
              d="M7649.625-725.652a.751.751,0,0,1-.75-.751v-3.471a.751.751,0,0,1,.75-.751.751.751,0,0,1,.751.751v3.471A.751.751,0,0,1,7649.625-725.652Z"
              transform="translate(-7648.875 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1674" data-name="Group 1674" transform="translate(4.339 19.09)">
            <path
              id="Path_1364"
              data-name="Path 1364"
              d="M7627.1-729.123h-3.47a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h3.47a.751.751,0,0,1,.751.751A.751.751,0,0,1,7627.1-729.123Z"
              transform="translate(-7622.875 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1675" data-name="Group 1675" transform="translate(4.339 22.561)">
            <path
              id="Path_1365"
              data-name="Path 1365"
              d="M7627.1-703.123h-3.47a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h3.47a.751.751,0,0,1,.751.751A.751.751,0,0,1,7627.1-703.123Z"
              transform="translate(-7622.875 704.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1676" data-name="Group 1676" transform="translate(11.28 12.148)">
            <path
              id="Path_1366"
              data-name="Path 1366"
              d="M7675.625-777.652a.751.751,0,0,1-.751-.751v-3.471a.751.751,0,0,1,.751-.751.751.751,0,0,1,.751.751v3.471A.751.751,0,0,1,7675.625-777.652Z"
              transform="translate(-7674.874 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1677" data-name="Group 1677" transform="translate(14.751 12.148)">
            <path
              id="Path_1367"
              data-name="Path 1367"
              d="M7701.625-777.652a.751.751,0,0,1-.751-.751v-3.471a.751.751,0,0,1,.751-.751.752.752,0,0,1,.752.751v3.471A.752.752,0,0,1,7701.625-777.652Z"
              transform="translate(-7700.874 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1678" data-name="Group 1678" transform="translate(11.28 12.148)">
            <path
              id="Path_1368"
              data-name="Path 1368"
              d="M7679.1-781.123h-3.471a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h3.471a.751.751,0,0,1,.751.751A.751.751,0,0,1,7679.1-781.123Z"
              transform="translate(-7674.874 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1679" data-name="Group 1679" transform="translate(11.28 15.619)">
            <path
              id="Path_1369"
              data-name="Path 1369"
              d="M7679.1-755.123h-3.471a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h3.471a.751.751,0,0,1,.751.751A.751.751,0,0,1,7679.1-755.123Z"
              transform="translate(-7674.874 756.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1680" data-name="Group 1680" transform="translate(11.28 19.09)">
            <path
              id="Path_1370"
              data-name="Path 1370"
              d="M7675.625-725.652a.751.751,0,0,1-.751-.751v-3.471a.751.751,0,0,1,.751-.751.751.751,0,0,1,.751.751v3.471A.751.751,0,0,1,7675.625-725.652Z"
              transform="translate(-7674.874 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1681" data-name="Group 1681" transform="translate(14.751 19.09)">
            <path
              id="Path_1371"
              data-name="Path 1371"
              d="M7701.625-725.652a.751.751,0,0,1-.751-.751v-3.471a.751.751,0,0,1,.751-.751.752.752,0,0,1,.752.751v3.471A.752.752,0,0,1,7701.625-725.652Z"
              transform="translate(-7700.874 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1682" data-name="Group 1682" transform="translate(11.28 19.09)">
            <path
              id="Path_1372"
              data-name="Path 1372"
              d="M7679.1-729.123h-3.471a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h3.471a.751.751,0,0,1,.751.751A.751.751,0,0,1,7679.1-729.123Z"
              transform="translate(-7674.874 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1683" data-name="Group 1683" transform="translate(11.28 22.561)">
            <path
              id="Path_1373"
              data-name="Path 1373"
              d="M7679.1-703.123h-3.471a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h3.471a.751.751,0,0,1,.751.751A.751.751,0,0,1,7679.1-703.123Z"
              transform="translate(-7674.874 704.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1684" data-name="Group 1684" transform="translate(18.222 12.148)">
            <path
              id="Path_1374"
              data-name="Path 1374"
              d="M7727.625-777.652a.752.752,0,0,1-.751-.751v-3.471a.752.752,0,0,1,.751-.751.751.751,0,0,1,.751.751v3.471A.751.751,0,0,1,7727.625-777.652Z"
              transform="translate(-7726.874 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1685" data-name="Group 1685" transform="translate(21.693 12.148)">
            <path
              id="Path_1375"
              data-name="Path 1375"
              d="M7753.626-777.652a.752.752,0,0,1-.752-.751v-3.471a.752.752,0,0,1,.752-.751.751.751,0,0,1,.75.751v3.471A.751.751,0,0,1,7753.626-777.652Z"
              transform="translate(-7752.874 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1686" data-name="Group 1686" transform="translate(18.222 12.148)">
            <path
              id="Path_1376"
              data-name="Path 1376"
              d="M7731.1-781.123h-3.471a.752.752,0,0,1-.751-.751.752.752,0,0,1,.751-.751h3.471a.751.751,0,0,1,.75.751A.751.751,0,0,1,7731.1-781.123Z"
              transform="translate(-7726.874 782.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1687" data-name="Group 1687" transform="translate(18.222 15.619)">
            <path
              id="Path_1377"
              data-name="Path 1377"
              d="M7731.1-755.123h-3.471a.752.752,0,0,1-.751-.751.752.752,0,0,1,.751-.751h3.471a.751.751,0,0,1,.75.751A.751.751,0,0,1,7731.1-755.123Z"
              transform="translate(-7726.874 756.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1688" data-name="Group 1688" transform="translate(18.222 19.09)">
            <path
              id="Path_1378"
              data-name="Path 1378"
              d="M7727.625-725.652a.752.752,0,0,1-.751-.751v-3.471a.752.752,0,0,1,.751-.751.751.751,0,0,1,.751.751v3.471A.751.751,0,0,1,7727.625-725.652Z"
              transform="translate(-7726.874 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1689" data-name="Group 1689" transform="translate(21.693 19.09)">
            <path
              id="Path_1379"
              data-name="Path 1379"
              d="M7753.626-725.652a.752.752,0,0,1-.752-.751v-3.471a.752.752,0,0,1,.752-.751.751.751,0,0,1,.75.751v3.471A.751.751,0,0,1,7753.626-725.652Z"
              transform="translate(-7752.874 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1690" data-name="Group 1690" transform="translate(18.222 19.09)">
            <path
              id="Path_1380"
              data-name="Path 1380"
              d="M7731.1-729.123h-3.471a.752.752,0,0,1-.751-.751.752.752,0,0,1,.751-.751h3.471a.751.751,0,0,1,.75.751A.751.751,0,0,1,7731.1-729.123Z"
              transform="translate(-7726.874 730.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1691" data-name="Group 1691" transform="translate(18.222 22.561)">
            <path
              id="Path_1381"
              data-name="Path 1381"
              d="M7731.1-703.123h-3.471a.752.752,0,0,1-.751-.751.752.752,0,0,1,.751-.751h3.471a.751.751,0,0,1,.75.751A.751.751,0,0,1,7731.1-703.123Z"
              transform="translate(-7726.874 704.625)"
              fill="#353743"
            />
          </g>
        </g>
        <g id="Group_1699" data-name="Group 1699" transform="translate(0 0)">
          <path
            id="Path_1387"
            data-name="Path 1387"
            d="M7526.089-911.2a25.743,25.743,0,0,1-25.714-25.714,25.743,25.743,0,0,1,25.714-25.715A25.744,25.744,0,0,1,7551.8-936.91,25.744,25.744,0,0,1,7526.089-911.2Zm0-49.927a24.24,24.24,0,0,0-24.212,24.213,24.24,24.24,0,0,0,24.212,24.213A24.24,24.24,0,0,0,7550.3-936.91,24.241,24.241,0,0,0,7526.089-961.123Z"
            transform="translate(-7500.375 962.625)"
            fill="#353743"
          />
        </g>
      </g>
    </svg>
  );
};

export default SumCalendar;
