import React from 'react';
import * as Realm from 'realm-web';

export const APP_ID = 'wizard-qpxfp';
export const graphqlUri = `https://services.cloud.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`;
// Connect to your MongoDB Realm app
export const realmApp = new Realm.App(APP_ID);
// Gets a valid Realm user access token to authenticate requests
export async function getAccessToken() {
  // Guarantee that there's a logged in user with a valid access token
  if (!realmApp.currentUser) {
    const user = await realmApp?.logIn(Realm.Credentials.anonymous());
    return {
      accessToken: user.accessToken || '',
      user,
    };
  } else {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    await realmApp.currentUser.refreshCustomData();
  }
  return {
    accessToken: realmApp?.currentUser?.accessToken || '',
    user: realmApp?.currentUser,
  };
}
export const RealmAppContext = React.createContext<undefined | typeof realmApp>(undefined);
