import React, { FunctionComponent } from 'react';

import {
  Wrapper,
  ImageWrapper,
  IconWrapper,
  IconsWrapper,
  Image,
  ImageIcon,
  FlexWrapper,
  FlexColumn,
  ImageIconWrapper,
  ActionIconsWrapper,
} from './styled';
import Edit from 'components/shared/icons/edit';
import Erase from 'components/shared/icons/erase';
import { Box, Typography } from '@material-ui/core';
import { styledTheme } from 'styles/themes';
import { ResponsiveImageType } from 'react-datocms';
import QtyBubble from 'components/shared/qty-bubble';
export interface CardLargeProps {
  title1?: string;
  image?: ResponsiveImageType;
  title2?: string;
  icons?: { icon: React.ReactNode; text: string }[];
  price?: string | React.ReactNode;
  pricePerPerson?: boolean;
  readOnly?: boolean;
  style?: React.CSSProperties | undefined;
  handleEdit?: (args: any) => void;
  handleErease?: (args: any) => void | Promise<void>;
  fullWidth?: boolean;
  qty?: number | string;
}

const CardLarge: FunctionComponent<CardLargeProps> = ({
  style,
  image,
  fullWidth,
  title1,
  title2,
  icons,
  price,
  handleEdit,
  handleErease,
  readOnly,
  qty,
  ...props
}) => {
  return (
    <>
      <Wrapper style={{ ...style, width: fullWidth ? '100%' : style?.width || 'auto' }} {...props}>
        {qty && (
          <Box
            sx={{
              position: 'absolute',
              top: '-10px',
              bottom: '0',
              margin: 'auto',
              right: '-10px',
            }}
          >
            <QtyBubble qty={qty} />
          </Box>
        )}
        <div>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              marginBottom: '3.5px',
              fontSize: '14px',
            }}
          >
            {title1}
          </Typography>
        </div>
        <FlexWrapper>
          <ImageWrapper>{image && <Image data={image}></Image>}</ImageWrapper>
          <FlexColumn>
            <Typography
              variant="h6"
              component="h6"
              className="detail__cardLarge"
              sx={{
                marginBottom: '0px',
                fontSize: '14px',
                color: styledTheme.colors.primary,
                maxWidth: {
                  xs: '70%',
                  md: '90%',
                  lg: '100%',
                },
              }}
            >
              {title2}
            </Typography>

            <IconsWrapper>
              {icons?.map(({ text, icon }) => {
                return (
                  <>
                    <IconWrapper>
                      <ImageIconWrapper>{icon}</ImageIconWrapper>
                      <Typography
                        variant="h6"
                        component="h6"
                        style={{
                          marginBottom: '6.5px',
                          fontSize: '14px',
                          marginTop: '10px',
                        }}
                      >
                        {text}
                      </Typography>
                    </IconWrapper>
                  </>
                );
              })}
            </IconsWrapper>
          </FlexColumn>
          <Typography
            variant="h6"
            component="h6"
            style={{
              marginBottom: '7px',
              fontSize: '16px',
              textAlign: 'left',
              position: 'absolute',
              right: '13px',
            }}
          >
            {price}
            <span style={{ fontSize: '12px' }}>/ person</span>
          </Typography>
        </FlexWrapper>
        {!readOnly && (
          <ActionIconsWrapper>
            {handleEdit && <Edit style={{ marginRight: '7px' }} onClick={handleEdit}></Edit>}
            {handleErease && <Erase onClick={handleErease}></Erase>}
          </ActionIconsWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default CardLarge;
