import React, { FunctionComponent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropertyPopup from 'components/shared/property-popup';
import { useAppSelector, useMustHaveGroupId } from 'app/hooks';
import { BUILDER_HOUSING_QUERY, BUILDER_HOUSING_QUERY_DATA } from 'app/queries';
import {
  groupInfoSelector,
  destinationInfoSelector,
  submitHousing,
  selectHousing,
  selectGroupDataCollectionStatus,
  gotoSummarySelector,
} from 'features/group-data-collection/groupSlice';
import { Cards, StyledSkeleton, SelectWrapper } from './styled';
import Select from 'components/shared/select';
import { CardWrapper } from 'components/shared';
import Card from 'components/shared/card';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FormArea from '../form-area';
import toast from 'react-hot-toast';
import { submitLastSeenAtStep, selectGroupId, setBidoID, reset } from 'features/group-data-collection/groupSlice';
import { Typography } from '@material-ui/core';
import useSort from 'hooks/useSort';
import api from 'api';
import { PriceService } from 'services/price';
export interface HousingProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const heading = 'Do you need a place to stay?';
const paragraph = 'Are you sure? We have some great featured deals selected specifically for you.';

const Housing: FunctionComponent<HousingProps> = ({ setActiveStep, ...props }) => {
  useMustHaveGroupId();
  useEffect(() => {
    setActiveStep(0);
  }, [setActiveStep]);
  const history = useHistory();
  const initialHousing = useAppSelector(selectHousing);
  const [housingYes, setHousingYes] = useState<undefined | boolean>(!!initialHousing || true);
  const [housing, setHousing] = useState<null | string>(initialHousing || null);
  const { groupSize } = useAppSelector(groupInfoSelector);
  const _id = useAppSelector(selectGroupId);
  const { destination, fromDate, untilDate } = useAppSelector(destinationInfoSelector);
  const diffTime = Math.abs(new Date(fromDate).getTime() - new Date(untilDate).getTime());
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const { data, loading: loadingHousing } = useQuery<BUILDER_HOUSING_QUERY_DATA>(BUILDER_HOUSING_QUERY, {
    variables: {
      destination: destination.id,
      groupSize,
      minimumNightsRental: diffDays,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    // we're insterested in knowing that the user got to ready because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'housing' }));
    (async () => {
      if (_id) {
        const bido_id = await api.getBidoId(_id);
        if (bido_id) {
          dispatch(setBidoID({ bido_id }));
        } else {
          dispatch(reset(null));
        }
      }
    })();
  }, [dispatch, _id]);
  const [sorting, setSorting] = useState('priceDesc');

  const { sortedArray } = useSort({
    array: data?.allHousings,
    fromDate,
    untilDate,
    groupSize,
    sorting,
    type: 'housing',
  });

  const gotoSummary = useAppSelector(gotoSummarySelector);
  const handleSubmit = async (e: any, yes: boolean) => {
    e.preventDefault();
    const selectedHousingData = data?.allHousings.find((house) => house.id == housing);
    const submit = selectedHousingData
      ? {
          id: selectedHousingData.id,
          name: selectedHousingData.name,
        }
      : undefined;
    if (!submit && yes) {
      toast.error('Please make your housing selection');
      return;
    }
    await dispatch(submitHousing({ data: submit }));
    if (gotoSummary) {
      //was clicked by checkout button (synthetic, non-human click)
      history.push('/builder/summary');
    } else {
      history.push('/builder/transportation');
    }
  };
  const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);

  const [showPopup, setShowPopup] = useState(false);
  const [propertyPopupID, setPropertyPopupID] = useState('');

  return (
    <>
      {showPopup && <PropertyPopup setShowPopup={setShowPopup} setHousing={setHousing} id={propertyPopupID} />}
      <FormArea
        heading={heading}
        paragraph={paragraph}
        initialValue={!!initialHousing}
        isSubmitting={dataCollectionStatus === 'loading'}
        stepLabel="Housing"
        onSubmit={handleSubmit}
        setCustomYes={setHousingYes}
      >
        <SelectWrapper>
          <Typography style={{ marginRight: '15px' }} variant="h4" component="h4">
            Sort:
          </Typography>
          <Select
            nomb
            options={[
              { name: 'Price - High to Low', value: 'priceDesc' },
              { name: 'Price - Low to High', value: 'priceAsc' },
              { name: 'Beds/Bedrooms - High to Low', value: 'bedsDesc' },
              { name: 'Beds/Bedrooms - Low to High', value: 'bedsAsc' },
            ]}
            onChange={(e) => {
              setSorting(e.target.value);
            }}
          />
        </SelectWrapper>
        {loadingHousing && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
            </div>
          </div>
        )}
        <Cards>
          {housingYes !== undefined &&
            sortedArray.map((housingfetched: any, i: number) => {
              const seasonalPricing = housingfetched?.destination.seasonalPricing;
              let finalPrice;
              if (seasonalPricing && groupSize) {
                finalPrice = PriceService.housing(
                  {
                    peakSeasonEnds: housingfetched?.destination.peakSeasonEnds,
                    peakSeasonStarts: housingfetched?.destination.peakSeasonStarts,
                    highSeasonStarts: housingfetched?.destination.highSeasonStarts,
                    highSeasonEnds: housingfetched?.destination.highSeasonEnds,
                    priceHigh: housingfetched?.priceHigh,
                    priceLow: housingfetched?.priceLow,
                    priceForHoliday: housingfetched?.priceForHoliday,
                    fromDate: new Date(fromDate),
                    untilDate: new Date(untilDate),
                    groupSize,
                  },
                  {
                    labeled: true,
                    usd: true,
                    rounded: true,
                    noCeroCero: true,
                  }
                );
              }
              const cardProps = {
                image: housingfetched?.cardImage?.responsiveImage,
                title: housingfetched?.name,
                iconsNew: [
                  { text1: housingfetched?.groupSizeMax, text2: 'Guests' },
                  { text1: `${housingfetched?.bedrooms} / ${housingfetched?.beds}`, text2: 'Bedrooms/Beds' },
                  { text1: housingfetched?.bathrooms, text2: 'Baths' },
                ],
                paragraph: housingfetched?.shortDescription,
                button1: {
                  text: 'LEARN MORE',
                  onClick: () => {
                    setPropertyPopupID(housingfetched?.id);
                    setShowPopup(true);
                  },
                },
                button2: {
                  text: 'Select Property',
                  onClick: () => {
                    if (housingfetched?.id == housing) {
                      //deselect
                      setHousing(null);
                    } else {
                      setHousing(housingfetched?.id);
                    }
                  },
                  scrollOnClick: true,
                },
                featured: housingfetched?.featured,
                selected: housingfetched?.id == housing,
                price: finalPrice,
              };
              if (housingYes) {
                return (
                  <CardWrapper key={housingfetched?.id}>
                    <Card {...cardProps} />
                  </CardWrapper>
                );
              } else {
                if (i < 3) {
                  return (
                    <CardWrapper key={housingfetched?.id}>
                      <Card {...cardProps} />
                    </CardWrapper>
                  );
                } else {
                  return null;
                }
              }
            })}
        </Cards>
      </FormArea>
      <div id="scrollHelper" />
    </>
  );
};

export default Housing;
