import React, { FunctionComponent, useState } from 'react';
import {
  Stepper,
  Step,
  OutterCirlce,
  InnerCircle,
  Label,
  Connector,
  ConnectorAnimation,
  MessageDiv,
  Triangle,
  CustomTooltip,
} from './styled';
import useWindowWidth from 'hooks/useWindowWidth';
import { IStep } from 'features/lead-capturing';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';

interface IStepperCustom {
  activeStep: number;
  steps: IStep[];
  connectorWidthMobile?: number | null;
}

const StepperCustom: FunctionComponent<IStepperCustom> = ({ connectorWidthMobile, activeStep, steps }) => {
  const { windowWidth } = useWindowWidth();
  let history = useHistory();
  let isMobile = windowWidth < 768;
  const [showTooltip, setShowTooltip] = useState<null | number>(null);
  const [message, setMessage] = useState(false);
  return (
    <Stepper onMouseEnter={() => setMessage(true)} onMouseLeave={() => setMessage(false)}>
      {steps.map(({ icon, link, tooltip }, i) => {
        let active = activeStep === i;
        let completed = activeStep > i;
        let last = steps.length - 1 === i;
        const activeOrCompleted = active || completed;
        // const activeAndCompleted = active && completed;
        const desktopStyles = {
          outterCircleStyles: {
            backgroundColor: activeOrCompleted ? (last ? '#52F0A6' : '#03A0EF') : 'transparent',
            color: activeOrCompleted ? '#fff' : '#BAC3C9',
            width: activeOrCompleted ? (last ? '54px' : '44px') : '30px',
            height: activeOrCompleted ? (last ? '54px' : '44px') : '30px',
            fontFamily: ['"Arial"', '"sans-serif"'].join(','),
            fontSize: activeOrCompleted ? '26px' : '18px',
          },
          innerCircleStyles: {
            border: activeOrCompleted ? (last ? '2px solid #1ECC7A' : '2px solid #fff') : '2px solid #BAC3C9',
            backgroundColor: activeOrCompleted ? (last ? '#1ECC7A' : '#03A0EF') : 'transparent',
            width: activeOrCompleted ? '34px' : '30px',
            height: activeOrCompleted ? '34px' : '30px',
          },
          connectorStyles: {},
          connectorAnimationStyles: {
            backgroundColor: activeOrCompleted ? '#03A0EF' : 'transparent',
            transitionDelay: active ? '0.3s' : '0s',
            height: completed ? '100%' : active ? '50%' : '0%',
            width: '100%',
          },
        };

        const mobileStyles = {
          outterCircleStyles: {
            backgroundColor: activeOrCompleted ? (last ? '#52F0A6' : '#03A0EF') : 'transparent',
            color: activeOrCompleted ? '#fff' : '#BAC3C9',
            fontFamily: ['"Arial"', '"sans-serif"'].join(','),
            width: activeOrCompleted ? (last ? '44px' : '36px') : '24px',
            height: activeOrCompleted ? (last ? '44px' : '36px') : '24px',
            fontSize: activeOrCompleted ? '22px' : '16px',
          },
          innerCircleStyles: {
            border: activeOrCompleted ? (last ? '2px solid #1ECC7A' : '2px solid #fff') : '2px solid #BAC3C9',
            backgroundColor: activeOrCompleted ? (last ? '#1ECC7A' : '#03A0EF') : 'transparent',
            width: activeOrCompleted ? '28px' : '24px',
            height: activeOrCompleted ? '28px' : '24px',
          },
          connectorStyles: {
            width: `${connectorWidthMobile}px`,
          },
          connectorAnimationStyles: {
            backgroundColor: activeOrCompleted ? '#03A0EF' : 'transparent',
            transitionDelay: active ? '0.3s' : '0s',
            height: '100%',
            width: completed ? '100%' : active ? '50%' : '0%',
          },
        };

        const styles = isMobile ? mobileStyles : desktopStyles;
        return (
          <Step>
            <Label onClick={() => history.push(link)}>
              <OutterCirlce
                style={styles.outterCircleStyles}
                onMouseEnter={() => setShowTooltip(i)}
                onMouseLeave={() => setShowTooltip(null)}
              >
                <InnerCircle style={styles.innerCircleStyles}>{icon}</InnerCircle>
                {tooltip && typeof showTooltip == 'number' && showTooltip == i && (
                  <CustomTooltip>
                    <Triangle></Triangle>
                    <MessageDiv>{tooltip}</MessageDiv>
                  </CustomTooltip>
                )}
              </OutterCirlce>
            </Label>

            {i !== steps.length - 1 && (
              <Connector style={styles.connectorStyles}>
                <ConnectorAnimation style={styles.connectorAnimationStyles} />
              </Connector>
            )}
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepperCustom;
