import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { Form, ButtonsWrapper } from 'components/shared';
import SubmitButton from 'components/shared/submit-button';
import { groupInfoSelector, submitGroupInfo } from 'features/group-data-collection/groupSlice';
import Select from 'components/shared/select';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { selectGroupDataCollectionStatus } from 'features/group-data-collection/groupSlice';
import { useAppDispatch, useAppSelector, useMustHaveGroupId } from 'app/hooks';
import BackButton from 'components/shared/back-button';
import StepLabel from 'components/shared/step-label';
import { Skeleton } from '@material-ui/lab';
export interface GroupInfoProps {
  handleNext: () => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  heading?: string;
  budgets?: { id: string; name: string; budgetRange: string }[];
}

export interface GroupInfoProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export type FormValuesGroupInfo = {
  groupSize: number;
  budget: string;
};

const GroupInfo: FunctionComponent<GroupInfoProps> = ({ budgets, handleNext, setActiveStep, heading, ...props }) => {
  useMustHaveGroupId();
  useEffect(() => {
    setActiveStep(2);
  }, [setActiveStep]);
  let history = useHistory();
  const initialValues = useAppSelector(groupInfoSelector);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormValuesGroupInfo>({
    defaultValues: {
      groupSize: initialValues.groupSize,
      budget: initialValues.budget,
    },
  });
  const dispatch = useAppDispatch();
  const onSubmit = async (data: FormValuesGroupInfo) => {
    console.log(data, 'data');
    await dispatch(submitGroupInfo({ data }));
    handleNext();
    history.push('/ready');
  };
  const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);
  const loading = dataCollectionStatus === 'loading';

  const options = [
    { name: 'Select one...', value: '' },
    ...new Array(30).fill('beer').map((_, i) => ({ name: `${i + 1}`, value: `${i + 1}` })),
  ];

  const budgetOptions = useMemo(
    () =>
      budgets
        ? [
          { name: 'Select one...', value: '' },
          ...budgets.map((budget) => ({ name: `${budget.name} - ${budget.budgetRange}`, value: budget.name })),
        ]
        : [{ name: 'Select one...', value: '' }],
    [budgets]
  );

  useEffect(() => {
    /** asynchronous select default value requires this side effect
     * https://github.com/react-hook-form/react-hook-form/discussions/2179
     * */
    if (!isSubmitting) {
      setValue('budget', initialValues.budget || '');
    }
  }, [budgetOptions, initialValues, setValue, isSubmitting]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <StepLabel>Step 3</StepLabel>
        <Typography variant="h4" component="h4" style={{ marginBottom: '40px' }}>
          {heading || <Skeleton animation="wave" width={'70%'} />}
        </Typography>
        <Select
          label="HOW MANY PEOPLE IN YOUR GROUP?"
          {...register('groupSize', {
            required: { value: true, message: 'Please choose your group size' },
          })}
          options={options}
          error={errors?.groupSize?.message}
        />

        <Select
          label="What's your per-person budget"
          {...register('budget', {
            required: { value: true, message: 'Please choose your budget' },
          })}
          options={budgetOptions}
          error={errors?.budget?.message}
        />

      </div>
      <ButtonsWrapper>
        <BackButton />
        <SubmitButton loading={loading} />
      </ButtonsWrapper>
    </Form>
  );
};

export default GroupInfo;
