import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { Form, ButtonsWrapper } from 'components/shared';
import { DateWrapper } from './styled';
import SubmitButton from 'components/shared/submit-button';
import { destinationInfoSelector, submitDestination } from 'features/group-data-collection/groupSlice';
import Select from 'components/shared/select';
import DatePicker from 'components/shared/date';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { selectGroupDataCollectionStatus } from 'features/group-data-collection/groupSlice';
import { useAppDispatch, useAppSelector, useMustHaveGroupId } from 'app/hooks';
import { Flex } from './styled';
import { FromIcon } from 'components/shared/date/FromIcon';
import { TilIcon } from 'components/shared/date/TilIcon';
import BackButton from 'components/shared/back-button';
import { useQuery } from '@apollo/client';
import StepLabel from 'components/shared/step-label';
import { Moment } from 'moment';
import { DESTINATIONS_QUERY } from 'app/queries';
import { Skeleton } from '@material-ui/lab';

export type FormValuesDestination = {
  destination: string;
  fromDate: string;
  untilDate: string;
  travelReason: string;
};

export type FormRealValuesDestination = {
  destination: string;
  fromDate: Moment;
  untilDate: Moment;
  travelReason: string;
};

export interface Destination {
  country: string;
  place: string;
  id: string;
  appointmentsOnly: boolean;
  __typename: 'DestinationRecord';
}

interface DestinationsData {
  allDestinations: Destination[];
}

interface Step2Props {
  handleNext: () => void;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  heading?: string;
  reasonsForTravel?: string;
}

const Step2: FunctionComponent<Step2Props> = ({ setActiveStep, handleNext, heading, reasonsForTravel, ...props }) => {
  useMustHaveGroupId();
  useEffect(() => {
    setActiveStep(1);
  }, [setActiveStep]);
  const { data: destinations, loading: loadingDestinations }: { data?: DestinationsData; loading: boolean } =
    useQuery(DESTINATIONS_QUERY);
  let history = useHistory();
  const initialValues = useAppSelector(destinationInfoSelector);
  const defaultValues = {
    travelReason: initialValues.travelReason || '',
    destination: initialValues.destination.id || '',
    fromDate: initialValues.fromDate || new Date().toISOString(),
    untilDate: initialValues.untilDate || new Date(new Date().getTime() + 86400000).toISOString(),
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    control,
    setValue,
    reset,
  } = useForm<FormValuesDestination>({
    defaultValues,
  });

  const dispatch = useAppDispatch();
  const onSubmit = async (data: FormRealValuesDestination) => {
    let destination = destinations?.allDestinations?.find((des) => data.destination === des.id);
    if (destination) {
      await dispatch(
        submitDestination({
          data: { ...data, destination },
        })
      );
      handleNext();
      history.push('/group-info');
    } else {
      //TODO: I don't think this else would ever run lol
    }
  };

  const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);
  const loading = dataCollectionStatus === 'loading';

  let destinationOptions = [{ name: 'Select one...', value: '' }];
  const fetchedDestinations = destinations?.allDestinations?.map((destination) => {
    const { country, place, id } = destination;
    return { name: `${place} - ${country}`, value: id, ...destination };
  });
  if (fetchedDestinations) {
    destinationOptions.push(...fetchedDestinations);
  }

  const reasons = reasonsForTravel
    ? [
        { name: 'Select one...', value: '' },
        ...reasonsForTravel.split(',').map((reason) => ({ name: reason, value: reason })),
      ]
    : [{ name: 'Select one...', value: '' }];

  const tilDateMin = () => {
    const watched = new Date(watch(['fromDate'])[0]);
    if (watched) {
      return new Date(watched.getTime() + 86400000); //add a day
    } else {
      return new Date(new Date().getTime() + 86400000); //add a day
    }
  };

  useEffect(() => {
    /** asynchronous select default value requires this side effect
     * https://github.com/react-hook-form/react-hook-form/discussions/2179
     * */
    if (!isSubmitting) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinations]); //This dependency array must remain as is to prevent infinite loop callback.

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <StepLabel>Step 2</StepLabel>
        <Typography variant="h4" component="h4" style={{ marginBottom: '40px' }}>
          {heading || <Skeleton animation="wave" width={'70%'} />}
        </Typography>
        {/* //TODO: technical debt */}
        {destinationOptions && (
          <Select
            disabled={loading}
            style={{ maxWidth: '320px' }}
            label="SELECT A DESTINATION"
            {...register('destination', {
              required: { value: true, message: 'Please choose a destination' },
            })}
            options={destinationOptions}
            error={errors?.destination?.message}
          />
        )}
        <Flex>
          <DateWrapper style={{ marginBottom: errors?.fromDate?.message ? '50px' : '0px' }}>
            <Controller
              name="fromDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a date',
                },
              }}
              render={({ field }) => (
                <DatePicker
                  disabled={loading}
                  icon={<FromIcon />}
                  label="from when"
                  datePickerProps={field}
                  error={errors?.fromDate?.message}
                />
              )}
            />
          </DateWrapper>
          <DateWrapper style={{ marginBottom: errors?.fromDate?.message ? '50px' : '0px' }}>
            <Controller
              name="untilDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a date',
                },
              }}
              render={({ field }) => (
                <DatePicker
                  disabled={loading}
                  icon={<TilIcon />}
                  label="till when"
                  datePickerProps={field}
                  minDate={tilDateMin()}
                  error={errors?.untilDate?.message}
                />
              )}
            />
          </DateWrapper>
        </Flex>
        <Select
          disabled={loading}
          style={{ maxWidth: '320px' }}
          label="What's the reason for your travel?"
          {...register('travelReason', {
            required: { value: true, message: 'Please choose a reason' },
          })}
          options={reasons}
          error={errors?.travelReason?.message}
        />
      </div>
      <ButtonsWrapper>
        <BackButton />
        <SubmitButton loading={loading} />
      </ButtonsWrapper>
    </Form>
  );
};

export default Step2;
