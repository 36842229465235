import React, { FunctionComponent, useEffect } from 'react';
import LeadCapturing from 'features/lead-capturing';
import ExperienceBuilder from 'features/experience-builder';
import SummaryStage from 'features/summary-stage';
import PackageSummary from 'features/experience-page';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getQueryParams } from 'utils';
import { MonitoringService } from 'services/monitoring';
import ErrorCapture from 'features/error-capture';

export interface AppProps {}

const App: FunctionComponent<AppProps> = ({ ...props }) => {
  useEffect(() => {
    //TODO: FINISH THIS
    const params = getQueryParams(window.location.search);
    if (params.admin === 'true') {
      //show reset button
    }
    MonitoringService.init();
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/error">
          <ErrorCapture />
        </Route>
        <Route path="/experience/:_id" component={PackageSummary} />
        <Route path="/builder/summary">
          <SummaryStage />
        </Route>
        <Route path="/builder">
          <ExperienceBuilder />
        </Route>
        <Route path="/">
          <LeadCapturing />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
