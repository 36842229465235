import React, { FunctionComponent } from 'react';
import { Label, LabelText } from 'components/shared/label';
import styled from 'styled-components';

export interface SelectProps {
  options: {
    name: string;
    value: string;
  }[];
  className?: string;
  nomb?: boolean;
  label?: string;
  error?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  value?: any;
  onChange?: (args: any) => any;
  hideArrow?: boolean;
  fullwidth?: boolean;
}

const StyledSelect = styled.select`
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Lato';
  background: ${({ theme }) => theme.colors.bgInput};
  background-image: url('./svg/arrow.svg');
  background-position: 97%;
  background-repeat: no-repeat;
  border: 1px solid ${({ theme }) => theme.colors.bg};
  height: 40px;
  padding: 2px 20px;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  margin-left: 0;
  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
    font-size: 16px;
  }
  svg {
    color: ${({ theme }) => theme.colors.primary};
    transform: rotate(90deg);
  }
  appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

const SelectWrapper: FunctionComponent<SelectProps> = React.forwardRef(
  (
    { options, className, label, error, disabled, style = {}, value, onChange, hideArrow, nomb, fullwidth, ...props },
    ref: any
  ) => {
    return (
      <Label className={className || ''} style={style} fullWidth={fullwidth}>
        <LabelText>{label}</LabelText>
        {error && <p className="error-message">{error}</p>}
        <StyledSelect
          {...props}
          value={value}
          style={{ marginBottom: nomb ? 0 : '15px' }}
          onChange={onChange}
          ref={
            ref as unknown as
            | ((instance: HTMLSelectElement | null) => void)
            | React.RefObject<HTMLSelectElement>
            | null
            | undefined
          }
          disabled={disabled}
        >
          {options.map((option) => (
            <option key={`${option.value}-${label}`} value={option.value}>
              {option.name}
            </option>
          ))}
        </StyledSelect>
      </Label>
    );
  }
);

export default SelectWrapper;
