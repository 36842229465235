import styled from 'styled-components';
import { md, lg } from 'styles/themes';

export const Overlay = styled.div`
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Popup = styled.div`
  max-height: 93%;
  background-color: white;
  border-radius: 8px;
  position: relative;
  overflow: auto;
  width: 90%;
  max-width: 550px;
  @media ${md} {
    width: 550px;
  }
  @media ${lg} {
  }
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .charinput {
    padding: 3px;
    font-weight: 900;
    font-family: 'Lato';
    width: 25px;
    height: 36px;
    margin: 2px;
    border-radius: 4px;
    background: #f6f7f9;
    border: 1px solid #eaeaea;
    text-align: center;
    @include ${md} {
      margin: 5px;
      padding: 10px;
      width: 30px;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
