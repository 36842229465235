import React, { FunctionComponent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAppSelector, useMustHaveGroupId } from 'app/hooks';
import { BUILDER_TRANSPORTATION_QUERY, BUILDER_TRANSPORTATION_QUERY_DATA } from 'app/queries';
import {
  groupInfoSelector,
  destinationInfoSelector,
  submitTransportation,
  selectTransportation,
  selectGroupDataCollectionStatus,
  gotoSummarySelector,
} from 'features/group-data-collection/groupSlice';
import { Cards, SelectWrapper, StyledSkeleton } from './styled';
import Select from 'components/shared/select';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FormArea from '../form-area';
import toast from 'react-hot-toast';
import { submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
import useSort from 'hooks/useSort';
import TransportationCard from './card';
import { TransportationTrip } from 'types';

export interface TransportationProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const heading = 'Do you need transportation? 🚐';
const paragraph = 'Are you sure? We have some great featured deals selected specifically for you.';

const Transportation: FunctionComponent<TransportationProps> = ({ setActiveStep, ...props }) => {
  useEffect(() => {
    setActiveStep(1);
  }, [setActiveStep]);
  useMustHaveGroupId();
  const history = useHistory();
  const initialTransportation = useAppSelector(selectTransportation);
  const [transportation, setTransportation] = useState<
    null | { id?: string; qty?: number | string; trip?: TransportationTrip }[]
  >(initialTransportation || null);
  const [transportationYes, seTransportationYes] = useState<undefined | boolean>(!!initialTransportation || true);
  const { groupSize, tripLength } = useAppSelector(groupInfoSelector);
  const { destination } = useAppSelector(destinationInfoSelector);
  const { data, loading: loadingTransportations } = useQuery<BUILDER_TRANSPORTATION_QUERY_DATA>(
    BUILDER_TRANSPORTATION_QUERY,
    {
      variables: {
        destination: destination.id,
        groupSize,
      },
    }
  );
  const dispatch = useDispatch();
  const gotoSummary = useAppSelector(gotoSummarySelector);
  useEffect(() => {
    // we're insterested in knowing that the user got to ready because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'transportation' }));
  }, [dispatch]);

  const handleSubmit = async (e: any, yes: boolean) => {
    e.preventDefault();
    const selectedTransportationData = transportation
      ?.map(({ id, qty, trip }) => {
        const transportation = data?.allTransportations.find((transp) => transp.id === id);
        if (transportation) {
          return {
            id: transportation.id,
            name: transportation.name,
            qty: `${qty}`,
            trip: trip,
          };
        } else return undefined;
      })
      .filter((x) => x);
    if (!selectedTransportationData && yes) {
      toast.error('Please make your  transportation selection');
      return;
    }
    await dispatch(submitTransportation({ data: selectedTransportationData }));
    //TODO: collect selected data here
    if (gotoSummary) {
      //was clicked by checkout button (synthetic, non-human click)
      history.push('/builder/summary');
    } else {
      history.push('/builder/daytime-activities');
    }
  };
  const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);

  //TODO: make it so if the transportation in redux changes, the select slocal state syncs
  const [showPopup, setShowPopup] = useState(false);
  const [transportationPopupID, setTransportationPopupID] = useState('');

  const [sorting, setSorting] = useState('priceDesc');

  const { sortedArray } = useSort({
    array: data?.allTransportations,
    groupSize,
    sorting,
    type: 'transportation',
  });

  return (
    <>
      <FormArea
        multiSelect
        heading={heading}
        paragraph={paragraph}
        isSubmitting={dataCollectionStatus === 'loading'}
        stepLabel="Transportation"
        onSubmit={handleSubmit}
        initialValue={!!initialTransportation}
        setCustomYes={seTransportationYes}
      >
        <SelectWrapper>
          <Typography style={{ marginRight: '15px' }} variant="h4" component="h4">
            Sort:
          </Typography>

          <Select
            nomb
            options={[
              { name: 'Price - High to Low', value: 'priceDesc' },
              { name: 'Price - Low to High', value: 'priceAsc' },
              { name: 'Seats - High to Low', value: 'seatsDesc' },
              { name: 'Seats - Low to High', value: 'seatsAsc' },
            ]}
            onChange={(e) => {
              setSorting(e.target.value);
            }}
          />
        </SelectWrapper>
        {loadingTransportations && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
              <StyledSkeleton animation="wave" height={'292px'} />
            </div>
          </div>
        )}
        <Cards>
          {transportationYes !== undefined &&
            sortedArray.map((transportationfetched: any, i: number) => (
              <TransportationCard
                {...{
                  groupSize,
                  tripLength,
                  setTransportationPopupID,
                  showPopup,
                  transportationPopupID,
                  setShowPopup,
                  setTransportation,
                  transportation,
                  transportationfetched,
                  transportationYes,
                  i,
                }}
              />
            ))}
        </Cards>
      </FormArea>
      <div id="scrollHelper" />
    </>
  );
};

export default Transportation;
