import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Container as MaterialContainer } from '@material-ui/core';

export const Container = styled(MaterialContainer)`
  min-height: 86vh;
  @media ${md} {
    min-height: 100vh;
  }
`;

export const Progress = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 22px 24px;
  @media ${md} {
    display: block;
    width: 98px;
    padding-top: 54px;
  }
  @media ${lg} {
    width: 98px;
  }
`;

export const Logo = styled.img`
  width: 50px;
  margin-right: 5px;
  min-width: 50px;
  @media ${md} {
    width: 44px;
    min-width: 0;
    margin-right: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 86vh;
  @media ${md} {
    display: flex;
    min-height: 100vh;
  }
`;

export const FormArea = styled.div`
  width: 100%;
  min-height: 86vh;
  padding: 22px 24px;
  @media ${md} {
    padding: 54px 48px;
    width: 80%;
    border-left: 2px solid ${({ theme }) => theme.colors.bgLight};
    min-height: 100vh;
  }
  @media ${lg} {
    width: 55%;
    padding: 54px 30px 40px 40px;
  }
`;

export const ImageArea = styled.div`
  background: ${({ theme }) => theme.colors.secondary};
  display: none;
  @media ${lg} {
    display: block;
    height: 100%;
    width: 35%;
    overflow: hidden;
  }
`;

export const ImageWrapper = styled.div`
  @media ${lg} {
    position: absolute;
    top: 0;
    // function as model for image placement
    left: calc(1170px * 0.65 + (100vw - 1170px) / 2);
    width: calc((1170px * 0.35) + (100vw - 1170px) / 2);
    height: 100vh;
    overflow: hidden;
  }
`;
