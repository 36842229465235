//CONSIDER EDITING DASHBOARD DATA COLLECTION IF YOU HAVE TO TOUCH THIS FILE
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import api from 'api';
import { groupState } from './groupSlice';

export interface pricesState {
  status: 'idle' | 'loading' | 'failed';
  _id?: string; // MongoDB group id
  data: {
    totalPrice: number;
    totalPerPerson: number;
    totalPerPayee: number;
    totalPerPersonExact: number;
    totalPerPayeeExact: number;
    housingTotal: number;
    housingPricePerPerson: number;
    transportationTotal: number;
    transportationPerPerson: number;
    daytimeActivitiesTotal: number;
    daytimeActivitiesPerPerson: number;
    nighttimeActivitiesTotal: number;
    nighttimeActivitiesPerPerson: number;
    personalServicesTotal: number;
    personalServicesPerPerson: number;
  };
  errors: { message: string }[];
}

export const initialState: pricesState = {
  _id: undefined,
  status: 'idle',
  data: {
    totalPrice: 0,
    totalPerPerson: 0,
    totalPerPayee: 0,
    totalPerPersonExact: 0,
    totalPerPayeeExact: 0,
    housingTotal: 0,
    housingPricePerPerson: 0,
    transportationTotal: 0,
    transportationPerPerson: 0,
    daytimeActivitiesTotal: 0,
    daytimeActivitiesPerPerson: 0,
    nighttimeActivitiesTotal: 0,
    nighttimeActivitiesPerPerson: 0,
    personalServicesTotal: 0,
    personalServicesPerPerson: 0,
  },
  errors: [],
};

const getPrices = createAsyncThunk('group/getPrices', async ({ _id }: { _id: string }, thunkAPI) => {
  if (!_id) {
    //Must have an id
    //TODO: reset group store
    //TODO: log to logrocket
    window.location.pathname = '/error';
    return;
  }
  try {
    const { errors: resErrors, ok, ...rest } = await api.getPrices(_id);
    if (ok) {
      return { ...rest, errors: resErrors };
    } else {
      window.location.pathname = '/error';
      return thunkAPI.rejectWithValue(JSON.stringify(resErrors));
    }
  } catch (error) {
    window.location.pathname = '/error';
    return thunkAPI.rejectWithValue(JSON.stringify([error]));
  }
});

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPrices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload) {
          const { _id, errors, ...rest } = action.payload;
          state.data = rest;
          state.errors.concat(errors);
        }
      })
      .addCase(getPrices.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message && typeof action.error.message === 'string') {
          state.errors.push({ message: action.error.message });
        } else if (action.error.message) {
          state.errors = JSON.parse(action.error.message);
        }
      });
  },
});

export const selectPrices = (state: RootState) => state.prices;
export const selectPricesData = (state: RootState) => state.prices.data;
export const selectPricesStatus = (state: RootState) => state.prices.status;
export const selectPricesErrors = (state: RootState) => state.prices.errors;
export { getPrices };

export default pricesSlice.reducer;
