import { useAppDispatch, usePrices } from 'app/hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import StepLabel from 'components/shared/step-label';
import { Row, Col1, Col2, EstimateWrapper, EstimateRow, SubmitButtonWrapper } from './styled';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import CardLarge from 'components/shared/card-large';
import {
  submitHousing,
  submitTransportation,
  summaryReached,
  toExperience,
  updatePayees,
} from 'features/group-data-collection/groupSlice';
import Beds from 'components/shared/icons/beds';
import Baths from 'components/shared/icons/baths';
import People from 'components/shared/icons/people';
import { daysDiff, toUSD, toUSDNoDecimal } from 'utils';
import { useHistory } from 'react-router';
import CardSummary from 'components/shared/card-summary';
import SumDestination from 'components/shared/icons/sum-destination';
import SumSize from 'components/shared/icons/sum-size';
import SumCalendar from 'components/shared/icons/sum-calendar';
import { useSummary } from 'app/hooks';
import SubmitButton from 'components/shared/submit-button';
import Select from 'components/shared/select';
import toast from 'react-hot-toast';
import { PriceService } from 'services/price';

const heading = `You’re almost done {firstName}.</br>Here is a full summary of the package you’ve built.`;
export interface SummaryProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const Summary: FunctionComponent<SummaryProps> = ({ setActiveStep, ...props }) => {
  useEffect(() => {
    setActiveStep(5);
  }, [setActiveStep]);
  const {
    housingData,
    transportationData,
    daytimeActivitiesData,
    nighttimeActivitiesData,
    personalServicesData,
    group,
  } = useSummary();

  const {
    totalPrice,
    totalPerPerson,
    totalPerPayee,
    totalPerPersonExact,
    totalPerPayeeExact,
    housingTotal,
    housingPricePerPerson,
    transportationTotal,
    transportationPerPerson,
    daytimeActivitiesTotal,
    daytimeActivitiesPerPerson,
    nighttimeActivitiesTotal,
    nighttimeActivitiesPerPerson,
    personalServicesTotal,
    personalServicesPerPerson,
    loadingPrices,
    renderPrice,
  } = usePrices();
  const [payees, setPayees] = useState(group.payees);
  const [changed, setChanged] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(summaryReached({}));
  }, []);

  const removeHousing = async () => {
    const res = await dispatch(submitHousing({ data: undefined }));
    console.log(res, 'res');
  };

  const removeTransportation = async () => {
    const res = await dispatch(submitTransportation({ data: undefined }));
    console.log(res, 'res');
  };

  const onSubmit = async () => {
    //set loading
    //redirect to summary
    console.log('Submitted!');
    await dispatch(toExperience(null));
    window.location.href = `/experience/${group._id}/?pay=true`;
  };

  const payeeOptions = new Array(Number(group?.groupSize))
    .fill('beer') //lol
    .map((_, i) => ({ name: `${i + 1}`, value: `${i + 1}` }));

  const handlePayeeChange = (e: any) => {
    setPayees(e.target.value);
    setChanged(true);
  };

  useEffect(() => {
    async function updpayees() {
      if (payees && changed) {
        setChanged(false);
        const updatedPayees = await dispatch(updatePayees({ payees }));
        if (updatedPayees.type == 'group/updatePayees/fulfilled') {
          toast.success('Payees updated.');
        } else {
          toast.error('Error updating payees, please try again.');
        }
      }
    }
    updpayees();
  }, [dispatch, payees, changed]);

  return (
    <div>
      <StepLabel>Package Summary</StepLabel>
      <Typography
        variant="h4"
        component="h4"
        dangerouslySetInnerHTML={{
          __html: heading.replace(/{firstName}/g, group.groupLead.firstName),
        }}
        style={{
          marginBottom: '22px',
          maxWidth: '634px',
          opacity: 0,
          animation: 'fadein forwards 1s',
        }}
      ></Typography>
      <div>
        <CardSummary
          icons={[
            {
              icon: <SumDestination />,
              text1: 'DESTINATION',
              text2: `${group.destination.place}, ${group.destination.country}`,
            },
            {
              icon: <SumCalendar />,
              text1: 'DATE',
              text2: `${new Date(`${group.fromDate}T00:00:00`).toDateString()} - ${new Date(
                `${group.untilDate}T00:00:00`
              ).toDateString()}`,
            },
            {
              icon: <SumSize />,
              text1: 'GROUP SIZE',
              text2: `${group?.groupSize} Adults`,
            },
          ]}
        />
      </div>
      <Row>
        <Col1>
          {housingData?.allHousings && housingData?.allHousings?.length > 0 && (
            <CardLarge
              pricePerPerson
              title1="HOUSING"
              image={housingData?.allHousings[0].cardImage?.responsiveImage}
              title2={housingData?.allHousings[0].name}
              icons={[
                { icon: <Beds></Beds>, text: `${housingData?.allHousings[0].bedrooms}` },
                { icon: <Baths></Baths>, text: `${housingData?.allHousings[0].bathrooms}` },
                { icon: <People></People>, text: `${housingData?.allHousings[0].groupSizeMax}` },
              ]}
              price={housingPricePerPerson && toUSDNoDecimal(housingPricePerPerson)}
              style={{ marginBottom: '13px' }}
              handleEdit={() => history.push('/builder')}
              // handleErease={removeHousing}
            ></CardLarge>
          )}
          {transportationData?.map((transportation) => {
            const transportationPrice =
              group.tripLength &&
              PriceService.transportation(
                {
                  isThisAnAirportTransfer: transportation.isThisAnAirportTransfer,
                  price: transportation.price,
                  trip: transportation.trip,
                  groupSize: group.groupSize,
                  activityType: transportation.activityType,
                  tripLength: group.tripLength,
                },
                { usd: true, noCeroCero: true, rounded: true }
              );
            return (
              <CardLarge
                pricePerPerson
                qty={transportation.qty}
                title1="TRANSPORTATION"
                image={transportation.cardImage?.responsiveImage}
                title2={transportation.name}
                icons={[{ icon: <People></People>, text: `${transportation.groupSizeMax}` }]}
                price={transportationPrice}
                style={{ marginBottom: '13px' }}
                handleEdit={() => history.push('/builder/transportation')}
                // handleErease={removeTransportation}
              ></CardLarge>
            );
          })}
          {daytimeActivitiesData?.allDaytimeActivities?.map((daytimeActivity) => {
            const price = PriceService.daytimeActivities(
              {
                activityType: daytimeActivity.activityType,
                price: daytimeActivity.price,
                groupSize: group.groupSize,
              },
              { usd: true, noCeroCero: true, rounded: true }
            );
            return (
              <CardLarge
                pricePerPerson
                title1="DAYTIME ACTIVITY"
                image={daytimeActivity.cardImage?.responsiveImage}
                title2={daytimeActivity.name}
                icons={[{ icon: <People></People>, text: `${daytimeActivity.groupSizeMax}` }]}
                price={price}
                style={{ marginBottom: '13px' }}
                handleEdit={() => history.push('/builder/daytime-activities')}
              ></CardLarge>
            );
          })}
          {nighttimeActivitiesData?.allNighttimeActivities.map((nighttimeActivity) => {
            const price = PriceService.nighttimeActivities(
              {
                activityType: nighttimeActivity.activityType,
                price: nighttimeActivity.price,
                groupSize: group.groupSize,
              },
              { usd: true, noCeroCero: true, rounded: true }
            );
            return (
              <CardLarge
                pricePerPerson
                title1="NIGHTTIME ACTIVITY"
                image={nighttimeActivity.cardImage?.responsiveImage}
                title2={nighttimeActivity.name}
                icons={[{ icon: <People></People>, text: `${nighttimeActivity.groupSizeMax}` }]}
                price={price}
                style={{ marginBottom: '13px' }}
                handleEdit={() => history.push('/builder/nighttime-activities')}
              ></CardLarge>
            );
          })}
          {personalServicesData?.map((personalService) => {
            const price = PriceService.personalServices(
              {
                activityType: personalService.activityType,
                fromDate: group.fromDate,
                price: personalService.price,
                reach: personalService.reach,
                untilDate: group.untilDate,
                groupSize: group.groupSize,
              },
              { usd: true, noCeroCero: true, rounded: true }
            );
            return (
              <CardLarge
                qty={personalService.qty}
                pricePerPerson
                title1="PERSONAL SERVICE"
                image={personalService.cardImage?.responsiveImage}
                title2={personalService.name}
                icons={[{ icon: <People></People>, text: `${personalService.groupSizeMax}` }]}
                price={price}
                style={{ marginBottom: '13px' }}
                handleEdit={() => history.push('/builder/personal-services')}
              ></CardLarge>
            );
          })}
        </Col1>
        <Col2>
          <EstimateWrapper>
            <div
              style={{
                padding: '10px 30px 12px 22px',
              }}
            >
              <Typography style={{ color: '#fff' }} variant="h4" component="h4">
                Estimate
              </Typography>
            </div>
            <div style={{ width: '100%', height: '1px', backgroundColor: '#4B4D5C' }} />
            <div
              style={{
                padding: '22px 30px 12px 27px',
              }}
            >
              <Typography style={{ marginBottom: '16px', color: '#03A0EF' }} variant="h6" component="h6">
                GROUP PACKAGE
              </Typography>
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Housing
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {housingPricePerPerson ? (
                    <>
                      {' '}
                      {renderPrice(housingPricePerPerson || 0)} <span style={{ fontSize: '12px' }}>/ person</span>{' '}
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Transportation
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {transportationPerPerson ? (
                    <>
                      {renderPrice(transportationPerPerson)}
                      <span style={{ fontSize: '12px' }}>/ person</span>
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {/* Daytime Activities //temp-no-night*/}
                  Activities
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {daytimeActivitiesPerPerson ? (
                    <>
                      {renderPrice(daytimeActivitiesPerPerson)} <span style={{ fontSize: '12px' }}>/ person</span>{' '}
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>
              {/* <EstimateRow> //temp-no-night
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Nighttime Activities
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {nighttimeActivitiesPerPerson !== '$0' ? (
                    <>
                      {nighttimeActivitiesPerPerson}
                      <span style={{ fontSize: '12px' }}>/ person</span>
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>*/}
              <EstimateRow>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  Personal Services
                </Typography>
                <Typography
                  style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                  variant="h6"
                  component="h6"
                >
                  {personalServicesPerPerson ? (
                    <>
                      {renderPrice(personalServicesPerPerson)}
                      <span style={{ fontSize: '12px' }}>/ person</span>
                    </>
                  ) : (
                    <span style={{ fontSize: '12px' }}>None</span>
                  )}
                </Typography>
              </EstimateRow>
            </div>
            {/* <div
              style={{
                border: '1px solid #4B4D5C',
                borderRadius: '6px',
                padding: '10px',
                margin: '0 30px 28px 27px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography style={{ color: '#fff' }} variant="h6" component="h6">
                Group Total
              </Typography>
              <Typography style={{ color: '#03A0EF', fontWeight: 'lighter' }} variant="h6" component="h6">
                {totalPrice}
              </Typography>
            </div> */}
            <div style={{ width: '100%', height: '0', borderBottom: '1px dashed #4B4D5C' }} />
            <Box
              sx={{
                padding: '22px 30px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                style={{ color: '#fff', fontWeight: 'lighter', fontSize: '18px' }}
                variant="h6"
                component="h6"
              >
                How many people will pay?
              </Typography>
              <Select
                style={{ maxWidth: '60px', marginBottom: 0 }}
                hideArrow
                label=" "
                value={payees}
                onChange={handlePayeeChange}
                options={payeeOptions}
              />
            </Box>

            <div style={{ width: '100%', height: '0', borderBottom: '1px dashed #4B4D5C' }} />
            <div style={{ padding: '16px 29px 21px 22px' }}>
              <Typography style={{ marginBottom: '16px', color: '#1ECC7A' }} variant="h6" component="h6">
                TOTAL PER PAYEE
              </Typography>
              <div
                style={{
                  border: '1px solid #4B4D5C',
                  borderRadius: '6px',
                  backgroundColor: '#4B4D5C',
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography style={{ color: '#fff' }} variant="h6" component="h6">
                  Each Payee Pays
                </Typography>
                <Typography style={{ color: '#1ECC7A', fontWeight: 'lighter' }} variant="h6" component="h6">
                  {renderPrice(totalPerPayee)}
                </Typography>
              </div>
            </div>
          </EstimateWrapper>
          <SubmitButtonWrapper>
            <SubmitButton style={{ marginLeft: 'auto' }} onClick={onSubmit}>
              Submit
            </SubmitButton>
          </SubmitButtonWrapper>
        </Col2>
      </Row>
    </div>
  );
};

export default Summary;
