import { Box, Button, Card, Checkbox, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Input } from '../input';
import { Controller, useForm } from 'react-hook-form';
import { Popup, Overlay, InputsWrapper } from './styled';
import toast from 'react-hot-toast';
import { GROUP_DATA } from 'app/queries';
import Popover from '@material-ui/core/Popover';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { selectGroup, setBidoID } from 'features/group-data-collection/groupSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CloseIcon from '@material-ui/icons/Close';
import { ApolloQueryResult } from '@apollo/client';
import Select from 'components/shared/select';
import PhoneInput from 'components/shared/phone-input';
import CONFIG from 'app/config';
import PayService from 'services/pay';
import { computeMonthsTilTrip } from 'utils';
import { ExpGroupData } from 'features/experience-page/expGroupSlice';

interface SignUpPopupInterface {
  group?: ExpGroupData;
  closePopup: () => void;
  refetchGroup: any;
  totalPerPersonExact?: number | string;
  totalPerPayeeExact?: number | string;
}
export type FormValues = {
  y1: string;
  y2: string;
  y3: string;
  y4: string;
  g1: string;
  g2: string;
  g3: string;
  g4: string;
  g5: string;
};

type SignUpFormValues = {
  name: string;
  email: string;
  phone: string;
  title: string;
  plan: string;
  payee: string;
};

const SignUpPopup: FC<SignUpPopupInterface> = ({
  group,
  closePopup,
  refetchGroup,
  totalPerPersonExact,
  totalPerPayeeExact,
  ...props
}) => {
  const [valid, setValid] = useState(true);
  const [validating, setValidating] = useState(false);
  const [registered, setRegistered] = useState(false);
  const _id = group?._id;
  const inputsWrapperRef = React.useRef<HTMLDivElement | null>(null);
  //POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const selectedGroup = useAppSelector(selectGroup);
  //FORMS
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const {
    register: registerSignUp,
    handleSubmit: handleSubmitSignUp,
    formState: { errors: errorsSignUp, isSubmitting: isSubmittingSignUp },
    control,
  } = useForm<SignUpFormValues>();
  const monthsTilTrip = computeMonthsTilTrip(`${group?.fromDate}`);
  const onSubmit = async (data: FormValues) => {
    const { y1, y2, y3, y4, g1, g2, g3, g4, g5 } = data;
    const bido_id = `${y1}${y2}${y3}${y4}-${g1}${g2}${g3}${g4}${g5}`;
    const body = {
      bido_id,
      _id,
    };
    try {
      setValidating(true);
      const res = await fetch('https://data.mongodb-api.com/app/wizard-qpxfp/endpoint/dashboard/verifyBidoId', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(body),
      });
      setValidating(false);
      if (res.ok) {
        //next
        const resJson = await res.json();
        console.log(resJson);
        if (resJson.valid) {
          //ids match
          setValid(true);
          dispatch(setBidoID({ bido_id }));
        } else {
          toast.error('The group ID you provided does not match our records');
        }
      } else {
        //reject with value
        toast.error('The group ID you provided does not match our records');
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const onSubmitSignUp = async (data: SignUpFormValues) => {
    if (totalPerPayeeExact && group && group.bido_id && group._id) {
      const payUrl = PayService.genCardAuthorizationURLv1({
        ...data,
        totalPerPayeeExact: `${totalPerPayeeExact}`,
        monthsTilTrip,
        bido_id: group.bido_id,
        _id: group._id,
      });
      window.location.href = payUrl;
    }
  };

  interface MyTarget {
    dataset: {
      number: number;
    };
    value: string;
  }
  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    console.log(e.target as unknown as MyTarget);
    console.log(e);
    const current = Number((e.target as unknown as MyTarget).dataset.number);
    const inputs = Array.from(document?.querySelectorAll(`[data-number]`) as unknown as HTMLInputElement[]);
    if ((e.key === 'Backspace' || e.keyCode === 8) && current > 1) {
      const prev = inputs[Number(current - 1)];
      prev?.focus();
    } else if (
      current < 9 &&
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(
        (e.target as unknown as MyTarget).value as unknown as string
      )
    ) {
      const next = inputs[Number(current)];
      next?.focus();
    }
  };

  const hasS = group?.firstName?.toLowerCase().slice(-1) === 's' ? "'" : "'s";
  const name = group?.firstName + hasS;

  const paymentInstallmentOptions = CONFIG.installmentOptions(monthsTilTrip);

  return (
    <Overlay>
      <Popup>
        <CloseIcon
          sx={{
            position: 'absolute',
            right: '5px',
            top: '5px',
            zIndex: 1,
            cursor: 'pointer',
          }}
          onClick={() => closePopup()}
        />
        <Card sx={{ '&.MuiPaper-root': { padding: '20px', position: 'relative' } }}>
          <Typography sx={{ mb: '20px' }} variant="h6" component="h6">
            Join {name} Experience!
          </Typography>
          {valid && (
            <form onSubmit={handleSubmitSignUp(onSubmitSignUp)}>
              <Typography textAlign="center">Please provide your information to join this BIDO Experience</Typography>
              <Input
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
                label="Name"
                {...registerSignUp('name', { required: { value: true, message: 'Please provide your name' } })}
                placeholder="Greg"
                error={errorsSignUp?.name?.message}
              />
              <Input
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
                label="Email"
                {...registerSignUp('email', { required: { value: true, message: 'Please provide your email' } })}
                placeholder="email@email.com"
                error={errorsSignUp?.email?.message}
              />
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: {
                    value: true,
                    message: 'Phone number is required',
                  },
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...{
                      ...field,
                      error: errorsSignUp?.phone?.message,
                      style: {
                        width: '100%',
                        height: '100%',
                      },
                    }}
                  />
                )}
              />
              <Select
                style={{ maxWidth: '100%', width: '100%' }}
                label="TITLE"
                {...registerSignUp('title', {
                  required: { value: true, message: 'Please choose a title' },
                })}
                options={[
                  {
                    name: 'Atendee',
                    value: 'Atendee',
                  },
                  {
                    name: 'Bride',
                    value: 'Bride',
                  },
                  { name: 'Groom', value: 'Groom' },
                  { name: 'Wife', value: 'Wife' },
                ]}
                error={errorsSignUp?.title?.message}
              />
              <Select
                style={{ maxWidth: '100%', width: '100%' }}
                label="INSTALLMENT OPTIONS"
                {...registerSignUp('plan', {
                  required: { value: true, message: 'Please choose a plan' },
                })}
                options={paymentInstallmentOptions}
                error={errorsSignUp?.plan?.message}
              />
              {/* <Select
                style={{ maxWidth: '100%', width: '100%' }}
                label="ARE YOU A PAYEE?"
                {...registerSignUp('payee')}
                options={[
                  { name: 'Yes', value: 'yes' },
                  { name: 'No', value: 'no' },
                ]}
                error={errorsSignUp?.payee?.message}
              /> */}
              <Typography sx={{ mb: '20px' }}>
                When clicking "Sign Up" you'll be prompted to input your payment information. After doing so you'll be
                enlisted in the experience!
              </Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Button onClick={() => closePopup()} size="large" variant="contained" color="secondary">
                  Cancel
                </Button>
                <Button type="submit" size="large" variant="contained" color="primary">
                  Sign up
                </Button>
              </div>
            </form>
          )}
        </Card>
      </Popup>
    </Overlay>
  );
};

export default SignUpPopup;
