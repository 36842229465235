import { CardWrapper } from 'components/shared';
import Card from 'components/shared/card';
import { FunctionComponent, useState } from 'react';
import { PriceService } from 'services/price';
import { TransportationTrip } from 'types';
import { toggleFromObjArray } from 'utils';

interface TransportationCardProps {
  transportation: { id?: string | undefined; qty?: string | number | undefined; trip?: TransportationTrip }[] | null;
  transportationfetched: any;
  transportationYes: boolean;
  i: number;
  groupSize?: number;
  tripLength?: string;
  setTransportationPopupID: React.Dispatch<React.SetStateAction<string>>;
  showPopup?: boolean;
  transportationPopupID: string;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setTransportation: React.Dispatch<
    React.SetStateAction<
      | {
          id?: string | undefined;
          qty?: string | number | undefined;
          trip?: TransportationTrip;
        }[]
      | null
    >
  >;
}

const TransportationCard: FunctionComponent<TransportationCardProps> = ({
  transportation,
  transportationfetched,
  transportationYes,
  groupSize,
  tripLength,
  i,
  setTransportationPopupID,
  showPopup,
  transportationPopupID,
  setShowPopup,
  setTransportation,
  ...props
}) => {
  const selected = transportation?.map((x) => x.id)?.includes(transportationfetched?.id);
  const thisTransportation = transportation?.find((x) => x.id == transportationfetched?.id);
  const qty = thisTransportation?.qty;
  const initialTrip = thisTransportation?.trip || 'one-way';
  const [trip, setTrip] = useState<TransportationTrip>(initialTrip);
  const price =
    groupSize &&
    tripLength &&
    PriceService.transportation(
      {
        price: transportationfetched.price,
        groupSize,
        tripLength,
        activityType: transportationfetched.activityType,
        trip,
        isThisAnAirportTransfer: transportationfetched.isThisAnAirportTransfer,
      },
      { labeled: true, rounded: true, usd: true, noCeroCero: true }
    );
  const cardProps = {
    isThisAnAirportTransfer: transportationfetched?.isThisAnAirportTransfer,
    withTransportationPopup: {
      showPopup,
      transportationPopupID,
      transportationfetched,
      setShowPopup,
      setTransportation,
    },
    image: transportationfetched?.cardImage?.responsiveImage,
    title: transportationfetched?.name,
    iconsNew: [
      { text1: transportationfetched?.passengers, text2: 'Passengers' },
      // { text1: transportationfetched?.bags, text2: 'Bags' },
    ],
    paragraph: transportationfetched?.shortDescription,
    withQty: true,
    initialQty: qty || 1,
    button1: {
      text: 'LEARN MORE',
      onClick: () => {
        setTransportationPopupID(transportationfetched?.id);
        setShowPopup(true);
      },
    },
    button2: {
      text: selected ? 'Remove' : 'Select',
      onClick: (qty: number, trip?: TransportationTrip) => {
        setTransportation((prev: any) => {
          return toggleFromObjArray(prev, { ...transportationfetched, qty, trip });
        });
      },
    },
    featured: transportationfetched?.featured,
    selected,
    price,
    trip,
    setTrip,
  };

  if (transportationYes) {
    return (
      <CardWrapper key={transportationfetched?.id}>
        <Card {...cardProps} />
      </CardWrapper>
    );
  } else {
    if (i < 3) {
      return (
        <CardWrapper key={transportationfetched?.id}>
          <Card {...cardProps} />
        </CardWrapper>
      );
    } else {
      return null;
    }
  }
};

export default TransportationCard;
