import styled from 'styled-components';
import { md, lg } from 'styles/themes';

export const Wrapper = styled.div`
  top: 55px;
  position: sticky;
`;

export const Estimate = styled.div`
  padding: 7px 13px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.bgDark};
  margin-top: auto;
  margin-top: 35px;
`;

export const Collapse = styled.button<{ rotate?: boolean }>`
  outline: none;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  top: 62px;
  left: -25px;
  width: 22px;
  height: 22px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #f0f1f2;
  opacity: 1;
  transform: rotate(${({ rotate }) => (rotate ? '0' : '180deg')});
`;
