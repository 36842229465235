import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Skeleton } from '@material-ui/lab';

export const StyledSkeleton = styled(Skeleton)`
  transform: scale(1, 1);
  margin-bottom: 24px;
  width: 159px;

  @media ${md} {
    width: 164px;
  }
  @media ${lg} {
    width: 216px;
  }
`;
export const Cards = styled.div`
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  animation: fadein forwards 1s;
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;
