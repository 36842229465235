import styled from 'styled-components';
import { md, lg } from 'styles/themes';

// export const Container = styled(MaterialContainer)`
//   min-height: 86vh;
//   @include media(md) {
//     min-height: 100vh;
//   }
// `;

export const Stepper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;
  @media ${md} {
    justify-content: center;
    width: 50px;
    margin-top: 44px;
    flex-direction: column;
  }
  @media ${lg} {
  }
`;

export const Step = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${md} {
    flex-direction: column;
  }
  @media ${lg} {
  }
`;

export const OutterCirlce = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 0;
  transition: 0.3s all;
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const InnerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s all;
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const Label = styled.div`
  cursor: pointer;
  & :hover.MessageDiv {
    display: block;
  }
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const Connector = styled.div`
  height: 2px;
  background-color: #bac3c9;
  @media ${md} {
    height: 83px;
    width: 2px;
  }
  @media ${lg} {
  }
`;

export const ConnectorAnimation = styled.div`
  @media ${md} {
    transition: 0.3s height width;
  }
  @media ${lg} {
  }
`;

export const MessageDiv = styled.div`
  width: 75px;
  height: 20px;
  background-color: #353743;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  font-size: 8px;
  line-height: 8px;
  padding-right: 4px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: 2px;
  border-left: 8px solid #353743;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transform: rotate(-90deg);
`;

export const CustomTooltip = styled.div`
  position: absolute;
  bottom: -32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
