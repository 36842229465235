import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { groupState, selectGroup, selectGroupId } from 'features/group-data-collection/groupSlice';
import confetti from 'canvas-confetti';
import { computePersDataWithQty, computeTotalPrice, computeTranspDataWithQty, toUSD, toUSDNoDecimal } from 'utils';
import {
  SUMMARY_HOUSING_QUERY_DATA,
  SUMMARY_HOUSING_QUERY,
  SUMMARY_TRANSPORTATION_QUERY_DATA,
  SUMMARY_TRANSPORTATION_QUERY,
  SUMMARY_DAYTIMEACTIVITIES_QUERY_DATA,
  SUMMARY_DAYTIMEACTIVITIES_QUERY,
  SUMMARY_NIGHTTIMEACTIVITIES_QUERY_DATA,
  SUMMARY_NIGHTTIMEACTIVITIES_QUERY,
  SUMMARY_PERSONALSERVICES_QUERY_DATA,
  SUMMARY_PERSONALSERVICES_QUERY,
  GROUP_DATA,
} from 'app/queries';
import { useQuery } from '@apollo/client';
import { PriceService } from 'services/price';
import api, { GetPrices } from 'api';
import {
  getPrices,
  selectPrices,
  selectPricesData,
  selectPricesStatus,
} from 'features/group-data-collection/pricesSlice';
import { CircularProgress } from '@material-ui/core';
import { ExpGroupData } from 'features/experience-page/expGroupSlice';
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useMustHaveGroupId = () => {
  const history = useHistory();
  const _id = useAppSelector(selectGroupId);
  console.log(_id, '_id');
  useEffect(() => {
    if (!_id) {
      history.replace('/');
    }
  }, [history, _id]);
};

export const useCanvasConfetti = ({
  duration = 2 * 1000,
  startVelocity = 30,
  spread = 360,
  ticks = 30,
  zIndex = 0,
  particleCount = 100,
}) => {
  /**FROM https://www.kirilv.com/canvas-confetti/ */
  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }
  if (!document.querySelector('#confetti')) {
    var myCanvas = document.createElement('canvas');
    myCanvas.id = 'confetti';
    myCanvas.style.position = 'absolute';
    myCanvas.style.top = '0';
    myCanvas.style.left = '0';
    myCanvas.style.width = '100%';
    myCanvas.style.height = '100%';
    document.querySelector('#confetti-container')?.appendChild(myCanvas);

    var myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true,
    });
    myConfetti({
      particleCount,
      spread,
      // any other options from the global
      // confetti function
    });
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity, spread, ticks, zIndex };

    var interval: NodeJS.Timeout = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
    /**END FROM */
  }
};

export const usePrices = () => {
  const dispatch = useAppDispatch();
  const { group } = useAppSelector(selectGroup);
  useEffect(() => {
    dispatch(getPrices({ _id: `${group._id}` }));
  }, [group, dispatch]);
  const prices = useAppSelector(selectPricesData);
  const pricesStatus = useAppSelector(selectPricesStatus);
  const loadingPrices = pricesStatus === 'loading';
  const renderPrice = (price: number) => (loadingPrices ? <CircularProgress size={22} /> : toUSDNoDecimal(price));
  return { ...prices, loadingPrices, renderPrice };
};

export const usePricesExperience = (_id?: string) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getPrices({ _id: `${_id}` }));
  }, [_id]);
  const prices = useAppSelector(selectPricesData);
  const pricesStatus = useAppSelector(selectPricesStatus);
  const loadingPrices = pricesStatus === 'loading';
  const renderPrice = (price: number) => (loadingPrices ? <CircularProgress size={22} /> : toUSDNoDecimal(price));
  return { ...prices, loadingPrices, renderPrice };
};

export const useSummary = () => {
  const { group } = useAppSelector(selectGroup);

  const { data: housingData } = useQuery<SUMMARY_HOUSING_QUERY_DATA>(SUMMARY_HOUSING_QUERY, {
    variables: {
      id: group.housing,
    },
  });

  const { data: transportationData } = useQuery<SUMMARY_TRANSPORTATION_QUERY_DATA>(SUMMARY_TRANSPORTATION_QUERY, {
    variables: {
      ids: group?.transportation?.map(({ id }) => id),
    },
    skip: !group.transportation,
  });

  const { data: daytimeActivitiesData } = useQuery<SUMMARY_DAYTIMEACTIVITIES_QUERY_DATA>(
    SUMMARY_DAYTIMEACTIVITIES_QUERY,
    {
      variables: {
        ids: group.daytimeActivities,
      },
      skip: !group.daytimeActivities,
    }
  );

  const { data: nighttimeActivitiesData } = useQuery<SUMMARY_NIGHTTIMEACTIVITIES_QUERY_DATA>(
    SUMMARY_NIGHTTIMEACTIVITIES_QUERY,
    {
      variables: {
        ids: group.nighttimeActivities,
      },
      skip: !group.nighttimeActivities,
    }
  );

  const { data: personalServicesData } = useQuery<SUMMARY_PERSONALSERVICES_QUERY_DATA>(SUMMARY_PERSONALSERVICES_QUERY, {
    variables: {
      ids: group.personalServices?.map(({ id }) => id),
    },
    skip: !group.personalServices,
  });

  return {
    housingData,
    transportationData: transportationData?.allTransportations?.map(({ name, id, ...other }) => {
      const tr = group.transportation?.find((transp) => transp.id == id);
      return {
        ...tr,
        ...other,
        name,
      };
    }),
    daytimeActivitiesData,
    nighttimeActivitiesData,
    personalServicesData: personalServicesData?.allPersonalServices?.map(({ name, id, ...other }) => {
      const ps = group.personalServices?.find((transp) => transp.id == id);
      return {
        ...ps,
        ...other,
        name,
      };
    }),
    group,
  };
};
