import styled from 'styled-components';
import { md } from 'styles/themes';

export const Flex = styled.div`
  max-width: 320px;
  @media ${md} {
    display: flex;
    justify-content: space-between;
  }
`;

export const DateWrapper = styled.div`
  @media ${md} {
    width: 48%;
  }
  .MuiFormControl-root {
    height: auto;
  }
`;
