import { gql, gql as graphql } from '@apollo/client';
import { ResponsiveImageType } from 'react-datocms';
import { ActivityType, TransportationTrip } from 'types';

export const imageFragment = `responsiveImage(
  imgixParams: { fit: clamp, w: 1, h: 1, auto: format }
) {
  # HTML5 src/srcset/sizes attributes
  srcSet
  webpSrcSet
  sizes
  src
  # size information (post-transformations)
  width
  height
  aspectRatio
  # SEO attributes
  alt
  title
  # background color placeholder or...
  bgColor
  # blur-up placeholder, JPEG format, base64-encoded
  base64
}`;

export const LEAD_CAPTURING_CONFIG_QUERY = graphql`
{
    leadCapturingConfig {
    reasonsForTravel
    showcaseImages {
    ${imageFragment}
    }
    step1Heading
    step2Heading
    step3Heading
    step4Heading
    step4SubHeading
    step4Paragraph
    step4Button1Text
    step4Button2Text
    budgets {
      id
      name
      budgetRange
    }
    scheduleHeading
    scheduleParagraph
    scheduledCallHeading
    scheduledParagraph
  }
}
`;

export const BUILDER_WIZARD_CONFIG_QUERY = graphql`
{
  builderWizardConfig {
      showcaseImagesHousing {
        ${imageFragment}
      }
      showcaseImagesTransportation {
        ${imageFragment}
      }
      showcaseImagesDaytimeActivity {
        ${imageFragment}
      }
      showcaseImagesNighttimeActivity {
        ${imageFragment}
      }
      showcaseImagesPersonalService {
        ${imageFragment}
      }
  }
}
`;

export const DESTINATIONS_QUERY = graphql`
  {
    allDestinations(filter: { available: { eq: true } }) {
      place
      id
      country
      appointmentsOnly
    }
  }
`;

export type BUILDER_HOUSING_QUERY_DATA = {
  allHousings: Array<{
    locationCoordinates?: {
      latitude?: number;
      longitude?: number;
    };
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    bathrooms: number;
    sleeps: number;
    priceLow: number;
    priceHigh: number;
    priceForHoliday: number;
    name: string;
    shortDescription: string;
  }>;
};

export const BUILDER_HOUSING_QUERY = graphql`
  query housingForGroup($destination: ItemId!, $groupSize: IntType!, $minimumNightsRental: IntType!) {
    allHousings(
      orderBy: featured_DESC,
      filter: {
        destination: { eq: $destination }
        groupSizeMax: { gte: $groupSize }
        groupSizeMin: { lte: $groupSize }
        minimumNightsRental: { lte: $minimumNightsRental }
      },
    ) {
      id
      locationCoordinates {
        latitude
        longitude
      }
      available
      groupSizeMax
      groupSizeMin
      featured
      destination {
        available
        id
        seasonalPricing
        peakSeasonEnds
        peakSeasonStarts
        highSeasonStarts
        highSeasonEnds
      }
      cardImage {
          ${imageFragment}
      }
      bathrooms
      sleeps
      bedrooms
      beds
      priceLow
      priceHigh
      priceForHoliday
      name
      shortDescription
    }
  }
`;

export type BUILDER_SINGLE_TRANSPORTATION_QUERY_DATA = {
  allTransportations: Array<{
    id: string;
    passengers?: number;
    bags?: number;
    shortDescription?: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      country: string;
      place: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    showcaseImages: {
      responsiveImage: ResponsiveImageType;
    }[];
    price: number;
    duration: number;
    name: string;
    description: string;
  }>;
};

export const BUILDER_SINGLE_TRANSPORTATION_QUERY = graphql`
  query transportationForGroup($id: ItemId!) {
    allTransportations(filter: { id: { eq: $id } }) {
      id
      passengers
      bags
      shortDescription
      name
      price
      available
      groupSizeMax
      groupSizeMin
      description
      featured
      duration
      destination {
        available
        id
        place
        country
        seasonalPricing
        peakSeasonEnds
        peakSeasonStarts
        highSeasonStarts
        highSeasonEnds
      }
      showcaseImages {
        ${imageFragment}
      }
      cardImage {
        ${imageFragment}
      }
    }
  }
`;

export interface TRANSPORTATION_DATA {
  id: string;
  passengers?: number;
  bags?: number;
  shortDescription?: string;
  available: boolean;
  groupSizeMax: number;
  groupSizeMin: number;
  featured: boolean;
  destination: {
    available: boolean;
    id: string;
    country: string;
    place: string;
    seasonalPricing: boolean;
    peakSeasonEnds: string | null;
    peakSeasonStarts: string | null;
    highSeasonStarts: string | null;
    highSeasonEnds: string | null;
  };
  cardImage: {
    responsiveImage: ResponsiveImageType;
  };
  showcaseImages: {
    responsiveImage: ResponsiveImageType;
  }[];
  price: number;
  duration: number;
  name: string;
  description: string;
  activityType: ActivityType;
}

export type BUILDER_TRANSPORTATION_QUERY_DATA = {
  allTransportations: Array<TRANSPORTATION_DATA>;
};

export const BUILDER_TRANSPORTATION_QUERY = graphql`
  query transportationForGroup($destination: ItemId!, $groupSize: IntType!) {
    allTransportations(
      filter: {
        destination: { eq: $destination }
        groupSizeMax: { gte: $groupSize }
        groupSizeMin: { lte: $groupSize }
      }
    ) {
      id
      passengers
      bags
      isThisAnAirportTransfer
      shortDescription
      name
      price
      available
      activityType
      groupSizeMax
      groupSizeMin
      description
      duration
      featured
      destination {
        available
        id
        place
        country
        seasonalPricing
        peakSeasonEnds
        peakSeasonStarts
        highSeasonStarts
        highSeasonEnds
      }
      showcaseImages {
        ${imageFragment}
      }
      cardImage {
        ${imageFragment}
      }
    }
  }
`;

export type SUMMARY_TRANSPORTATION_QUERY_DATA = {
  allTransportations?: Array<{
    id: string;
    price: number;
    name: string;
    groupSizeMax: number;
    passengers?: number;
    bags?: number;
    qty?: string | number;
    trip?: TransportationTrip;
    isThisAnAirportTransfer?: boolean;
    activityType: ActivityType;
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
  }>;
};

export const SUMMARY_TRANSPORTATION_QUERY = graphql`
  query transportationForGroup($ids: [ItemId!]) {
    allTransportations(
      filter: {
      id: { in: $ids }
    }
    ) {
      id
      passengers
      bags
      name
      price
      groupSizeMax
      isThisAnAirportTransfer
      activityType
      cardImage {
        ${imageFragment}
      }
    }
  }
`;

export type BUILDER_SINGLE_HOUSING_QUERY_DATA = {
  allHousings: Array<{
    id: string;
    locationCoordinates: {
      latitude: number;
      longitude: number;
    };
    destination: {
      id: string;
      place: string;
      country: string;
    };
    showcaseImages: {
      responsiveImage: ResponsiveImageType;
    }[];
    reviews: {
      name: string;
      review: string;
      date: string;
    }[];
    bathrooms: number;
    bedrooms: number;
    beds: number;
    locationCaption?: string;
    groupSizeMax: number;
    name: string;
    longDescription: string;
    shortDescription: string;
    locationDetails: string;
    thingsToKnow: {
      icon: string;
      text: string;
    }[];
  }>;
};

export const BUILDER_SINGLE_HOUSING_QUERY = graphql`
  query housingForGroup($id: ItemId!) {
    allHousings(filter: { id: { eq: $id } }) {
      locationCoordinates {
        latitude
        longitude
      }
      thingsToKnow {
      icon
      text
    }
    destination {
      place
      id
      country
    }
    longDescription
    shortDescription
    id
    featured
    locationCaption
    locationDetails
    bedrooms
    beds
    bathrooms
    groupSizeMax
    name
    reviews {
      name
      review
      date
    }
    showcaseImages {
      ${imageFragment}
    }
    }
  }`;

export type SUMMARY_HOUSING_QUERY_DATA = {
  allHousings: Array<{
    id: string;
    locationCoordinates?: {
      latitude?: number;
      longitude?: number;
    };
    bathrooms: number;
    bedrooms: number;
    groupSizeMax: number;
    name: string;
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    destination: {
      peakSeasonEnds?: string;
      peakSeasonStarts?: string;
      highSeasonStarts?: string;
      highSeasonEnds?: string;
      seasonalPricing: boolean;
    };
    priceHigh?: number;
    priceLow?: number;
    priceForHoliday?: number;
  }>;
};

export const SUMMARY_HOUSING_QUERY = graphql`
  query housingForGroup($id: ItemId!) {
    allHousings(filter: { id: { eq: $id } }) {
      id
      locationCoordinates {
        latitude
        longitude
    }
      name
      bedrooms
      bathrooms
      groupSizeMax
      destination {
        peakSeasonEnds
        peakSeasonStarts
        highSeasonStarts
        highSeasonEnds
        seasonalPricing
      }
      priceHigh
      priceLow
      priceForHoliday
      cardImage{
        ${imageFragment}
      }
    }
  }
`;

export type BUILDER_SINGLE_DAYTIMEACTIVITIES_QUERY_DATA = {
  allDaytimeActivities: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    activityType: 'group' | 'individual';
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    price: number;
    name: string;
    description: string;
    showcaseImages: {
      responsiveImage: ResponsiveImageType;
    }[];
  }>;
};

export const BUILDER_SINGLE_DAYTIMEACTIVITIES_QUERY = graphql`
query activitiesForGroup($id: ItemId!) {
  allDaytimeActivities(
    filter: {
      id: { eq: $id }
    }
  ) {
    id
    name
    price
    available
    groupSizeMax
    groupSizeMin
    description
    featured
    activityType
    destination {
      available
      id
      seasonalPricing
      peakSeasonEnds
      peakSeasonStarts
      highSeasonStarts
      highSeasonEnds
    }
    cardImage {
      ${imageFragment}
    }
    showcaseImages {
      ${imageFragment}
    }
  }
}
`;

export type BUILDER_DAYTIMEACTIVITIES_QUERY_DATA = {
  allDaytimeActivities: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    activityType: 'group' | 'individual';
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    price: number;
    name: string;
    shortDescription: string;
  }>;
};

export const BUILDER_DAYTIMEACTIVITIES_QUERY = graphql`
query activitiesForGroup(
  $destination: ItemId!,
  $groupSize: IntType! ,
  # $activityType: String!
) {
  allDaytimeActivities(
    filter: {
      destination: { eq: $destination }
      groupSizeMax: { gte: $groupSize }
      groupSizeMin: { lte: $groupSize }
      # activityType: { eq: $activityType }
    },
    first: "100" #technical debt
  ) {
    id
    name
    price
    available
    groupSizeMax
    groupSizeMin
    featured
    shortDescription
    activityType
    destination {
      available
      id
      seasonalPricing
      peakSeasonEnds
      peakSeasonStarts
      highSeasonStarts
      highSeasonEnds
    }
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export type SUMMARY_DAYTIMEACTIVITIES_QUERY_DATA = {
  allDaytimeActivities: Array<{
    id: string;
    name: string;
    price: number;
    groupSizeMax: number;
    activityType: 'group' | 'individual';
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
  }>;
};

export const SUMMARY_DAYTIMEACTIVITIES_QUERY = graphql`
query activitiesForGroup($ids: [ItemId!]) {
  allDaytimeActivities(
    filter: {
      id: { in: $ids }
    }
  ) {
    id
    name
    price
    groupSizeMax
    activityType
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export type BUILDER_NIGHTTIMEACTIVITIES_QUERY_DATA = {
  allNighttimeActivities: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    activityType: 'group' | 'individual';
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    price: number;
    name: string;
    shortDescription: string;
  }>;
};

export const BUILDER_NIGHTTIMEACTIVITIES_QUERY = graphql`
query nighttymeActivitiesForGroup(
  $destination: ItemId!,
  $groupSize: IntType! ,
  # $activityType: String!
) {
  allNighttimeActivities(
    filter: {
      destination: { eq: $destination }
      groupSizeMax: { gte: $groupSize }
      groupSizeMin: { lte: $groupSize }
      # activityType: { eq: $activityType }
    },
    first: "100" #technical debt
  ) {
    id
    name
    price
    available
    groupSizeMax
    featured
    groupSizeMin
    shortDescription
    activityType
    destination {
      available
      id
      seasonalPricing
      peakSeasonEnds
      peakSeasonStarts
      highSeasonStarts
      highSeasonEnds
    }
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export type SUMMARY_NIGHTTIMEACTIVITIES_QUERY_DATA = {
  allNighttimeActivities: Array<{
    id: string;
    price: number;
    name: string;
    groupSizeMax: number;
    activityType: 'group' | 'individual';
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
  }>;
};

export const SUMMARY_NIGHTTIMEACTIVITIES_QUERY = graphql`
query nighttymeActivitiesForGroup($ids: [ItemId!]) {
  allNighttimeActivities(
    filter: {
      id: { in: $ids }
    }
  ) {
    id
    name
    price
    groupSizeMax
    activityType
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export type BUILDER_SINGLE_PERSONALSERVICES_QUERY_DATA = {
  allPersonalServices: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    featured: boolean;
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    showcaseImages: {
      responsiveImage: ResponsiveImageType;
    }[];
    price: number;
    name: string;
    description: string;
  }>;
};

export const BUILDER_SINGLE_PERSONALSERVICES_QUERY = graphql`
query personalServicesForGroup($id: ItemId!) {
  allPersonalServices(
    filter: {
      id: { eq: $id }
    }
  ) {
    id
    name
    price
    available
    groupSizeMax
    groupSizeMin
    featured
    description
    destination {
      available
      id
      seasonalPricing
      peakSeasonEnds
      peakSeasonStarts
      highSeasonStarts
      highSeasonEnds
    }
    cardImage {
      ${imageFragment}
    }
    showcaseImages {
      ${imageFragment}
    }
  }
}
`;

export type BUILDER_PERSONALSERVICES_QUERY_DATA = {
  allPersonalServices: Array<{
    id: string;
    available: boolean;
    groupSizeMax: number;
    groupSizeMin: number;
    featured: boolean;
    reach: 'one-off' | 'trip';
    destination: {
      available: boolean;
      id: string;
      seasonalPricing: boolean;
      peakSeasonEnds: string | null;
      peakSeasonStarts: string | null;
      highSeasonStarts: string | null;
      highSeasonEnds: string | null;
    };
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
    price: number;
    activityType: 'group' | 'individual';
    name: string;
    shortDescription: string;
  }>;
};

export const BUILDER_PERSONALSERVICES_QUERY = graphql`
query personalServicesForGroup($destination: ItemId!, $groupSize: IntType!) {
  allPersonalServices(
    filter: {
      destination: { eq: $destination }
      groupSizeMax: { gte: $groupSize }
      groupSizeMin: { lte: $groupSize }
    },
    first: "100" #technical debt
  ) {
    id
    name
    price
    available
    groupSizeMax
    featured
    groupSizeMin
    shortDescription
    activityType
    reach
    destination {
      available
      id
      seasonalPricing
      peakSeasonEnds
      peakSeasonStarts
      highSeasonStarts
      highSeasonEnds
    }
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export type SUMMARY_PERSONALSERVICES_QUERY_DATA = {
  allPersonalServices?: Array<{
    id: string;
    price: number;
    name: string;
    groupSizeMax: number;
    qty: number;
    reach: 'one-off' | 'trip';
    activityType: 'group' | 'individual';
    cardImage: {
      responsiveImage: ResponsiveImageType;
    };
  }>;
};

export const SUMMARY_PERSONALSERVICES_QUERY = graphql`
query personalServicesForGroup($ids: [ItemId!]) {
  allPersonalServices(
    filter: {
      id: { in: $ids }
    }
  ) {
    id
    name
    price
    groupSizeMax
    activityType
    reach
    cardImage {
      ${imageFragment}
    }
  }
}
`;

export type GROUP_DATA = {
  _id: string;
  bido_id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  payees: number | string;
  destination: {
    id: string;
    country: string;
    place: string;
  };
  housing: {
    id: string;
    locationCoordinates?: {
      latitude?: number;
      longitude?: number;
    };
    name: string;
    priceForHoliday: string;
    priceHigh: string;
    priceLow: string;
  };
  transportation: {
    id: string;
    name: string;
    price: string;
    qty?: number;
    trip?: TransportationTrip;
  }[];
  daytimeActivities: {
    id: string;
    name: string;
    price: string;
  }[]; //
  nighttimeActivities: {
    id: string;
    name: string;
    price: string;
  }[];
  personalServices: {
    id: string;
    name: string;
    price: string;
  }[];
  fromDate: string;
  untilDate: string;
  tripLength: string;
  travelReason: string;
  groupSize: number;
  budget: string;
  contactMethod: string;
  status: string;
  approval_status: string;
  archived: string;
  notes: string;
  checklist: {
    name: string;
    status: string;
  };
  members:
    | null
    | {
        name: string;
        email: string;
        phone: string;
      }[];
};

export const GROUP_QUERY = gql`
  query groupById($id: ObjectId!) {
    group(query: { _id: $id }) {
      _id
      payees
      bido_id
      firstName
      lastName
      email
      phone
      destination {
        id
        country
        place
      }
      housing {
        id
        name
        priceForHoliday
        priceHigh
        priceLow
      }
      transportation {
        id
        name
        price
        qty
        trip
      }
      daytimeActivities {
        id
        name
        price
      }
      nighttimeActivities {
        id
        name
        price
      }
      personalServices {
        id
        name
        price
        qty
      }
      fromDate
      untilDate
      tripLength
      travelReason
      groupSize
      budget
      contactMethod
      status
      approval_status
      archived
      notes
      checklist {
        name
        status
      }
      members {
        name
        phone
        email
      }
    }
  }
`;
