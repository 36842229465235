import styled from 'styled-components';

export const Label = styled.label<{ fullWidth?: boolean }>`
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '300px')};
  width: 100%;
  display: block;
  position: relative;
`;

export const LabelText = styled.span<{ fullWidth?: boolean }>`
  color: ${({ theme }) => theme.colors.bgDarker};
  font-size: 12px;
  margin-bottom: 7px;
  text-transform: uppercase;
  font-weight: 700;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;
