import styled from 'styled-components';
import { Image as DatoCMSImage } from 'react-datocms';
import { styledTheme } from 'styles/themes';

export const Wrapper = styled.div<{ selected?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ selected }) => (selected ? '0px 5px 20px #0000003B' : '0px 5px 20px #00000012')};
  border-radius: 12px;
  position: relative;
  transition: 0.3s;
  border: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primary : 'transparent')};
  svg {
    width: 13px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 125px;
  margin-bottom: 6px;
`;

export const Image = styled(DatoCMSImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
`;

export const Typography = styled.div`
  font-size: 14px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: solid 1px #eaeaea;
  border-left: none;
  border-right: none;
  height: 45px;
  width: 100%;
  margin-bottom: 6.5px;
  justify-content: space-around;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //  width: 33.3%;
  //padding: 3px;
  justify-content: center;
`;

export const ImageIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-right: 2px;
`;

export const ButtonsCardWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const TextWrapper = styled.div`
  padding: 0 11px 11px 11px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const Select = styled.select`
  width: 50px;
  border-radius: 5px;
  border-color: ${styledTheme.colors.bgLight};
  margin-left: 5px;
  height: 30px;
  cursor: pointer;
`;

export const FeaturedDiv = styled.div`
  width: 68px;
  height: 25px;
  background-color: #1ecc7a;
  border: 1px solid #ffffff;
  border-radius: 6px;
  color: #ffffff;
  font-size: 10px;
  line-height: 10px;
  padding-left: 6px;
  padding-top: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
  box-shadow: 0px 2px 7px #00000090;
  display: flex;
  justify-content: center;
  align-items: center;
`;
