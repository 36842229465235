import React, { FunctionComponent, useEffect } from 'react';
import {
  Container,
  ProgressTabletAndAbove,
  Wrapper,
  FormArea,
  ImageArea,
  ImageWrapper,
  Logo,
  SummaryAreaTabletAndAbove,
  SummaryAreaMobile,
  ProgressMobile,
} from './styled';
import Check from '@material-ui/icons/Check';
import useWindowWidth from 'hooks/useWindowWidth';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Route, Switch } from 'react-router-dom';
import Summary from './summary';
import Stepper from 'features/lead-capturing/stepper-custom';
import { Form, ButtonsWrapper } from 'components/shared';
import { useQuery } from '@apollo/client';
import { LEAD_CAPTURING_CONFIG_QUERY } from 'app/queries';
import { IStep } from 'features/lead-capturing';
import HousingIcon from 'components/shared/icons/housing';
import TransportationIcon from 'components/shared/icons/transportation';
import DaytimeActivitiesIcon from 'components/shared/icons/activities-daytime';
import NighttimeActivitiesIcon from 'components/shared/icons/activities-nighttime';
import PersonalServicesIcon from 'components/shared/icons/personal-services';
import { submitLastSeenAtStep } from 'features/group-data-collection/groupSlice';
import { useAppDispatch } from 'app/hooks';

export interface ISummaryStage {}

const useIconsStyles = makeStyles((theme: any) =>
  createStyles({
    housing: {
      width: 16,
    },
    transportation: {
      width: 16,
    },
    daytime: {
      width: 16,
    },
    nighttime: {
      width: 16,
    },
    personal: {
      width: 16,
    },
    check: {
      width: 18,
    },
  })
);

const useIconsStylesMobile = makeStyles((theme: any) =>
  createStyles({
    housing: {
      width: 14,
    },
    transportation: {
      width: 14,
    },
    daytime: {
      width: 14,
    },
    nighttime: {
      width: 14,
    },
    personal: {
      width: 14,
    },
    check: {
      width: 14,
    },
  })
);

const SummaryStage: FunctionComponent<ISummaryStage> = ({ ...props }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { windowWidth } = useWindowWidth();
  const isMobile = windowWidth < 769;
  const iconsClasses = useIconsStyles();
  const iconsClassesMobile = useIconsStylesMobile();
  const steps: IStep[] = [
    {
      link: '/builder',
      icon: <HousingIcon className={isMobile ? iconsClassesMobile.housing : iconsClasses.housing}></HousingIcon>,
    },
    {
      link: '/builder/transportation',
      icon: (
        <TransportationIcon
          className={isMobile ? iconsClassesMobile.transportation : iconsClasses.transportation}
        ></TransportationIcon>
      ),
    },
    {
      link: '/builder/daytime-activities',
      icon: (
        <DaytimeActivitiesIcon
          className={isMobile ? iconsClassesMobile.daytime : iconsClasses.daytime}
        ></DaytimeActivitiesIcon>
      ),
    },
    // {//temp-no-night
    //   link: '/builder/nighttime-activities',
    //   icon: (
    //     <NighttimeActivitiesIcon
    //       className={isMobile ? iconsClassesMobile.nighttime : iconsClasses.nighttime}
    //     ></NighttimeActivitiesIcon>
    //   ),
    // },
    {
      link: '/builder/personal-services',
      icon: (
        <PersonalServicesIcon
          className={isMobile ? iconsClassesMobile.personal : iconsClasses.personal}
        ></PersonalServicesIcon>
      ),
    },
    {
      link: '/builder/summary',
      icon: <Check className={isMobile ? iconsClassesMobile.check : iconsClasses.check}></Check>,
    },
  ];
  const { data } = useQuery(LEAD_CAPTURING_CONFIG_QUERY);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // we're insterested in knowing that the user got to schedule because there's nothing to submit on this step
    dispatch(submitLastSeenAtStep({ at: 'summary' }));
  }, [dispatch]);
  return (
    <>
      <ProgressMobile>
        <Logo src="/svg/bido-logo.svg" alt="BIDO logo" />
        <Stepper connectorWidthMobile={6} activeStep={activeStep} steps={steps} />
      </ProgressMobile>
      <Container fixed>
        <Wrapper>
          <ProgressTabletAndAbove>
            <Logo src="/svg/bido-logo.svg" alt="BIDO logo" />
            <Stepper activeStep={activeStep} steps={steps} />
          </ProgressTabletAndAbove>
          <FormArea>
            <Switch>
              <Route path="/">
                <Summary setActiveStep={setActiveStep} />
              </Route>
            </Switch>
          </FormArea>
        </Wrapper>
      </Container>
    </>
  );
};

export default SummaryStage;
