import { FunctionComponent, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Root,
  Popup,
  Image,
  ImageWrapper,
  InfoWrapper,
  ImageWrapper2,
  ImageWrapper3,
  Col1,
  Col2,
  Overlay,
  DetailsWrapper,
  Review,
  Row1Review,
  Row,
  IconNumFlex,
  ButtonsWrapper,
  ImagesPopup,
} from './styled';
import MapZone from 'components/shared/map-zone';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { useQuery } from '@apollo/client';
import { BUILDER_SINGLE_HOUSING_QUERY, BUILDER_SINGLE_HOUSING_QUERY_DATA } from 'app/queries';
import { Button, Typography } from '@material-ui/core';
import Bedrooms from 'components/shared/icons/bedrooms';
import Beds from 'components/shared/icons/beds';
import Baths from 'components/shared/icons/baths';
import People from 'components/shared/icons/people';
import ButtonImages from 'components/shared/icons/button-images';
import BackButton from '../back-button';

interface IPropertyPopup {
  id: string;
  props?: any;
  setHousing: React.Dispatch<React.SetStateAction<string | null>>;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}
SwiperCore.use([Pagination, Navigation]);

const PropertyPopup: FunctionComponent<IPropertyPopup> = ({ setShowPopup, setHousing, id, ...props }) => {
  const { data, loading: loadingHousing } = useQuery<BUILDER_SINGLE_HOUSING_QUERY_DATA>(BUILDER_SINGLE_HOUSING_QUERY, {
    variables: {
      id: id,
    },
  });
  const params: Swiper = {
    slidesPerView: 1,
    preloadImages: false,
    autoplay: true,
    loop: true,
    navigation: true,
    pagination: {
      clickable: true,
      el: '.swiper-pagination-hero',
    },
  };
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const housing = data?.allHousings[0];
  const images = housing?.showcaseImages;
  const [openImages, setOpenImages] = useState(false);
  const [mainPopup, setMainPopup] = useState(true);
  return (
    <Root>
      <Overlay onClick={() => setShowPopup(false)} />
      {!openImages && mainPopup && (
        <Popup>
          <p
            onClick={() => setShowPopup(false)}
            style={{
              position: 'absolute',
              top: '8.5px',
              right: '8.5px',
              zIndex: 100,
              margin: '0',
              fontSize: '20px',
              lineHeight: '14px',
              color: '#353743',
              cursor: 'pointer',
            }}
          >
            x
          </p>
          {!loadingHousing && (
            <div>
              <Typography style={{ marginBottom: '20px' }} variant="h5" component="h5">
                {housing?.name}
              </Typography>
              <div className="swiperWrapper">
                <Swiper {...params}>
                  {data?.allHousings[0].showcaseImages.map(({ responsiveImage }, i) => {
                    return (
                      <SwiperSlide>
                        <ImageWrapper>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper>
                      </SwiperSlide>
                    );
                  })}
                  {/* <div className="swiper-pagination-hero"></div> */}
                </Swiper>
              </div>
              <div className="images">
                <div style={{ width: '49.5%' }}>
                  {images?.[0]?.responsiveImage && (
                    <ImageWrapper>
                      <Image data={images[0].responsiveImage}></Image>
                    </ImageWrapper>
                  )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '49.5%' }}>
                  {images &&
                    images.map(({ responsiveImage }, i) => {
                      if (i === 0 || i > 4) return null;
                      return <ImageWrapper2>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper2>;
                    })}
                </div>
                <ButtonImages
                  onClick={() => {
                    setOpenImages(true);
                    setMainPopup(false);
                  }}
                  style={{ cursor: 'pointer', position: 'absolute', bottom: '10px', right: '10px' }}
                />
              </div>
              <InfoWrapper>
                <Col1>
                  <Typography style={{ marginBottom: '13px' }} variant="h5" component="h5">
                    Description
                  </Typography>
                  <Typography
                    variant="caption"
                    dangerouslySetInnerHTML={{ __html: housing?.longDescription || '' }}
                    component="p"
                  ></Typography>
                  <div style={{ width: '100%', margin: '20px 0 30px', height: '1px', backgroundColor: '#EAEAEA' }} />
                  <Typography style={{ marginBottom: '13px' }} variant="h5" component="h5">
                    Things to know
                  </Typography>
                  <div className="thingsWrapper">
                    <div className="thingsRow">
                      {housing?.thingsToKnow.map(({ text, icon }, i) => {
                        if (i >= Math.round((housing?.thingsToKnow.length - 1) / 2)) return null;
                        return (
                          <div style={{ display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
                            <img
                              style={{ maxWidth: '20px', padding: '0', marginRight: '7.5px' }}
                              src={`/svg/icon-${icon}.svg`}
                              alt=""
                            />
                            <Typography
                              variant="caption"
                              dangerouslySetInnerHTML={{ __html: text || '' }}
                              component="p"
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div className="thingsRow">
                      {housing?.thingsToKnow.map(({ text, icon }, i) => {
                        if (i < Math.round((housing?.thingsToKnow.length - 1) / 2)) return null;
                        return (
                          <div style={{ display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
                            <img
                              style={{ maxWidth: '20px', padding: '0', marginRight: '7.5px' }}
                              src={`/svg/icon-${icon}.svg`}
                              alt=""
                            />
                            <Typography
                              variant="caption"
                              dangerouslySetInnerHTML={{ __html: text || '' }}
                              component="p"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div style={{ width: '100%', margin: '20px 0 30px', height: '1px', backgroundColor: '#EAEAEA' }} />
                  <Typography style={{ marginBottom: '13px' }} variant="h5" component="h5">
                    Where you’ll be
                  </Typography>
                  <MapZone
                    locationCoordinates={{
                      lat: housing?.locationCoordinates?.latitude || 0,
                      lng: housing?.locationCoordinates?.longitude || 0,
                    }}
                  />
                  <Typography
                    className="mapText"
                    variant="caption"
                    dangerouslySetInnerHTML={{
                      __html: `${housing?.locationCaption}`,
                    }}
                    component="p"
                  ></Typography>
                  <div style={{ width: '100%', margin: '20px 0 30px', height: '1px', backgroundColor: '#EAEAEA' }} />
                  {housing?.reviews.length !== 0 && (
                    <Typography style={{ marginBottom: '40px' }} variant="h5" component="h5">
                      Reviews
                    </Typography>
                  )}
                  {housing?.reviews.map(({ name, review, date }) => {
                    return (
                      <Review>
                        <Row1Review>
                          <img style={{ marginRight: '10px' }} src="/reviewImg.jpg" alt="" />
                          <div>
                            <Typography style={{ fontSize: '14px', lineHeight: '20px' }} variant="h6" component="h6">
                              {name}
                            </Typography>
                            <Typography
                              style={{ fontSize: '12px', fontWeight: 300, lineHeight: '18px', color: '#727C83' }}
                              variant="h6"
                              component="h6"
                            >
                              {`${months[Number.parseInt(date.split('-')[1]) - 1]} ${date.split('-')[2]}, ${
                                date?.split('-')[0]
                              }`}
                            </Typography>
                          </div>
                        </Row1Review>
                        <Typography variant="caption" component="p">
                          {review}
                        </Typography>
                      </Review>
                    );
                  })}
                </Col1>
                <Col2>
                  <Typography style={{ marginBottom: '16px' }} variant="h5" component="h5">
                    Specs
                  </Typography>
                  <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                    DESTINATION
                  </Typography>
                  <Typography
                    style={{ fontSize: '14px', color: '#03A0EF', marginBottom: '14px' }}
                    variant="h6"
                    component="h6"
                  >
                    {`${housing?.destination.place}, ${housing?.destination.country}`}
                  </Typography>
                  <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                    PROPERTY NAME
                  </Typography>
                  <Typography
                    style={{ fontSize: '14px', color: '#03A0EF' }}
                    variant="h6"
                    component="h6"
                    className="locationDetails"
                    dangerouslySetInnerHTML={{
                      __html: `${housing?.name}` || '',
                    }}
                  ></Typography>
                  <Typography
                    style={{ fontSize: '14px', fontWeight: 300, color: '#03A0EF', marginBottom: '14px' }}
                    variant="h6"
                    component="h6"
                    className="locationDetails"
                    dangerouslySetInnerHTML={{
                      __html: `${housing?.shortDescription}` || '',
                    }}
                  ></Typography>
                  <DetailsWrapper>
                    <Row>
                      <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                        BEDROOMS:
                      </Typography>
                      <IconNumFlex>
                        <Bedrooms style={{ marginRight: '5px' }} />
                        <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                          {housing?.bedrooms}
                        </Typography>
                      </IconNumFlex>
                    </Row>
                    <Row>
                      <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                        BEDS:
                      </Typography>
                      <IconNumFlex>
                        <Beds style={{ marginRight: '5px' }} />
                        <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                          {housing?.beds}
                        </Typography>
                      </IconNumFlex>
                    </Row>
                    <Row>
                      <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                        BATHROOMS:
                      </Typography>
                      <IconNumFlex>
                        <Baths style={{ marginRight: '5px' }} />
                        <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                          {housing?.bathrooms}
                        </Typography>
                      </IconNumFlex>
                    </Row>
                    <Row>
                      <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                        GUESTS:
                      </Typography>
                      <IconNumFlex>
                        <People style={{ marginRight: '5px' }} />
                        <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                          {housing?.groupSizeMax}
                        </Typography>
                      </IconNumFlex>
                    </Row>
                  </DetailsWrapper>
                  <Button
                    className="buttonSelect__popup"
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{ width: '100%', marginTop: '22px', textTransform: 'capitalize' }}
                    onClick={() => {
                      setHousing(id);
                      setShowPopup(false);
                    }}
                  >
                    Select Property
                  </Button>
                </Col2>
              </InfoWrapper>
              <ButtonsWrapper>
                <BackButton className="backButton__popup" customOnClick={() => setShowPopup(false)} />
              </ButtonsWrapper>
            </div>
          )}
        </Popup>
      )}
      {!mainPopup && openImages && (
        <ImagesPopup>
          <p
            onClick={() => {
              setOpenImages(false);
              setMainPopup(true);
            }}
            style={{
              position: 'absolute',
              top: '8.5px',
              right: '8.5px',
              zIndex: 100,
              margin: '0',
              fontSize: '20px',
              lineHeight: '14px',
              color: '#353743',
              cursor: 'pointer',
            }}
          >
            x
          </p>
          <Swiper {...params}>
            {data?.allHousings[0].showcaseImages.map(({ responsiveImage }, i) => {
              return (
                <SwiperSlide>
                  <ImageWrapper3>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper3>
                </SwiperSlide>
              );
            })}
            {/* <div className="swiper-pagination-hero"></div> */}
          </Swiper>
        </ImagesPopup>
      )}
    </Root>
  );
};

export default PropertyPopup;
