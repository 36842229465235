import { FunctionComponent } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useAppDispatch, useAppSelector, usePrices, useSummary } from 'app/hooks';
import {
  selectGroup,
  submitHousing,
  submitTransportation,
  summaryAfterSubmit,
  summaryReachedSelector,
} from 'features/group-data-collection/groupSlice';
import CaretRight from './CaretRight';
import { Estimate, Collapse, Wrapper, Container } from './styles';
import { useState } from 'react';
import DetailCard from './detail-card';
import { useHistory } from 'react-router';
import { useRef } from 'react';
import QtyBubble from 'components/shared/qty-bubble';
import { toUSDNoDecimal } from 'utils';

export interface SummaryTabletProps {
  show?: boolean;
  toggle: (args: any) => any;
}

const SummaryTablet: FunctionComponent<SummaryTabletProps> = ({ show, toggle, ...props }) => {
  const {
    housingData,
    transportationData,
    daytimeActivitiesData,
    nighttimeActivitiesData,
    personalServicesData,
    group,
  } = useSummary();

  const { totalPerPerson, renderPrice } = usePrices();

  const destination = `${group.destination.place}, ${group.destination.country}`;
  const date = `${new Date(`${group.fromDate}T00:00:00`).toDateString()} - ${new Date(
    `${group.untilDate}T00:00:00`
  ).toDateString()}`;

  const dispatch = useAppDispatch();
  const divRef = useRef<null | HTMLDivElement>(null);

  const [openSummary, setOpenSummary] = useState(false);
  const history = useHistory();
  const Detail = withStyles({
    root: {
      lineHeight: '15px',
      fontSize: '12px',
      fontWeight: 'bold',
      position: 'relative',
    },
  })(Typography);

  const removeHousing = async () => {
    dispatch(submitHousing({ data: undefined }));
  };

  const removeTransportation = async () => {
    dispatch(submitTransportation({ data: undefined }));
  };

  const summaryReached = useAppSelector(summaryReachedSelector);
  const housing = housingData?.allHousings[0].name;
  const transportation = transportationData;
  const daytimeActivities = daytimeActivitiesData?.allDaytimeActivities;
  const nighttimeActivities = nighttimeActivitiesData?.allNighttimeActivities;
  const personalServices = personalServicesData;

  const heightSummaryReached = () => {
    if (summaryReached) return '135px';
    else return '88px';
  };

  const {
    group: { bido_id },
  } = useAppSelector(selectGroup);
  return (
    <Wrapper
      style={{
        height: openSummary
          ? `${divRef?.current ? divRef?.current.getBoundingClientRect().height + 88 : 0}px`
          : heightSummaryReached(),
      }}
    >
      <Collapse
        style={{ transform: openSummary ? 'rotateZ(270deg)' : 'rotateZ(90deg)' }}
        onClick={() => setOpenSummary(!openSummary)}
      >
        <CaretRight />
      </Collapse>
      <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
        <div style={{ position: 'absolute', bottom: '0', width: '100%' }}>
          <Container style={{ backgroundColor: '#F0F1F2' }}>
            <div
              ref={divRef}
              style={{ color: '#353743', padding: '11px 0', backgroundColor: '#F0F1F2', width: '100%' }}
            >
              <Typography variant="h6" component="h6" style={{ marginBottom: '20px' }}>
                Summary
              </Typography>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ width: '48%' }}>
                  <DetailCard name="Destination" handleEdit={() => history.push('/destination')}>
                    <Detail color="primary">{destination}</Detail>
                  </DetailCard>
                  <DetailCard name="Date">
                    <Detail color="primary">{date}</Detail>
                  </DetailCard>
                  <DetailCard name="Group Size" handleEdit={() => history.push('/group-info')}>
                    <Detail color="primary">{group.groupSize} Adults</Detail>
                  </DetailCard>
                  <DetailCard name="Payees">
                    <Detail color="primary">{group.payees} Adults</Detail>
                  </DetailCard>
                </div>
                <div style={{ width: '48%' }}>
                  {housing && (
                    <DetailCard
                      name="Housing"
                      handleEdit={() => history.push('/builder/housing')}
                      handleErease={removeHousing}
                    >
                      <Detail color="primary">{housing}</Detail>
                    </DetailCard>
                  )}
                  {transportation && (
                    <DetailCard
                      name="Transportation"
                      handleEdit={() => history.push('/builder/transportation')}
                      handleErease={removeTransportation}
                    >
                      {transportation?.map((transp) => (
                        <Detail color="primary" key={transp.id}>
                          {transp.name}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '-3px',
                              bottom: '0',
                              margin: 'auto',
                              right: '-20px',
                            }}
                          >
                            <QtyBubble qty={transp.qty} />
                          </Box>
                        </Detail>
                      ))}
                    </DetailCard>
                  )}
                  {daytimeActivities && daytimeActivities.length > 0 && (
                    <DetailCard name="ACTIVITIES" handleEdit={() => history.push('/builder/daytime-activities')}>
                      {daytimeActivities?.map(({ id, name }) => (
                        <Detail color="primary" key={id}>
                          {name}
                        </Detail>
                      ))}
                    </DetailCard>
                  )}
                  {nighttimeActivities && nighttimeActivities.length > 0 && (
                    <DetailCard
                      name="Nighttime Activities"
                      handleEdit={() => history.push('/builder/nighttime-activities')}
                    >
                      {nighttimeActivities?.map(({ id, name }) => (
                        <Detail color="primary" key={id}>
                          {name}
                        </Detail>
                      ))}
                    </DetailCard>
                  )}
                  {personalServices && personalServices.length > 0 && (
                    <DetailCard name="Personal Services" handleEdit={() => history.push('/builder/personal-services')}>
                      {personalServices?.map(({ id, name, qty }) => (
                        <Detail color="primary" key={id}>
                          {name}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '-3px',
                              bottom: '0',
                              margin: 'auto',
                              right: '-20px',
                            }}
                          >
                            <QtyBubble qty={qty} />
                          </Box>
                        </Detail>
                      ))}
                    </DetailCard>
                  )}
                </div>
              </div>
            </div>
          </Container>

          <Estimate>
            <Container>
              <Typography variant="h6" component="h6" style={{ marginBottom: '10px', color: 'white' }}>
                Estimate
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <Detail
                  style={{
                    color: 'white',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    width: '48%',
                    marginBottom: '0',
                  }}
                >
                  GROUP TOTAL
                  <Detail color="primary" style={{ display: 'inline-box' }}>
                    {totalPrice}
                  </Detail>
                </Detail> */}
                <Detail
                  style={{
                    color: 'white',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    width: '48%',
                    marginBottom: '0',
                  }}
                >
                  PER PERSON
                  <Detail color="primary" style={{ display: 'inline-box' }}>
                    {renderPrice(totalPerPerson)}
                  </Detail>
                </Detail>
                <Detail
                  style={{
                    color: 'white',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    width: '48%',
                    marginBottom: '0',
                  }}
                >
                  GROUP ID
                  <Detail color="primary" style={{ display: 'inline-box' }}>
                    {bido_id}
                  </Detail>
                </Detail>
              </div>
              {summaryReached && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%', marginTop: '10px', marginBottom: '5px' }}
                  onClick={() => {
                    dispatch(summaryAfterSubmit({}));
                    setTimeout(() => {
                      (document.querySelector('#submitStep') as HTMLButtonElement).click();
                    }, 10);
                  }}
                >
                  Checkout
                </Button>
              )}
            </Container>
          </Estimate>
        </div>
      </div>
    </Wrapper>
  );
};

export default SummaryTablet;
