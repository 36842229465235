// eslint-disable-next-line import/no-anonymous-default-export
const Baths = (props: any) => {
  return (
    <svg
      id="Group_1344"
      {...props}
      data-name="Group 1344"
      xmlns="http://www.w3.org/2000/svg"
      width="10.155"
      height="13.503"
      viewBox="0 0 10.155 13.503"
    >
      <g id="Group_1330" data-name="Group 1330" transform="translate(4.185 2.092)">
        <path
          id="Path_1244"
          data-name="Path 1244"
          d="M1506-627.552h-5.022a.474.474,0,0,1-.474-.474.474.474,0,0,1,.474-.474H1506a.474.474,0,0,1,.474.474A.474.474,0,0,1,1506-627.552Z"
          transform="translate(-1500.5 628.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1331" data-name="Group 1331" transform="translate(6.696 3.766)">
        <path
          id="Path_1245"
          data-name="Path 1245"
          d="M1545.974-596.714a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1545.974-596.714Z"
          transform="translate(-1545.5 598.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1332" data-name="Group 1332" transform="translate(6.696 6.277)">
        <path
          id="Path_1246"
          data-name="Path 1246"
          d="M1545.974-551.714a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1545.974-551.714Z"
          transform="translate(-1545.5 553.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1333" data-name="Group 1333" transform="translate(6.696 8.788)">
        <path
          id="Path_1247"
          data-name="Path 1247"
          d="M1545.974-506.715a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1545.974-506.715Z"
          transform="translate(-1545.5 508.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1334" data-name="Group 1334" transform="translate(5.022 3.766)">
        <path
          id="Path_1248"
          data-name="Path 1248"
          d="M1515.974-596.714a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1515.974-596.714Z"
          transform="translate(-1515.5 598.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1335" data-name="Group 1335" transform="translate(5.022 6.277)">
        <path
          id="Path_1249"
          data-name="Path 1249"
          d="M1515.974-551.714a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1515.974-551.714Z"
          transform="translate(-1515.5 553.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1336" data-name="Group 1336" transform="translate(5.022 8.788)">
        <path
          id="Path_1250"
          data-name="Path 1250"
          d="M1515.974-506.715a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1515.974-506.715Z"
          transform="translate(-1515.5 508.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1337" data-name="Group 1337" transform="translate(8.37 3.766)">
        <path
          id="Path_1251"
          data-name="Path 1251"
          d="M1575.974-596.714a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1575.974-596.714Z"
          transform="translate(-1575.5 598.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1338" data-name="Group 1338" transform="translate(8.37 6.277)">
        <path
          id="Path_1252"
          data-name="Path 1252"
          d="M1575.974-551.714a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1575.974-551.714Z"
          transform="translate(-1575.5 553.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1339" data-name="Group 1339" transform="translate(8.37 8.788)">
        <path
          id="Path_1253"
          data-name="Path 1253"
          d="M1575.974-506.715a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1575.974-506.715Z"
          transform="translate(-1575.5 508.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1340" data-name="Group 1340" transform="translate(0 0)">
        <path
          id="Path_1254"
          data-name="Path 1254"
          d="M1425.974-652.5a.474.474,0,0,1-.474-.474v-11.717a1.313,1.313,0,0,1,1.311-1.311h5.022a1.313,1.313,0,0,1,1.311,1.311v1.255a.474.474,0,0,1-.474.474.474.474,0,0,1-.474-.474v-1.255a.363.363,0,0,0-.363-.363h-5.022a.363.363,0,0,0-.363.363v11.717A.474.474,0,0,1,1425.974-652.5Z"
          transform="translate(-1425.5 666)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1341" data-name="Group 1341" transform="translate(6.696 11.299)">
        <path
          id="Path_1255"
          data-name="Path 1255"
          d="M1545.974-461.715a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1545.974-461.715Z"
          transform="translate(-1545.5 463.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1342" data-name="Group 1342" transform="translate(5.022 11.299)">
        <path
          id="Path_1256"
          data-name="Path 1256"
          d="M1515.974-461.715a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1515.974-461.715Z"
          transform="translate(-1515.5 463.5)"
          fill="#bac3c9"
        />
      </g>
      <g id="Group_1343" data-name="Group 1343" transform="translate(8.37 11.299)">
        <path
          id="Path_1257"
          data-name="Path 1257"
          d="M1575.974-461.715a.474.474,0,0,1-.474-.474v-.837a.474.474,0,0,1,.474-.474.474.474,0,0,1,.474.474v.837A.474.474,0,0,1,1575.974-461.715Z"
          transform="translate(-1575.5 463.5)"
          fill="#bac3c9"
        />
      </g>
    </svg>
  );
};

export default Baths;
