import styled from 'styled-components';
import { sm, md, lg } from 'styles/themes';
import { Image as DatoCMSImage } from 'react-datocms';

export const Wrapper = styled.div`
  width: 100%;
  border: solid 1px #eaeaea;
  border-radius: 8px 8px 8px 8px;
  padding: 8px 13px 11px 13px;
  position: relative;
`;

export const ImageWrapper = styled.div`
  width: 45px;
  height: 45px;
  margin-right: 13px;
`;

export const Image = styled(DatoCMSImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px 4px 4px 4px;
`;

export const Typography = styled.div`
  font-size: 14px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 25px;
  width: 100%;
  margin-bottom: 6.5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 33.3%;
  margin-right: 14px;
  // padding: 3px 12px 3px 12px;
`;

export const ImageIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding-right: 2px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 127px;
    @media ${sm} {
      max-width: none;
    }
`;

export const ImageIconWrapper = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;

export const ActionIconsWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  svg {
    cursor: pointer;
  }
`;
