// eslint-disable-next-line import/no-anonymous-default-export
const Beds = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="119" height="47" viewBox="0 0 119 47">
      <defs>
        <filter id="Rectángulo_233" x="0" y="0" width="119" height="47" filterUnits="userSpaceOnUse">
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3.5" result="blur" />
          <feFlood flood-opacity="0.565" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Grupo_1906" data-name="Grupo 1906" transform="translate(-995.5 -378.5)">
        <g id="Grupo_1905" data-name="Grupo 1905">
          <g id="Grupo_1904" data-name="Grupo 1904">
            <g id="Grupo_1903" data-name="Grupo 1903">
              <g id="Grupo_1902" data-name="Grupo 1902">
                <g transform="matrix(1, 0, 0, 1, 995.5, 378.5)" filter="url(#Rectángulo_233)">
                  <g
                    id="Rectángulo_233-2"
                    data-name="Rectángulo 233"
                    transform="translate(10.5 8.5)"
                    fill="#353743"
                    stroke="#eaeaea"
                    stroke-width="1"
                  >
                    <rect width="98" height="26" rx="6" stroke="none" />
                    <rect x="0.5" y="0.5" width="97" height="25" rx="5.5" fill="none" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <text
          id="View_Full_Gallery"
          data-name="View Full Gallery"
          transform="translate(1061 403)"
          fill="#fff"
          font-size="9"
          font-family="Lato-Bold, Lato"
          font-weight="700"
        >
          <tspan x="-33.745" y="0">
            View Full Gallery
          </tspan>
        </text>
        <g id="Grupo_1899" data-name="Grupo 1899" transform="translate(-2 57)">
          <circle
            id="Elipse_257"
            data-name="Elipse 257"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1015 339)"
            fill="#fff"
          />
          <circle
            id="Elipse_262"
            data-name="Elipse 262"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1015 342)"
            fill="#fff"
          />
          <circle
            id="Elipse_265"
            data-name="Elipse 265"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1015 345)"
            fill="#fff"
          />
          <circle
            id="Elipse_258"
            data-name="Elipse 258"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1018 339)"
            fill="#fff"
          />
          <circle
            id="Elipse_261"
            data-name="Elipse 261"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1018 342)"
            fill="#fff"
          />
          <circle
            id="Elipse_264"
            data-name="Elipse 264"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1018 345)"
            fill="#fff"
          />
          <circle
            id="Elipse_259"
            data-name="Elipse 259"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1021 339)"
            fill="#fff"
          />
          <circle
            id="Elipse_260"
            data-name="Elipse 260"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1021 342)"
            fill="#fff"
          />
          <circle
            id="Elipse_263"
            data-name="Elipse 263"
            cx="1"
            cy="1"
            r="1"
            transform="translate(1021 345)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default Beds;
