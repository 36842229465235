import React, { FunctionComponent, useEffect, useState } from 'react';
import { ResponsiveImageType } from 'react-datocms';
import { StyledImage } from './styled';

export interface ShowcaseProps {
  images: { responsiveImage: ResponsiveImageType }[];
  duration?: number;
}

const IShowcase: FunctionComponent<ShowcaseProps> = ({ images, duration = 10000, ...props }) => {
  const imagesLength = images?.length;
  const [slideNumber1, setSlideNumber1] = useState<number>(0);
  const [slideNumber2, setSlideNumber2] = useState<number>(1);
  const [opacity, setOpacity] = useState(1);
  useEffect(() => {
    const showcaseInterval = setInterval(() => {
      setOpacity(0);
      setTimeout(() => setOpacity(1), 250);
      setTimeout(() => {
        setSlideNumber1((i) => (i < imagesLength - 1 ? i + 1 : 0));
      }, 250);
    }, duration);
    const showcaseInterval2 = setInterval(() => {
      setSlideNumber2((i) => (i < imagesLength - 1 ? i + 1 : 0));
    }, duration * 1.3);
    return () => {
      clearInterval(showcaseInterval);
      clearInterval(showcaseInterval2);
    };
  }, [setOpacity, setSlideNumber1, imagesLength, setSlideNumber2, duration]);

  return (
    <>
      {images?.length && images[slideNumber1] && images[slideNumber1].responsiveImage && (
        <StyledImage style={{ zIndex: 0, opacity }} data={images[slideNumber1].responsiveImage} />
      )}
      {images?.length && images[slideNumber2] && images[slideNumber2].responsiveImage && (
        <StyledImage style={{ zIndex: -1 }} data={images[slideNumber2].responsiveImage} />
      )}
    </>
  );
};

export default IShowcase;
