import { Link } from '@material-ui/core';
import { Container, StyledButton, Wrapper, ButtonWrapper } from './styled';
import { useHistory } from 'react-router';

const ErrorCapture = () => {
  const history = useHistory();

  return (
    <Container fixed>
      <Wrapper>
        <h3>
          An error has occurred <br /> Please try again in a few minutes by{' '}
          <Link style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
            clicking here
          </Link>
          <br />
        </h3>
        <p>If the error persists, please click the following button to work directly with a BIDO Agent:</p>
        <ButtonWrapper>
          <StyledButton
            onClick={() => history.push('/schedule')}
            color="primary"
            variant="contained"
            size="large"
            style={{ minWidth: '212px !important' }}
          >
            Build with a BIDO Agent
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default ErrorCapture;
