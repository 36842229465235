import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Container as MaterialContainer } from '@material-ui/core';

export const Container = styled(MaterialContainer)``;

export const Topbar = styled.div`
  width: 100%;
  height: 83px;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: ${({ theme }) => theme.colors.bgInput};
  @media ${md} {
    height: 100px;
  }
`;

export const TopbarFlex = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  min-height: 83px;
  @media ${md} {
    min-height: 100px;
  }

  .topbarTitle {
    font-size: 20px;
    text-align: start;
    @media ${md} {
      font-size: 28px;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Logo = styled.img`
  width: 40px;
  margin-right: 32px;
  min-width: 40px;
  @media ${md} {
    min-width: 55px;
    width: 55px;
    margin-right: 42px;
  }
`;
