//CONSIDER EDITING DASHBOARD DATA COLLECTION IF YOU HAVE TO TOUCH THIS FILE
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import api from 'api';
import { GroupsCollection } from 'schemas/db';

export interface ExpGroupData
  extends Omit<
    GroupsCollection,
    | '_id'
    | 'tripLength'
    | 'contactMethod'
    | 'status'
    | 'approval_status'
    | 'last_seen_at_step'
    | 'changed_at'
    | 'first_seen_at'
    | 'send_approval_notification'
    | 'quoted_at'
  > {
  _id?: GroupsCollection['_id'];
  tripLength?: GroupsCollection['tripLength'];
  contactMethod?: GroupsCollection['contactMethod'];
  status?: GroupsCollection['status'];
  approval_status?: GroupsCollection['approval_status'];
}

export interface expGroupState {
  status: 'idle' | 'loading' | 'failed';
  data: ExpGroupData;
  errors: { message: string }[];
}

export const initialState: expGroupState = {
  status: 'idle',
  data: {
    _id: undefined,
    payees: '',
    bido_id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    destination: undefined,
    housing: undefined,
    transportation: undefined,
    daytimeActivities: undefined,
    nighttimeActivities: undefined,
    personalServices: undefined,
    fromDate: '',
    untilDate: '',
    tripLength: undefined,
    travelReason: '',
    groupSize: '',
    budget: '',
    contactMethod: undefined,
    status: undefined,
    approval_status: undefined,
    archived: false,
    notes: '',
    checklist: undefined,
    members: undefined,
  },
  errors: [],
};

const getExpGroupData = createAsyncThunk(
  'experienceGroupData/getExpGroupData',
  async ({ _id }: { _id: string }, thunkAPI) => {
    if (!_id) {
      //Must have an id
      //TODO: reset group store
      //TODO: log to logrocket
      return;
    }
    try {
      const { groupData, ok } = await api.getExpGroupData(_id);
      if (ok) {
        return groupData;
      } else {
        return thunkAPI.rejectWithValue(
          JSON.stringify('Error getting group information, please try again in a few moments...')
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(JSON.stringify([error]));
    }
  }
);

export const expGroupSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExpGroupData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getExpGroupData.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload) {
          const groupData = action.payload;
          state.data = groupData;
        }
      })
      .addCase(getExpGroupData.rejected, (state, action) => {
        state.status = 'failed';
        if (action.error.message) {
          state.errors = JSON.parse(action.error.message);
        }
      });
  },
});

export const selectExpGroup = (state: RootState) => state.expGroup;
export const selectPricesData = (state: RootState) => state.expGroup.data;
export const selectExpGroupStatus = (state: RootState) => state.expGroup.status;
export { getExpGroupData };

export default expGroupSlice.reducer;
