import { createPortal } from 'react-dom';
import { ReactNode, FunctionComponent } from 'react';

interface IPortal {
  children: ReactNode;
  id?: string;
}
const Portal: FunctionComponent<IPortal> = ({ children, id }) => {
  const modalRoot = typeof document !== 'undefined' && document.getElementById(id || 'modal');
  return modalRoot ? createPortal(children, modalRoot) : null;
};

export default Portal;
