import React, { FunctionComponent, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Form, ButtonsWrapper } from 'components/shared';
import { selectGroupLead, submitStart } from 'features/group-data-collection/groupSlice';
import { Input } from 'components/shared/input';
import SubmitButton from 'components/shared/submit-button';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { selectGroupDataCollectionStatus } from 'features/group-data-collection/groupSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import StepLabel from 'components/shared/step-label';
import { Skeleton } from '@material-ui/lab';
import PhoneInput from 'components/shared/phone-input';
import Select from 'components/shared/select';

export interface Step1Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  heading?: string;
}

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  contactMethod: string;
};

const Step1: FunctionComponent<Step1Props> = ({ setActiveStep, heading, ...props }) => {
  const methods = [
    { name: 'Select one...', value: '' },
    { name: 'Email', value: 'email' },
    { name: 'Text', value: 'text' },
    { name: 'Call', value: 'call' },
  ];
  useEffect(() => {
    setActiveStep(0);
  }, [setActiveStep]);
  let history = useHistory();
  const initialValues = useAppSelector(selectGroupLead);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      email: initialValues.email || '',
      phone: initialValues.phone || '',
      contactMethod: initialValues.contactMethod || '',
    },
  });
  const dispatch = useAppDispatch();
  const onSubmit = async (data: FormValues) => {
    await dispatch(submitStart({ data }));
    history.push('/destination');
  };
  const dataCollectionStatus = useAppSelector(selectGroupDataCollectionStatus);
  const loading = dataCollectionStatus === 'loading';
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <StepLabel>Step 1</StepLabel>
        <Typography variant="h4" component="h4" style={{ marginBottom: '40px' }}>
          {heading || <Skeleton animation="wave" width={'70%'} />}
        </Typography>
        <Input
          disabled={loading}
          fullWidth
          placeholder="Josh"
          label="First Name"
          {...register('firstName', {
            required: { value: true, message: 'First name is required' },
          })}
          error={errors?.firstName?.message}
        />

        <Input
          disabled={loading}
          fullWidth
          placeholder={'Smith'}
          label="Last Name"
          {...register('lastName', {
            required: { value: true, message: 'Last name is required' },
          })}
          error={errors?.lastName?.message}
        />

        <Input
          disabled={loading}
          fullWidth
          placeholder="jsmith@domain.com"
          label="Email"
          {...register('email', {
            required: { value: true, message: 'Email is required' },
          })}
          type="email"
          error={errors?.email?.message}
        />
        <Controller
          control={control}
          name="phone"
          rules={{
            required: { value: true, message: 'Phone number is required' },
          }}
          render={({ field }) => <PhoneInput {...{ ...field, disabled: loading, error: errors?.phone?.message }} />}
        />
        <Select
          fullwidth
          label="WHAT’S THE BEST WAY TO REACH YOU?"
          {...register('contactMethod', {
            required: {
              value: true,
              message: 'Please choose a contact method',
            },
          })}
          options={methods}
          error={errors?.contactMethod?.message}
        />
      </div>
      <ButtonsWrapper>
        <SubmitButton loading={loading} style={{ marginLeft: 'auto' }} />
      </ButtonsWrapper>
    </Form>
  );
};

export default Step1;
