import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Container as MaterialContainer } from '@material-ui/core';

export const Container = styled(MaterialContainer)`
  @media ${md} {
    padding-top: 0;
  }
`;
export const Wrapper = styled.div`
  color:  ${({ theme }) => theme.colors.bg};
  top: 55px;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s height;
`;

export const Estimate = styled.div`
  padding: 13px 0 18px;
  color:  ${({ theme }) => theme.colors.bg};
  background: ${({ theme }) => theme.colors.bgDark};
  margin-top: auto;
  width: 100%;
`;

export const Collapse = styled.button`
outline: none;
position: absolute;
cursor: pointer;
border-radius: 50%;
top: auto;
bottom: -10px;
z-index: 100;
transform: rotateZ(90deg);
left: auto;
right: auto;
margin: auto;
width: 22px;
height: 22px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 2px solid #F0F1F2;
opacity: 1;
transition: 0.5s transform;
`