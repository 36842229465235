// see https://material-ui.com/customization/theming/ for reference.

import { createTheme } from '@material-ui/core/styles';
//breakpoints
const breakpoints = {
  values: {
    xs: 320,
    sm: 375,
    md: 768,
    lg: 1170,
    xl: 1170,
  },
};

//media queries
const mediaQuery = (breakpoint: string) => `only screen and (min-width: ${breakpoint}px)`;

export const xs = mediaQuery(`${breakpoints.values.xs}`);
export const sm = mediaQuery(`${breakpoints.values.sm}`);
export const md = mediaQuery(`${breakpoints.values.md}`);
export const lg = mediaQuery(`${breakpoints.values.lg}`);
export const xl = mediaQuery(`${breakpoints.values.xl}`);

// colors. If edited make sure to update src/styled.d.ts as well.
export const colors = {
  primaryLight: '#03A0EF',
  primary: '#03A0EF',
  secondary: '#353743',
  tertiary: '#CF2C78',
  bgLight: '#F0F1F2',
  bgInput: '#F6F7F9',
  placeholder: '#E3E2E2',
  bg: '#EAEAEA',
  bgDark: '#353743',
  bgDarker: '#727C83',
  bodyLight: '#BAC3C9',
  body: '#353743',
};

//theme config
const materialTheme = createTheme({
  breakpoints,
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '18px 50px',
          boxShadow: '0px 0px 50px #00000041',
          borderRadius: '8px',
        },
      },
      // Name of the rule
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
  },
  palette: {
    primary: {
      light: colors.primaryLight,
      main: colors.primary,
      dark: colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: '#CF2C78',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['"Lato"', '"sans-serif"'].join(','),
    h1: {
      fontWeight: 900,
    },
    h2: {
      fontWeight: 900,
    },
    h3: {
      fontWeight: 900,
    },
    h4: {
      fontWeight: 900,
      fontSize: '28px',
    },
    h5: {
      fontWeight: 900,
    },
    h6: {
      fontWeight: 900,
    },
  },
});

const styledTheme = { colors, sm, md, lg, xl };

export { materialTheme, styledTheme };
