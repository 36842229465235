import { FunctionComponent, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import styled from 'styled-components';

export interface IMapZone {
  locationCoordinates: {
    lat: number;
    lng: number;
  };
  mapId?: string;
  zoom?: number;
}

const MapWrapper = styled.div`
  height: 400px;
  width: 100%;
`;

const MapZone: FunctionComponent<IMapZone> = ({ locationCoordinates, mapId = 'map', zoom = 15, ...props }) => {
  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyAAcaKXDPUHN6HtEYsTU-Qh7uTZm74J_yU',
      version: 'weekly',
    });
    let map;
    loader.load().then((google) => {
      map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        // center: { lat: -34.397, lng: 150.644 },
        center: locationCoordinates,
        zoom,
        zoomControl: false,
      });
      // The marker, positioned at Uluru
      const marker = new google.maps.Marker({
        position: { lat: locationCoordinates.lat - 0.0025, lng: locationCoordinates.lng },
        // position: locationCoordinates,
        map: map,
        icon: '/map-marker.png',
      });
    });
  }, []);
  return <MapWrapper id={mapId}></MapWrapper>;
};

export default MapZone;
