import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Image as DatoCMSImage } from 'react-datocms';
import { Button, Typography } from '@material-ui/core';
import BackButton from '../back-button';

export const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  height: 313px;

  margin-bottom: 6px;
  @media ${md} {
    height: 313px;
  }
`;

export const ImageWrapper2 = styled.div`
  width: 100%;
  overflow: hidden;
  height: 162px;
  border-radius: 6px;
  margin-bottom: 6px;

  @media ${md} {
    display: block;
    width: 49%;
    height: 152px;
  }
`;

export const ImageWrapper3 = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  margin-bottom: 6px;
  height: 60%;

  @media ${md} {
    height: 80vh;
  }
`;

export const ImagesPopup = styled.div`
  display: none;
  @media ${md} {
    display: block;
    position: sticky;
    width: 90%;
    max-height: 93%;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    z-index: 101;
    max-width: 1300px;
    padding: 30px;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  right: 0;
  z-index: 100;
  position: absolute;
  bottom: 0;
  height: 100vh;
`;

export const Image = styled(DatoCMSImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Root = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .swiper-pagination-bullet {
    background-color: white;
    border: 2px solid white;
    opacity: 1;
    width: 11px;
    height: 11px;
    transition: 0.3s background-color;
  }
  .swiper-pagination-bullet-active {
    background-color: #03a0ef;
  }
  .swiper-pagination-hero {
    display: flex;
    justify-content: center;
    bottom: 10px;
    z-index: 1;
    position: absolute;
  }
  .swiper-slide {
  }

  .thingsWrapper {
    display: flex;
    justify-content: space-between;

    .thingsRow {
      width: 100%;
      @media ${md} {
        width: 48%;
      }
    }
  }
  @media ${md} {
  }
  @media ${lg} {
  }
`;

export const Popup = styled.div`
  width: 90%;
  padding: 20px;
  max-height: 93%;
  background-color: white;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  z-index: 101;
  overflow-y: auto;
  .swiperWrapper {
    display: block;
  }

  .images {
    display: none;
  }
  @media ${md} {
    width: 80%;
    max-width: 900px;
    .images {
      display: flex;
      position: relative;
      justify-content: space-between;
    }
    .swiperWrapper {
      display: none;
    }
  }
  @media ${lg} {
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${md} {
    flex-direction: row;
    height: auto;
    align-items: flex-start;
    width: 100%;
  }
`;

export const Review = styled.div`
  margin-bottom: 30px;
`;
export const Row1Review = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

export const Col1 = styled.div`
  margin-top: 30px;
  p {
    margin: 0;
  }

  .mapText {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    span {
      display: block;
      font-weight: 900;
      margin-bottom: 10px;
    }
  }
  @media ${md} {
    margin: 36px 50px 0 16px;
    width: 60%;
  }
`;

export const Col2 = styled.div`
  padding: 24px 30px 21px;
  border-radius: 6px;
  margin-top: 36px;
  box-shadow: 0px 3px 20px 0px #848484ca;
  .locationDetails {
    p {
      color: ${({ theme }) => theme.colors.primary};
      margin: 0;
      font-size: 13px;
      font-weight: normal;
    }
  }
  h3 {
    margin: 0;
  }
  @media ${md} {
    width: 40%;
  }
`;

export const DetailsWrapper = styled.div``;

export const Row = styled.div`
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;

  @media ${lg} {
    max-width: 50%;
  }
`;

export const IconNumFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  padding: 24px 24px 20px;
  .backButton__popup {
    width: 100%;
    svg {
      width: 30px;
    }
    margin-bottom: 8px;
    @media ${md} {
      width: auto;
      margin-bottom: 0px;
    }
  }
  .buttonSelect__popup {
    width: 100%;
    @media ${md} {
      width: auto;
      margin-bottom: 0px;
    }
  }
  @media ${md} {
    display: flex;
    justify-content: space-between;
  }
`;
