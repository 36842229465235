import { FunctionComponent, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Root,
  Popup,
  Image,
  ImageWrapper,
  InfoWrapper,
  ImageWrapper2,
  ImageWrapper3,
  Col1,
  Col2,
  Overlay,
  DetailsWrapper,
  Row,
  IconNumFlex,
  ButtonsWrapper,
  ImagesPopup,
  SelectWrapper,
  Select,
} from './styled';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { useQuery } from '@apollo/client';
import { BUILDER_SINGLE_TRANSPORTATION_QUERY_DATA, BUILDER_SINGLE_TRANSPORTATION_QUERY } from 'app/queries';
import { Button, Typography } from '@material-ui/core';
import ButtonImages from 'components/shared/icons/button-images';
import BackButton from '../back-button';
import Portal from '../portal';
import { TransportationTrip } from 'types';

interface ITransportationPopup {
  id: string;
  props?: any;
  setTransportation: (qty: number | string, trip?: TransportationTrip) => void;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  initialQty?: number | string;
  trip?: TransportationTrip;
  setTrip?: (arg: any) => void;
  isThisAnAirportTransfer?: boolean;
  qty: number | string | undefined;
  setQty: (arg: any) => void;
  selected?: boolean;
}
SwiperCore.use([Pagination, Navigation]);

const TransportationPopup: FunctionComponent<ITransportationPopup> = ({
  setShowPopup,
  setTransportation,
  selected,
  id,
  initialQty = 1,
  trip,
  setTrip,
  qty,
  setQty,
  isThisAnAirportTransfer,
  ...props
}) => {
  const { data, loading: loadingTransportation } = useQuery<BUILDER_SINGLE_TRANSPORTATION_QUERY_DATA>(
    BUILDER_SINGLE_TRANSPORTATION_QUERY,
    {
      variables: {
        id: id,
      },
    }
  );
  const params: Swiper = {
    slidesPerView: 1,
    preloadImages: false,
    autoplay: true,
    loop: true,
    navigation: true,
    pagination: {
      clickable: true,
      el: '.swiper-pagination-hero',
    },
  };
  const transportation = data?.allTransportations[0];
  const images = transportation?.showcaseImages;
  const [openImages, setOpenImages] = useState(false);
  const [mainPopup, setMainPopup] = useState(true);

  return (
    <Portal>
      <Root>
        <Overlay onClick={() => setShowPopup(false)} />
        {!openImages && mainPopup && (
          <Popup>
            <p
              onClick={() => setShowPopup(false)}
              style={{
                position: 'absolute',
                top: '8.5px',
                right: '8.5px',
                zIndex: 100,
                margin: '0',
                fontSize: '20px',
                lineHeight: '14px',
                color: '#353743',
                cursor: 'pointer',
              }}
            >
              x
            </p>
            {!loadingTransportation && (
              <div>
                <Typography style={{ marginBottom: '20px' }} variant="h5" component="h5">
                  {transportation?.name}
                </Typography>
                <div className="swiperWrapper">
                  <Swiper {...params}>
                    {images &&
                      images.map(({ responsiveImage }, i) => {
                        return (
                          <SwiperSlide>
                            <ImageWrapper>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper>
                          </SwiperSlide>
                        );
                      })}
                    {/* <div className="swiper-pagination-hero"></div> */}
                  </Swiper>
                </div>
                <div className="images">
                  <div style={{ width: '49.5%' }}>
                    {images?.[0]?.responsiveImage && (
                      <ImageWrapper>
                        <Image data={images[0].responsiveImage}></Image>
                      </ImageWrapper>
                    )}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '49.5%' }}>
                    {images &&
                      images.map(({ responsiveImage }, i) => {
                        if (i === 0 || i > 4) return null;
                        return (
                          <ImageWrapper2>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper2>
                        );
                      })}
                  </div>
                  <ButtonImages
                    onClick={() => {
                      setOpenImages(true);
                      setMainPopup(false);
                    }}
                    style={{ cursor: 'pointer', position: 'absolute', bottom: '10px', right: '10px' }}
                  />
                </div>
                <InfoWrapper>
                  <Col1>
                    <Typography style={{ marginBottom: '13px' }} variant="h5" component="h5">
                      Description
                    </Typography>
                    <Typography
                      variant="caption"
                      dangerouslySetInnerHTML={{ __html: transportation?.description || '' }}
                      component="p"
                    ></Typography>
                  </Col1>
                  <Col2>
                    <Typography style={{ marginBottom: '16px' }} variant="h5" component="h5">
                      Specs
                    </Typography>
                    <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                      DESTINATION
                    </Typography>
                    <Typography
                      style={{ fontSize: '14px', color: '#03A0EF', marginBottom: '14px' }}
                      variant="h6"
                      component="h6"
                    >
                      {`${transportation?.destination.place}, ${transportation?.destination.country}`}
                    </Typography>
                    <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                      TRANSPORTATION NAME
                    </Typography>
                    <Typography
                      style={{ fontSize: '14px', color: '#03A0EF' }}
                      variant="h6"
                      component="h6"
                      className="locationDetails"
                      dangerouslySetInnerHTML={{
                        __html: `${transportation?.name}` || '',
                      }}
                    ></Typography>
                    <Typography
                      style={{ fontSize: '14px', fontWeight: 300, color: '#03A0EF', marginBottom: '14px' }}
                      variant="h6"
                      component="h6"
                      className="locationDetails"
                      dangerouslySetInnerHTML={{
                        __html: `${transportation?.shortDescription}` || '',
                      }}
                    ></Typography>
                    <DetailsWrapper>
                      <Row>
                        <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                          PASSENGERS:
                        </Typography>
                        <IconNumFlex>
                          <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                            {transportation?.passengers}
                          </Typography>
                        </IconNumFlex>
                      </Row>
                      {/* <Row>
                        <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                          BAGS:
                        </Typography>
                        <IconNumFlex>
                          <Typography style={{ fontSize: '13px' }} variant="h6" component="h6">
                            {transportation?.bags}
                          </Typography>
                        </IconNumFlex>
                      </Row> */}
                    </DetailsWrapper>
                    <SelectWrapper>
                      <Typography component="p">Quantity</Typography>
                      <Select
                        value={Number(qty)}
                        onChange={(e) => {
                          setQty(e.target.value);
                        }}
                      >
                        {new Array(5)
                          .fill('beer')
                          .map((_, i) => ({ name: `${i}`, value: `${i}` }))
                          .map((option) => (
                            <option key={`${option.name}-${id}popup`} value={option.value}>
                              {option.name}
                            </option>
                          ))
                          .slice(1)}
                      </Select>
                    </SelectWrapper>
                    {isThisAnAirportTransfer && setTrip && (
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Typography component="p">Trip</Typography>
                        <SelectWrapper>
                          <Select
                            value={trip}
                            onChange={(e) => {
                              setTrip(e.target.value);
                            }}
                            style={{ width: '100%' }}
                          >
                            <option value="one-way">One way</option>
                            <option value="round-trip">Round trip</option>
                          </Select>
                        </SelectWrapper>
                      </div>
                    )}
                    <Button
                      className="buttonSelect__popup"
                      size="large"
                      variant="contained"
                      color="secondary"
                      style={{ width: '100%', marginTop: '22px', textTransform: 'capitalize' }}
                      onClick={(e) => {
                        setTransportation(Number(`${qty}`), trip);
                        setTimeout(() => {
                          setShowPopup(false);
                        }, 1);
                      }}
                    >
                      {selected ? 'Remove' : 'Select'} Transportation
                    </Button>
                  </Col2>
                </InfoWrapper>
                <ButtonsWrapper>
                  <BackButton className="backButton__popup" customOnClick={() => setShowPopup(false)} />
                </ButtonsWrapper>
              </div>
            )}
          </Popup>
        )}
        {!mainPopup && openImages && (
          <ImagesPopup>
            <p
              onClick={() => {
                setOpenImages(false);
                setMainPopup(true);
              }}
              style={{
                position: 'absolute',
                top: '8.5px',
                right: '8.5px',
                zIndex: 100,
                margin: '0',
                fontSize: '20px',
                lineHeight: '14px',
                color: '#353743',
                cursor: 'pointer',
              }}
            >
              x
            </p>
            <Swiper {...params}>
              {images &&
                images.map(({ responsiveImage }, i) => {
                  return (
                    <SwiperSlide>
                      <ImageWrapper3>{responsiveImage && <Image data={responsiveImage}></Image>}</ImageWrapper3>
                    </SwiperSlide>
                  );
                })}
              {/* <div className="swiper-pagination-hero"></div> */}
            </Swiper>
          </ImagesPopup>
        )}
      </Root>
    </Portal>
  );
};

export default TransportationPopup;
