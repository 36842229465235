// eslint-disable-next-line import/no-anonymous-default-export
const ActivitiesDaytime = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20.366" height="20.367" viewBox="0 0 20.366 20.367">
      <g id="Group_1396" data-name="Group 1396" transform="translate(0 0)">
        <g id="Group_1383" data-name="Group 1383" transform="translate(9.554 3.404)">
          <path
            id="Path_1280"
            data-name="Path 1280"
            d="M3686.428-2650.28h-7.643a.629.629,0,0,1-.629-.629c0-1.781,1.955-3.177,4.45-3.177s4.451,1.4,4.451,3.177A.629.629,0,0,1,3686.428-2650.28Zm-6.831-1.258h6.019a3.563,3.563,0,0,0-3.009-1.29A3.565,3.565,0,0,0,3679.6-2651.538Z"
            transform="translate(-3678.157 2654.085)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1384" data-name="Group 1384" transform="translate(1.911 3.404)">
          <path
            id="Path_1281"
            data-name="Path 1281"
            d="M3570.956-2650.28a.629.629,0,0,1-.628-.629c0-1.041-1.462-1.919-3.193-1.919a3.565,3.565,0,0,0-3.01,1.29h3.5a.629.629,0,0,1,.629.629.629.629,0,0,1-.629.629h-4.316a.628.628,0,0,1-.629-.629c0-1.781,1.955-3.177,4.45-3.177s4.45,1.4,4.45,3.177A.629.629,0,0,1,3570.956-2650.28Z"
            transform="translate(-3562.684 2654.085)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1385" data-name="Group 1385" transform="translate(9.007)">
          <path
            id="Path_1282"
            data-name="Path 1282"
            d="M3670.534-2699.583a.628.628,0,0,1-.627-.586,5.027,5.027,0,0,1,1.624-3.719,5.545,5.545,0,0,1,2.76-1.556,2.815,2.815,0,0,1,2.633.656.628.628,0,0,1,0,.889l-3.052,3.052a.629.629,0,0,1-.889,0,.629.629,0,0,1,0-.889l2.477-2.477a2.194,2.194,0,0,0-.922,0,4.282,4.282,0,0,0-2.118,1.212,3.83,3.83,0,0,0-1.258,2.745.629.629,0,0,1-.585.669Z"
            transform="translate(-3669.899 2705.521)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1386" data-name="Group 1386" transform="translate(3.602 5.405)">
          <path
            id="Path_1283"
            data-name="Path 1283"
            d="M3589.407-2616.658a.628.628,0,0,1-.444-.184,2.818,2.818,0,0,1-.655-2.634,5.546,5.546,0,0,1,1.555-2.76,5.552,5.552,0,0,1,2.76-1.556,2.816,2.816,0,0,1,2.633.656.628.628,0,0,1,0,.889l-5.4,5.405A.631.631,0,0,1,3589.407-2616.658Zm3.957-5.951a2.535,2.535,0,0,0-.494.051,4.287,4.287,0,0,0-2.117,1.212,4.281,4.281,0,0,0-1.212,2.117,2.183,2.183,0,0,0,0,.921l4.253-4.253A1.8,1.8,0,0,0,3593.364-2622.609Z"
            transform="translate(-3588.231 2623.868)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1387" data-name="Group 1387" transform="translate(9.282 5.952)">
          <path
            id="Path_1284"
            data-name="Path 1284"
            d="M3680.044-2601.181H3677.5a.629.629,0,0,1-.629-.629c0-6.448-.948-10.968-2.6-12.4a.629.629,0,0,1-.064-.887.629.629,0,0,1,.808-.122c.006-.015.013-.028.021-.042a.629.629,0,0,1,.851-.26,8,8,0,0,1,3.726,4.934,28.907,28.907,0,0,1,1.067,8.779A.63.63,0,0,1,3680.044-2601.181Zm-1.922-1.257h1.289c-.068-5.991-1.123-9.672-3.292-11.425C3677.146-2612.087,3678.065-2608.734,3678.123-2602.438Z"
            transform="translate(-3674.049 2615.595)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1388" data-name="Group 1388" transform="translate(0 16.561)">
          <path
            id="Path_1285"
            data-name="Path 1285"
            d="M3544-2452.777a2.874,2.874,0,0,1-1.985-.775,1.674,1.674,0,0,0-1.2-.5,1.669,1.669,0,0,0-1.2.5,2.878,2.878,0,0,1-1.985.775,2.874,2.874,0,0,1-1.985-.775,1.675,1.675,0,0,0-1.2-.5.629.629,0,0,1-.629-.628.629.629,0,0,1,.629-.629,2.876,2.876,0,0,1,1.985.775,1.672,1.672,0,0,0,1.2.5,1.67,1.67,0,0,0,1.2-.5,2.877,2.877,0,0,1,1.985-.775,2.875,2.875,0,0,1,1.985.775,1.672,1.672,0,0,0,1.2.5.629.629,0,0,1,.629.629A.629.629,0,0,1,3544-2452.777Z"
            transform="translate(-3533.814 2455.308)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1389" data-name="Group 1389" transform="translate(9.554 16.658)">
          <path
            id="Path_1286"
            data-name="Path 1286"
            d="M3678.785-2451.415a.629.629,0,0,1-.629-.629.628.628,0,0,1,.629-.628,1.672,1.672,0,0,0,1.2-.5,3.28,3.28,0,0,1,1.112-.65.629.629,0,0,1,.787.413.629.629,0,0,1-.413.788,2.124,2.124,0,0,0-.7.431A2.874,2.874,0,0,1,3678.785-2451.415Z"
            transform="translate(-3678.157 2453.851)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1390" data-name="Group 1390" transform="translate(14.623 17.423)">
          <path
            id="Path_1287"
            data-name="Path 1287"
            d="M3756.666-2440.622a2.7,2.7,0,0,1-1.665-.529.628.628,0,0,1-.147-.877.628.628,0,0,1,.877-.147,1.461,1.461,0,0,0,.935.3.629.629,0,0,1,.629.629A.629.629,0,0,1,3756.666-2440.622Z"
            transform="translate(-3754.736 2442.292)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1391" data-name="Group 1391" transform="translate(15.923 16.561)">
          <path
            id="Path_1288"
            data-name="Path 1288"
            d="M3775.015-2452.777a.628.628,0,0,1-.628-.629.628.628,0,0,1,.628-.629,1.672,1.672,0,0,0,1.2-.5,2.878,2.878,0,0,1,1.985-.775.629.629,0,0,1,.629.629.628.628,0,0,1-.629.628,1.673,1.673,0,0,0-1.2.5A2.875,2.875,0,0,1,3775.015-2452.777Z"
            transform="translate(-3774.386 2455.308)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1392" data-name="Group 1392" transform="translate(0 14.014)">
          <path
            id="Path_1289"
            data-name="Path 1289"
            d="M3544-2491.268a2.872,2.872,0,0,1-1.985-.775,1.673,1.673,0,0,0-1.2-.5,1.668,1.668,0,0,0-1.2.5,2.876,2.876,0,0,1-1.985.775,2.871,2.871,0,0,1-1.985-.775,1.674,1.674,0,0,0-1.2-.5.629.629,0,0,1-.629-.629.629.629,0,0,1,.629-.629,2.875,2.875,0,0,1,1.985.775,1.671,1.671,0,0,0,1.2.5,1.669,1.669,0,0,0,1.2-.5,2.876,2.876,0,0,1,1.985-.775,2.874,2.874,0,0,1,1.985.775,1.671,1.671,0,0,0,1.2.5.629.629,0,0,1,.629.629A.629.629,0,0,1,3544-2491.268Z"
            transform="translate(-3533.814 2493.799)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1393" data-name="Group 1393" transform="translate(9.554 14.213)">
          <path
            id="Path_1290"
            data-name="Path 1290"
            d="M3678.785-2488.462a.629.629,0,0,1-.629-.629.628.628,0,0,1,.629-.628,1.673,1.673,0,0,0,1.2-.5,3.9,3.9,0,0,1,.768-.513.628.628,0,0,1,.839.294.629.629,0,0,1-.295.839,2.809,2.809,0,0,0-.526.362A2.877,2.877,0,0,1,3678.785-2488.462Z"
            transform="translate(-3678.157 2490.794)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1394" data-name="Group 1394" transform="translate(14.451 14.746)">
          <path
            id="Path_1291"
            data-name="Path 1291"
            d="M3754.243-2480.936a2.764,2.764,0,0,1-1.86-.676.629.629,0,0,1-.107-.883.629.629,0,0,1,.883-.107,1.534,1.534,0,0,0,1.084.407.629.629,0,0,1,.629.629A.629.629,0,0,1,3754.243-2480.936Z"
            transform="translate(-3752.142 2482.735)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1395" data-name="Group 1395" transform="translate(15.923 14.014)">
          <path
            id="Path_1292"
            data-name="Path 1292"
            d="M3775.015-2491.268a.628.628,0,0,1-.628-.629.628.628,0,0,1,.628-.629,1.671,1.671,0,0,0,1.2-.5,2.877,2.877,0,0,1,1.985-.775.629.629,0,0,1,.629.629.629.629,0,0,1-.629.629,1.672,1.672,0,0,0-1.2.5A2.873,2.873,0,0,1,3775.015-2491.268Z"
            transform="translate(-3774.386 2493.799)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default ActivitiesDaytime;
