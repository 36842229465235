import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { styledTheme } from 'styles/themes';

export interface IQtyBubble {
  qty?: number | string;
}

const Span = styled.span`
  background: ${styledTheme.colors.tertiary};
  border: 2px solid white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0px 2px 0px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  font-weight: 800;
  font-size: 11px;
  line-height: 11px;
  padding-bottom: 1px;
  text-align: center;
`;

const QtyBubble: FunctionComponent<IQtyBubble> = ({ qty, ...props }) => {
  return <Span>{qty}</Span>;
};

export default QtyBubble;
