import LogRocket from 'logrocket';

const ENABLED = true;

export const MonitoringService = {
  init() {
    if (!ENABLED) return;
    if (process.env.NODE_ENV !== 'development') {
      LogRocket.init('wzdojz/bido-wizard');
    }
  },
  identify(id: string, data: any) {
    if (!ENABLED) return;
    try {
      LogRocket.identify(id, data);
    } catch (e) {
      console.error(`Couldn't id user with ${JSON.stringify(data)}`);
    }
  },
};
