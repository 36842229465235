import styled from 'styled-components';
import { md, lg } from 'styles/themes';

export const Row = styled.div`
  @media ${lg} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const Col1 = styled.div`
  width: 100%;
  @media ${lg} {
    width: 61%;
  }
`;

export const Col2 = styled.div`
  width: 100%;
  @media ${lg} {
    width: 37%;
  }
`;

export const EstimateWrapper = styled.div`
  background-color: #353743;
  width: 100%;
  border-radius: 6px;
  @media ${md} {
  }
`;

export const EstimateRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media ${md} {
  }
`;

export const SubmitButtonWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: end;
`;
