import { Typography, Box, Button } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { styledTheme } from 'styles/themes';
import { reset } from 'features/group-data-collection/groupSlice';
import { useAppDispatch } from 'app/hooks';
import { useHistory } from 'react-router';
export interface StepLabelProps {
  children: React.ReactNode;
}

const StepLabel: FunctionComponent<StepLabelProps> = ({ children, ...props }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', margin: '10px 0' }}
    >
      <Typography sx={{ color: styledTheme.colors.bodyLight }}>{children}</Typography>
      <Button
        size="large"
        variant="outlined"
        onClick={() => {
          dispatch(reset({}));
          setTimeout(() => {
            window.location.href = '/';
          }, 200);
        }}
        sx={{
          ml: ' 15px',
          borderColor: '#EAEAEA',
          color: '#EAEAEA',
          paddingTop: '5px',
          height: '38px',
          '&:hover': {
            color: '#03A0EF',
          },
        }}
      >
        START OVER
      </Button>
    </Box>
  );
};

export default StepLabel;
