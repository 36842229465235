import styled from 'styled-components';
import { md, lg } from 'styles/themes';
import { Container as MaterialContainer } from '@material-ui/core';

export const Container = styled(MaterialContainer)``;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 43px;
  margin-top: 35px;
  @media ${lg} {
    display: flex;
    height: auto;
    justify-content: space-between;
    margin-bottom: 53px;
    margin-top: 28px;
  }
`;

export const Col1 = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 38px;
  @media ${lg} {
    width: 58%;
    margin-bottom: 0;
  }
`;

export const Col2 = styled.div`
  height: auto;
  width: 100%;
  @media ${lg} {
    width: 40%;
  }
`;
