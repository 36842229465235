import React, { FunctionComponent } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ArrowLeftButton from './arrowLeftButton';
import { useHistory } from 'react-router';
export interface BackButtonProps {
  loading?: boolean;
  backTo?: string;
  customOnClick?: () => void;
  className?: string;
}

const BackButton: FunctionComponent<BackButtonProps> = ({ customOnClick, loading, backTo, className, ...props }) => {
  const history = useHistory();

  const StyledButton = withStyles({
    root: {
      border: '1px solid #EAEAEA',
    },
    label: {
      color: '#EAEAEA',
    },
  })(Button);
  return (
    <StyledButton
      className={className || ''}
      onClick={() => {
        if (customOnClick) {
          customOnClick();
          return;
        }
        if (backTo) {
          history.push(backTo);
        } else {
          history.goBack();
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      disabled={loading}
      variant="outlined"
      size="large"
      style={{ minWidth: '110px' }}
    >
      {loading ? (
        <CircularProgress color="inherit" size="25.5px" />
      ) : (
        <>
          <ArrowLeftButton />
          Back
        </>
      )}
    </StyledButton>
  );
};

export default BackButton;
