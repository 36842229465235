import { useQuery } from '@apollo/client';
import { Typography, Card } from '@material-ui/core';
import { GROUP_DATA, GROUP_QUERY } from 'app/queries';
import People from 'components/shared/icons/people';
import { FunctionComponent } from 'react';
import { Container, Wrapper, NumbsWrapper, NumbCircle, PeopleWrapper, UserWrapper } from './styled';
import { ExpGroupData } from '../expGroupSlice';
interface IGroupStatus {
  group?: ExpGroupData;
}

const GroupStatus: FunctionComponent<IGroupStatus> = ({ group, ...props }) => {
  const members = group?.members;
  const signed = members?.length;
  return (
    <div style={{ backgroundColor: '#F6F7F9' }}>
      <Container>
        <Wrapper>
          <Typography className="title" variant="h4" component="h4">
            Who’s already registered
          </Typography>
          <NumbsWrapper>
            <NumbCircle className="active">1</NumbCircle>
            {group &&
              group?.groupSize &&
              new Array((Number(group?.groupSize) || 0) - 1).fill('beer')?.map((_, i) => {
                return <NumbCircle className={signed && i + 1 < signed ? 'active' : ''}>{i + 2}</NumbCircle>;
              })}
          </NumbsWrapper>
          <PeopleWrapper>
            {members &&
              members.length > 0 &&
              members?.map(({ name }, i) => {
                return (
                  <UserWrapper>
                    <People
                      style={{
                        border: '1px solid rgb(186 195 201)',
                        borderRadius: '50%',
                        padding: '1px',
                        width: '20px',
                        height: '20px',
                        marginRight: '5px',
                      }}
                    ></People>
                    <Typography style={{ fontSize: '13px', fontWeight: 700 }}>{name}</Typography>
                  </UserWrapper>
                );
              })}
          </PeopleWrapper>
        </Wrapper>
      </Container>
    </div>
  );
};

export default GroupStatus;
