// eslint-disable-next-line import/no-anonymous-default-export
const Erase = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="7.196" height="7.196" viewBox="0 0 7.196 7.196">
      <g id="Group_1870" data-name="Group 1870" transform="translate(-1043.293 -106.793)">
        <line
          id="Line_155"
          data-name="Line 155"
          x2="5.782"
          y2="5.782"
          transform="translate(1044 107.5)"
          fill="none"
          stroke="#353743"
          stroke-linecap="round"
          stroke-width="1"
        />
        <line
          id="Line_156"
          data-name="Line 156"
          x1="5.782"
          y2="5.782"
          transform="translate(1044 107.5)"
          fill="none"
          stroke="#353743"
          stroke-linecap="round"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default Erase;
