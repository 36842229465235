export const SumSize = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51.43" height="51.43" viewBox="0 0 51.43 51.43">
      <g id="Group_1874" data-name="Group 1874" transform="translate(0 0)">
        <g id="Group_1660" data-name="Group 1660" transform="translate(6.541 13.483)">
          <g id="Group_1654" data-name="Group 1654" transform="translate(16.887)">
            <path
              id="Path_1345"
              data-name="Path 1345"
              d="M8169.633-848.108a6.766,6.766,0,0,1-6.758-6.758,6.766,6.766,0,0,1,6.758-6.758,6.766,6.766,0,0,1,6.759,6.758A6.766,6.766,0,0,1,8169.633-848.108Zm0-12.015a5.263,5.263,0,0,0-5.257,5.257,5.263,5.263,0,0,0,5.257,5.256,5.263,5.263,0,0,0,5.257-5.256A5.263,5.263,0,0,0,8169.633-860.123Z"
              transform="translate(-8162.875 861.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1655" data-name="Group 1655" transform="translate(13.884 12.015)">
            <path
              id="Path_1346"
              data-name="Path 1346"
              d="M8159.148-760.111a.751.751,0,0,1-.751-.751c0-2.991-.507-5.3-1.507-6.853a5.213,5.213,0,0,0-4.75-2.409h-4.005a5.209,5.209,0,0,0-4.749,2.409c-1,1.556-1.509,3.862-1.509,6.853a.751.751,0,0,1-.751.751.751.751,0,0,1-.75-.751c0-8.9,4.219-10.763,7.759-10.763h4.005c3.54,0,7.76,1.867,7.76,10.763A.751.751,0,0,1,8159.148-760.111Z"
              transform="translate(-8140.375 771.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1656" data-name="Group 1656" transform="translate(8.9 4.939)">
            <path
              id="Path_1347"
              data-name="Path 1347"
              d="M8108.776-813.155a5.741,5.741,0,0,1-5.734-5.735,5.741,5.741,0,0,1,5.734-5.735,5.691,5.691,0,0,1,3.38,1.1.75.75,0,0,1,.163,1.049.75.75,0,0,1-1.049.164,4.2,4.2,0,0,0-2.494-.812,4.238,4.238,0,0,0-4.233,4.233,4.239,4.239,0,0,0,4.233,4.233c.279,0,1.292-.049,1.483-.079a.75.75,0,0,1,.86.622.751.751,0,0,1-.622.861C8110.169-813.2,8109.041-813.155,8108.776-813.155Z"
              transform="translate(-8103.042 824.625)"
              fill="#353743"
            />
          </g>
          <g id="Group_1657" data-name="Group 1657" transform="translate(6.408 14.907)">
            <path
              id="Path_1348"
              data-name="Path 1348"
              d="M8085.125-740.15a.752.752,0,0,1-.751-.751,11.92,11.92,0,0,1,1.47-6.428,5.673,5.673,0,0,1,5.1-2.629l3.008.044a.75.75,0,0,1,.738.762.75.75,0,0,1-.75.74h-.012l-3-.044a4.2,4.2,0,0,0-3.822,1.939,10.515,10.515,0,0,0-1.231,5.616A.751.751,0,0,1,8085.125-740.15Z"
              transform="translate(-8084.374 749.958)"
              fill="#353743"
            />
          </g>
          <g id="Group_1658" data-name="Group 1658" transform="translate(0 11.013)">
            <path
              id="Path_1349"
              data-name="Path 1349"
              d="M8043.133-777.623h-6.008a.751.751,0,0,1-.751-.751.751.751,0,0,1,.751-.751h6.008a.751.751,0,0,1,.751.751A.751.751,0,0,1,8043.133-777.623Z"
              transform="translate(-8036.374 779.125)"
              fill="#353743"
            />
          </g>
          <g id="Group_1659" data-name="Group 1659" transform="translate(3.004 8.01)">
            <path
              id="Path_1350"
              data-name="Path 1350"
              d="M8059.625-794.116a.751.751,0,0,1-.751-.751v-6.007a.751.751,0,0,1,.751-.751.751.751,0,0,1,.75.751v6.007A.751.751,0,0,1,8059.625-794.116Z"
              transform="translate(-8058.874 801.625)"
              fill="#353743"
            />
          </g>
        </g>
        <g id="Group_1701" data-name="Group 1701" transform="translate(0 0)">
          <path
            id="Path_1389"
            data-name="Path 1389"
            d="M8013.088-911.2a25.744,25.744,0,0,1-25.714-25.715,25.744,25.744,0,0,1,25.714-25.715A25.744,25.744,0,0,1,8038.8-936.91,25.744,25.744,0,0,1,8013.088-911.2Zm0-49.928a24.24,24.24,0,0,0-24.212,24.213,24.24,24.24,0,0,0,24.212,24.213A24.24,24.24,0,0,0,8037.3-936.91,24.24,24.24,0,0,0,8013.088-961.123Z"
            transform="translate(-7987.374 962.625)"
            fill="#353743"
          />
        </g>
      </g>
    </svg>
  );
};

export default SumSize;
