// eslint-disable-next-line import/no-anonymous-default-export
const Transportation = (props: any) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="19.146" height="19.146" viewBox="0 0 19.146 19.146">
      <g id="Group_1366" data-name="Group 1366" transform="translate(0 0)">
        <g id="Group_1359" data-name="Group 1359" transform="translate(4.762 13.095)">
          <path
            id="Path_1260"
            data-name="Path 1260"
            d="M2531.488-2502.058a.645.645,0,0,1-.644-.644,1.739,1.739,0,0,0-1.736-1.737h-3.572a1.738,1.738,0,0,0-1.737,1.737.644.644,0,0,1-.644.644.644.644,0,0,1-.645-.644,3.029,3.029,0,0,1,3.025-3.025h3.572a3.029,3.029,0,0,1,3.025,3.025A.644.644,0,0,1,2531.488-2502.058Z"
            transform="translate(-2522.511 2505.728)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1360" data-name="Group 1360" transform="translate(2.381 7.143)">
          <path
            id="Path_1261"
            data-name="Path 1261"
            d="M2496.1-2591.019h-.632c-.57,0-1.172,0-1.741,0-3.222.009-5.087-.026-5.911-.847a1.369,1.369,0,0,1-.411-.989.644.644,0,0,1,.645-.645.644.644,0,0,1,.644.645.087.087,0,0,0,.032.077c.485.483,2.882.477,5,.47.572,0,1.177,0,1.748,0,2.115.006,4.512.013,5-.47a.086.086,0,0,0,.032-.077.644.644,0,0,1,.644-.645.645.645,0,0,1,.645.645,1.369,1.369,0,0,1-.412.989C2500.606-2591.1,2498.933-2591.019,2496.1-2591.019Z"
            transform="translate(-2487.402 2593.5)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1361" data-name="Group 1361" transform="translate(0 10.71)">
          <path
            id="Path_1262"
            data-name="Path 1262"
            d="M2455.914-2537.229h-2.976a.644.644,0,0,1-.644-.644.644.644,0,0,1,.644-.645h2.927c0-.342-.085-1.092-1.737-1.092l-.818,0a.645.645,0,0,1-.641-.648.645.645,0,0,1,.645-.641h0l.815,0c1.891,0,3.021.908,3.021,2.429A1.241,1.241,0,0,1,2455.914-2537.229Z"
            transform="translate(-2452.293 2540.903)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1362" data-name="Group 1362" transform="translate(14.286 10.714)">
          <path
            id="Path_1263"
            data-name="Path 1263"
            d="M2667.162-2537.167h-2.976a1.241,1.241,0,0,1-1.24-1.24c0-1.521,1.131-2.43,3.025-2.43h.817a.645.645,0,0,1,.645.645.645.645,0,0,1-.645.644h-.817c-1.653,0-1.733.751-1.737,1.093h2.927a.644.644,0,0,1,.644.644A.644.644,0,0,1,2667.162-2537.167Z"
            transform="translate(-2662.947 2540.837)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1363" data-name="Group 1363" transform="translate(0 7.143)">
          <path
            id="Path_1264"
            data-name="Path 1264"
            d="M2470.2-2581.5h-2.381a1.241,1.241,0,0,1-1.24-1.24v-1.141h-9.425v1.141a1.241,1.241,0,0,1-1.24,1.24h-2.381a1.241,1.241,0,0,1-1.239-1.24v-4.167a5.235,5.235,0,0,1,.784-3.258,1.835,1.835,0,0,1-.784-1.5v-.595a1.241,1.241,0,0,1,1.239-1.24h1.786a.644.644,0,0,1,.644.645.644.644,0,0,1-.644.644h-1.736v.546a.547.547,0,0,0,.546.547.644.644,0,0,1,.644.644v.059a.643.643,0,0,1-.188.455,3.64,3.64,0,0,0-1,3.056v4.118h2.283v-1.737a.645.645,0,0,1,.644-.645h10.714a.645.645,0,0,1,.645.645v1.737h2.283v-4.118a3.64,3.64,0,0,0-1-3.056.643.643,0,0,1-.189-.455v-.059a.644.644,0,0,1,.644-.644.547.547,0,0,0,.546-.547v-.546h-1.737a.645.645,0,0,1-.645-.644.645.645,0,0,1,.645-.645h1.786a1.241,1.241,0,0,1,1.24,1.24v.595a1.836,1.836,0,0,1-.784,1.5,4.566,4.566,0,0,1,.775,2.553.654.654,0,0,1,.01.11v4.762A1.241,1.241,0,0,1,2470.2-2581.5Z"
            transform="translate(-2452.293 2593.5)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1364" data-name="Group 1364" transform="translate(5.952)">
          <path
            id="Path_1265"
            data-name="Path 1265"
            d="M2546.662-2695.157a.644.644,0,0,1-.644-.644v-1.19a.547.547,0,0,0-.547-.546H2541.9a.547.547,0,0,0-.546.546v1.19a.644.644,0,0,1-.644.644.644.644,0,0,1-.644-.644v-1.19a1.837,1.837,0,0,1,1.835-1.835h3.571a1.837,1.837,0,0,1,1.835,1.835v1.19A.644.644,0,0,1,2546.662-2695.157Z"
            transform="translate(-2540.065 2698.826)"
            fill="currentColor"
          />
        </g>
        <g id="Group_1365" data-name="Group 1365" transform="translate(2.381 2.381)">
          <path
            id="Path_1266"
            data-name="Path 1266"
            d="M2501.139-2657.667a.645.645,0,0,1-.611-.441l-1.185-3.557c-.12-.3-.7-.764-1.775-.764h-5.952c-1.074,0-1.655.465-1.775.764l-1.186,3.557a.644.644,0,0,1-.815.407.645.645,0,0,1-.407-.815l1.191-3.571a.236.236,0,0,1,.008-.023,3.1,3.1,0,0,1,2.984-1.608h5.952a3.1,3.1,0,0,1,2.985,1.608l.008.023,1.191,3.571a.645.645,0,0,1-.408.815A.636.636,0,0,1,2501.139-2657.667Z"
            transform="translate(-2487.4 2663.717)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default Transportation;
